<template>
  <div ref="mainRef">
    <label
      data-required
      class="block text-900 text-sm font-medium"
    >
      邮箱
    </label>
    <InputText
      v-model="email"
      class="mt-2 w-full"
      :pt="{ root: { 'data-focus': 'true' } }"
    />
  </div>

  <div class="mt-5">
    <CodeField
      v-model="code"
      :email="email"
      scene="RESET_PWD"
    />
  </div>

  <div class="mt-5">
    <label
      data-required
      class="block text-900 text-sm font-medium"
    >
      新密码
    </label>
    <Password
      v-model="password"
      class="mt-2 w-full"
      :feedback="false"
      inputClass="w-full"
      @keydown.enter="onResetPwd"
    />
  </div>

  <Button
    class="w-full mt-8"
    label="重置密码"
    :disabled="!isValid"
    :loading="loading"
    @click="onResetPwd"
  />

  <div class="mt-4 flex items-center text-sm justify-center">
    <TextButton
      label="返回登录"
      @click="emit('login')"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { resetPwd } from '@/api/auth'
import { isEmail } from '@/utils'
import CodeField from '@/components/AuthCodeField.vue'

const email = ref('')
const code = ref('')
const password = ref('')
const isValid = computed(() => {
  return isEmail(email.value) && code.value !== '' && password.value !== ''
})

const loading = ref(false)
const emit = defineEmits<{
  login: []
  done: []
}>()

function onResetPwd() {
  if (!isValid.value) return

  loading.value = true

  resetPwd({
    email: email.value,
    code: code.value,
    password: password.value,
  })
    .then(res => {
      if (res.code === 0) {
        _message.success('重置成功')
        emit('done')
      } else {
        _message.info(res.message)
      }
    })
    .finally(() => {
      loading.value = false
    })
}

// auto focus
const mainRef = ref()
onMounted(() => {
  setTimeout(() => {
    mainRef.value.querySelector('input[data-focus]').focus()
  }, 0)
})
</script>

<style scoped></style>
