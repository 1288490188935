<template>
  <div class="flex flex-col items-center">
    <div
      class="pkg-card w-full h-98px relative rounded-12px overflow-hidden flex flex-col"
      :style="{
        backgroundColor: `var(--${props.pkg.style.themeColor}-400)`,
        borderColor: `var(--ld-pkg-border)`,
      }"
    >
      <div
        class="pkg-part-5 absolute top-0 left-0 w-full h-full"
        :style="{
          backgroundColor: `var(--${props.pkg.style.themeColor}-200)`,
        }"
      ></div>

      <Icon
        v-if="pkg.cardCount > 0"
        name="package-part-4"
        class="pkg-part-4 absolute top-1 right-1 w-203px"
      ></Icon>

      <div
        class="flex relative mb--1px"
        :style="{
          color: `var(--${props.pkg.style.themeColor}-300)`,
        }"
      >
        <Icon
          name="package-part-2"
          class="w-44px h-24px z-1"
        />
        <!-- 这里往左偏移 1 个像素，避免两个 svg 挨着，放大时会出现白线 -->
        <Icon
          name="package-part-1"
          class="w-39px h-24px ml--1 pkg-part-1"
        />
      </div>

      <div
        class="pkg-part-3 w-full flex-1 relative"
        :style="{
          background: `linear-gradient(180deg,  var(--${props.pkg.style.themeColor}-300) 0%, var(--${props.pkg.style.themeColor}-300)100%)`,
        }"
      ></div>

      <PkgRoundIcon
        :pkg="props.pkg"
        :size="66"
        :iconSize="44"
        class="text-48px z-1 absolute left-2 bottom-2"
      />

      <div class="absolute right-2 bottom-2">
        <div
          class="flex items-center justify-end mb-3 h-18px"
          :style="{
            color: `var(--${props.pkg.style.themeColor}-800)`,
          }"
        >
          <Icon
            name="package-card-count"
            class="w-15px"
          />
          <span class="text-15px font-bold ml-1px font-[DIN]">
            {{ pkg.cardCount }}
          </span>
        </div>

        <div
          class="flex items-center justify-end gap-6px h-20px"
          :style="{
            color: `var(--${props.pkg.style.themeColor}-800)`,
          }"
        >
          <Icon
            v-if="canShare"
            :key="publicIcon"
            :name="publicIcon"
            class="w-20px shrink-0 cursor-pointer"
            @click.stop="onShareEdit"
          />

          <div
            class="flex"
            @click.stop="toggleMenu"
          >
            <Icon
              name="package-setting"
              class="w-20px shrink-0 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-full text-center text-15px leading-[1.2] truncate mt-2">
      {{ pkg.name }}
    </div>

    <DebugLabel>
      <div class="text-xs">id：{{ pkg.id }}</div>
      <template v-if="isOwner">
        <div class="text-xs">创建时间：{{ $f.date(pkg.createdAt) }}</div>
        <div class="text-xs">最近编辑：{{ $f.date(pkg.updatedAt) }}</div>
        <div class="text-xs">
          最近快照：{{
            pkg.lastSnapshotAt ? $f.date(pkg.lastSnapshotAt) : '无'
          }}
        </div>
      </template>

      <template v-else-if="pkg.shelf">
        <div class="text-xs">
          添加时间：{{ $f.date(pkg.shelf.addedShelfAt) }}
        </div>
        <div
          v-if="pkg.lastSnapshotAt"
          class="text-xs"
        >
          最近快照：{{ $f.date(pkg.lastSnapshotAt) }}
        </div>
      </template>
    </DebugLabel>

    <OverlayPanel ref="menuOp">
      <div class="flex flex-col gap-4">
        <template v-if="isOwner">
          <div
            class="cursor-pointer"
            @click="onEdit"
          >
            {{ _t('编辑') }}
          </div>

          <div
            v-if="canShare"
            class="cursor-pointer"
            @click="onShareEdit"
          >
            {{
              props.pkg.owned?.shareStatus === ShareStatus.COMMUNITY
                ? _t('共享设置')
                : _t('开启共享')
            }}
          </div>

          <div
            class="cursor-pointer text-red-500"
            @click="onDelete"
          >
            {{ _t('删除卡包') }}
          </div>
        </template>

        <template v-else>
          <div
            class="cursor-pointer text-red-500"
            @click="onRemoveShelf"
          >
            {{ _t('移除卡包') }}
          </div>
        </template>
      </div>
    </OverlayPanel>
  </div>
</template>

<script lang="ts" setup>
import { ShareStatus } from '@/api/package-source'
import { computed, ref } from 'vue'
import DebugLabel from '../DebugLabel.vue'
import PkgRoundIcon from '../PkgRoundIcon.vue'

import type { PackageBasic } from '@/api/package-source'
import { useCommonStore } from '@/stores'
import { isPackageUpdateUnreleased } from '@/utils/package'

const props = defineProps<{
  pkg: PackageBasic
}>()

const emit = defineEmits<{
  edit: []
  shareSetting: []
  delete: []
  removeShelf: []
}>()

const menuOp = ref()

const publicIcon = computed(() => {
  if (isPackageUpdateUnreleased(props.pkg)) {
    return 'package-public-update'
  }
  return `package-public-${
    props.pkg.owned?.shareStatus === ShareStatus.COMMUNITY ? 'on' : 'off'
  }`
})
const store = useCommonStore()

const isOwner = computed(() => props.pkg.owned != null)

// 可以分享
const canShare = computed(() => isOwner.value && !store.isAndroidReviewing)

const toggleMenu = (event: MouseEvent) => {
  menuOp.value.toggle(event)
}

function onEdit() {
  menuOp.value.hide()

  emit('edit')
}

function onShareEdit() {
  menuOp.value.hide()

  emit('shareSetting')
}

async function onDelete() {
  menuOp.value.hide()

  emit('delete')
}

async function onRemoveShelf() {
  menuOp.value.hide()

  emit('removeShelf')
}
</script>

<style scoped>
.pkg-card {
  box-shadow: 0px 0px 2px var(--ld-pkg-border);
}

.pkg-part-1 {
  filter: drop-shadow(0px -1px 1px var(--slate-300));
}
</style>
