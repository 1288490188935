export function preloadVideo(src: string): Promise<{
  width: number
  height: number
}> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = src
    video.onloadeddata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }
    video.onloadedmetadata = () => {}
    video.onerror = err => {
      reject(err)
    }
  })
}
