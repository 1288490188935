<template>
  <div
    :class="[
      'flex items-stretch',
      {
        'flex-col': !_global.isPcMode,
      },
    ]"
  >
    <div
      :class="[
        'flex-1 mr-2 flex flex-col justify-between',
        {
          'w-full': !_global.isPcMode,
        },
      ]"
    >
      <ClozeEditor
        :id="props.cardDraft.id"
        :key="props.cardDraft.renderId + editorKey"
        :content="card.content"
        :limit="1000"
        editable
        @focus="onClozeContentFocus"
        @blur="showInvalidTip = true"
        @update="onClozeCardContentUpdate"
        @prev="emit('prev')"
        @next="emit('next')"
        @create-next="emit('create-after')"
      >
        <template
          v-if="!_global.isPcMode"
          #toolbtns
        >
          <Icon
            name="illustration"
            :class="[
              'w-23px',
              {
                'opacity-30': card.illustration,
              },
            ]"
            @click="emit('add-illustration')"
          />
        </template>
      </ClozeEditor>

      <div
        v-if="showInvalidTip"
        class="text-red text-14px"
      >
        {{ invalidMessage }}
      </div>
    </div>

    <div
      v-if="(!card.illustration && _global.isPcMode) || illustrationUploading"
      :class="[
        'h-78px w-78px flex items-center justify-center border border-dashed rounded bg-[var(--surface-100)] cursor-pointer',
        {
          'ml-auto': !_global.isPcMode,
        },
      ]"
      @click="emit('add-illustration')"
    >
      <ProgressSpinner
        v-if="illustrationUploading"
        class="w-36px h-36px p-11px"
      />

      <Icon
        v-else
        name="illustration"
        class="w-23px text-gray-600"
      />
    </div>

    <Image
      v-else-if="card.illustration"
      preview
      :class="[
        'illustration w-fit h-fit',
        {
          'mt-2 ml-auto': !_global.isPcMode,
        },
      ]"
    >
      <template #image>
        <div class="relative">
          <Img
            :assetId="card.illustration"
            class="h-78px w-78px rounded-8px object-cover"
            @click.stop
          />

          <Icon
            name="image-delete"
            class="absolute top-0 right-0 z-1 cursor-pointer w-18px"
            @click="emit('remove-illustration')"
          />
        </div>
      </template>
      <template #preview="slotProps">
        <img
          :src="_global.assetUrl(card.illustration)"
          class="object-cover"
          :style="slotProps.style"
          @click="slotProps.onClick"
        />
      </template>
    </Image>
  </div>
</template>
<script setup lang="ts">
import type { CardDraft } from '@/utils/card'
import { type Content, type ClozeCard } from '@/types/core'
import { removeCardEmptyFields, validateCard } from '@/utils/card'
import { computed, ref } from 'vue'
import ClozeEditor from '@/components/ClozeEditor/ClozeEditor.vue'
import Image from 'primevue/image'
import ProgressSpinner from 'primevue/progressspinner'

const props = defineProps<{
  cardDraft: CardDraft
  card: ClozeCard
  editorKey: number
  illustrationUploading: boolean
}>()

const emit = defineEmits<{
  prev: []
  next: []
  focus: []
  update: [ClozeCard]
  'create-after': []
  'add-illustration': []
  'remove-illustration': []
}>()

const filteredCard = computed(() => removeCardEmptyFields(props.card))

const invalidMessage = computed(() => validateCard(filteredCard.value))

const showInvalidTip = ref(false)

function onClozeContentFocus() {
  emit('focus')
  showInvalidTip.value = false
}

function onClozeCardContentUpdate(newContent: Content) {
  emit('update', {
    ...props.card,
    illustration: props.card.illustration?.trim() || undefined,
    content: newContent,
  } as ClozeCard)
}
</script>
<style scoped></style>
