<template>
  <div ref="mainRef">
    <label
      data-required
      class="block text-900 text-xl font-medium"
    >
      问题
    </label>

    <Textarea
      :modelValue="props.card.question"
      autoResize
      class="my-2 w-full px-4 py-2 border-solid border-1 rounded-8px border-[var(--surface-300)]"
      @update:model-value="onChange({ question: $event })"
    />

    <div class="flex flex-col gap-2">
      <div
        v-for="(item, i) in card.options"
        :key="i"
        class="flex flex-col gap-2"
      >
        <Textarea
          :modelValue="item.content"
          :placeholder="_t('请输入选项内容')"
          rows="1"
          class="g-underline-input"
          autoResize
          @update:model-value="onChoiceUpdate(i, { content: $event })"
        ></Textarea>

        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2 text-15px">
            <span
              v-if="item.isAnswer"
              class="text-green font-semibold"
            >
              {{ _t('正确选项') }}
            </span>
            <span
              v-else
              class="text-red font-semibold"
            >
              {{ _t('错误选项') }}
            </span>

            <InputSwitch
              :modelValue="item.isAnswer"
              class="option-answer-switch"
              @update:model-value="onChoiceUpdate(i, { isAnswer: $event })"
            />

            <span
              v-if="noAnswer"
              class="text-red font-semibold ml-4"
            >
              {{ _t('请至少设置一个正确选项') }}
            </span>

            <span
              v-else-if="item.content.trim() === ''"
              class="text-red font-semibold ml-4"
            >
              {{ _t('选项不能为空，请填写内容') }}
            </span>
          </div>

          <Icon
            name="trash"
            :class="[
              'w-16px cursor-pointer',
              {
                'opacity-30': !isChoiceOptionCanDelete,
              },
            ]"
            @click="onChoiceOptionDelete(i)"
          ></Icon>
        </div>
      </div>
    </div>

    <div
      :class="[
        'w-fit bg-gray-100 rounded-22px px-4 py-6px text-17px cursor-pointer flex items-center mt-4',
        {
          'opacity-30': !isChoiceOptionCanAdd,
        },
      ]"
      @click="onChoiceOptionAdd"
    >
      <i class="pi pi-plus text-12px !font-bold mr-1"></i>
      {{ _t('添加选项') }}
    </div>

    <div class="mt-4">
      <label class="block text-900 text-xl font-medium">配图</label>

      <InputText
        maxlength="1000"
        class="mt-2 w-full"
        autoResize
        placeholder="上传图片后的 assetId"
        :modelValue="props.card.illustration"
        @update:modelValue="onChange({ illustration: $event })"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CardType, MAX_CHOICE_OPTIONS, MIN_CHOICE_OPTIONS } from '@/types/core'
import { ref, onMounted, computed } from 'vue'

import type { MCQCard, ChoiceOption } from '@/types/core'
import Textarea from 'primevue/textarea'

const props = defineProps<{
  card: MCQCard
}>()

const emit = defineEmits<{
  change: [MCQCard]
}>()

const isChoiceOptionCanDelete = computed(() => {
  return props.card.options.length > MIN_CHOICE_OPTIONS
})

const isChoiceOptionCanAdd = computed(() => {
  return props.card.options.length <= MAX_CHOICE_OPTIONS
})

const noAnswer = computed(() => {
  return props.card.options.every(item => !item.isAnswer)
})

function onChoiceUpdate(
  index: number,
  { content, isAnswer }: Partial<ChoiceOption>
) {
  const item = props.card.options[index]

  if (item == null) return

  const newOptions = [...props.card.options]
  newOptions.splice(index, 1, {
    content: content == null ? item.content : content.trim(),
    isAnswer: isAnswer == null ? item.isAnswer : isAnswer,
  })

  onChange({
    options: newOptions,
  })
}

function onChoiceOptionDelete(index: number) {
  if (!isChoiceOptionCanDelete.value) return

  const newOptions = [...props.card.options]

  newOptions.splice(index, 1)

  onChange({
    options: newOptions,
  })
}

function onChoiceOptionAdd() {
  if (!isChoiceOptionCanAdd.value) return

  const newOptions = [...props.card.options]

  newOptions.push({
    content: '',
    isAnswer: false,
  })

  onChange({
    options: newOptions,
  })
}

function onChange({
  question,
  options,
  illustration,
}: {
  question?: string
  options?: ChoiceOption[]
  illustration?: string
}) {
  emit('change', {
    type: CardType.MCQ,
    question: question ?? props.card.question,
    options: options ?? props.card.options,
    illustration: illustration ?? props.card.illustration,
    analysis: props.card.analysis,
  })
}

// auto focus
const mainRef = ref()
onMounted(() => {
  setTimeout(() => {
    mainRef.value.querySelector('[data-focus]')?.focus()
  }, 0)
})
</script>

<style scoped></style>
