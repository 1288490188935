<template>
  <div
    v-if="editor"
    class="h-full flex flex-col gap-2"
  >
    <EditorContent
      :editor="editor"
      class="cloze-editor flex-1"
      @keydown="onKeydown"
      @contextmenu="onContextMenu"
    />

    <div
      v-if="wordOverflow > 0"
      class="text-red-500 text-14px"
    >
      超出字数限制，请再删减 {{ wordOverflow }} 个字
    </div>

    <div
      v-else-if="warningMessage"
      class="text-orange font-semibold text-14px"
    >
      {{ warningMessage }}
    </div>

    <div
      v-if="toolbtns"
      class="flex items-center"
    >
      <div
        v-if="_global.isPcMode"
        class="toolbtn flex items-center"
      >
        <Tooltip
          placement="top"
          class="cloze-tooltip"
        >
          <div>{{ _t('标记知识点') }}</div>
          <div>
            {{ _global.isMac ? 'Cmd + D' : 'Ctrl + D' }}
          </div>
          <template #trigger>
            <ClozeGroupIcon
              :color="clozeColor"
              @click="editor.chain().toggleCloze(selectedClozeGroup).run()"
            />
          </template>
        </Tooltip>

        <Tooltip
          :show="showClozeGroupPanel"
          placement="top"
          trigger="click"
          :showArrow="false"
          @update:show="showClozeGroupPanel = $event"
        >
          <div class="text-12px font-semibold text-ld-label-secondary">
            {{ _t('同色知识点，在闯关时交换位置也算答对') }}
          </div>

          <div class="p-1 flex items-center justify-between gap-4 mt-2">
            <Tooltip
              v-for="(item, i) in CLOZE_GROUPS"
              :key="item.group"
              placement="top"
              class="cloze-tooltip"
            >
              <div>{{ _t(`知识点 ${i + 1}`) }}</div>
              <div>
                {{ _t(`${_global.isMac ? `Cmd` : `Ctrl`} + ${i + 1}`) }}
              </div>
              <template #trigger>
                <ClozeGroupIcon
                  class="hover:bg-gray-200 cursor-pointer"
                  @click="onGroupSelect(item.group)"
                >
                  <Icon
                    :name="item.icon"
                    :style="{ color: `var(--${item.color})` }"
                    class="w-16px"
                  />
                </ClozeGroupIcon>
              </template>
            </Tooltip>

            <Tooltip
              placement="top"
              class="cloze-tooltip"
            >
              {{ _t('清除标记') }}
              <template #trigger>
                <ClozeGroupIcon
                  class="hover:bg-gray-200 cursor-pointer"
                  @click="onClozeClear()"
                >
                  <Icon
                    name="cloze-group-clear"
                    class="w-16px"
                  />
                </ClozeGroupIcon>
              </template>
            </Tooltip>
          </div>
          <template #trigger>
            <div
              :class="[
                'w-20px h-24px rounded-r-4px flex justify-center items-center',
                {
                  'bg-gray-300': showClozeGroupPanel,
                },
              ]"
            >
              <i class="pi pi-chevron-down text-15px"></i>
            </div>
          </template>
        </Tooltip>
      </div>

      <div class="ml-auto text-gray-600">
        <slot name="toolbtns"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEditor, EditorContent, type Extensions } from '@tiptap/vue-3'
import History from '@tiptap/extension-history'
// block nodes
import Document from './extensions/doc'
import Paragraph from './extensions/p'
// inline nodes
import Text from './extensions/text'
// marks
import Cloze, { PresetClozeGroup, getClozeColor } from './extensions/cloze'
import PlainText from './extensions/plain-text'
import Placeholder from '@tiptap/extension-placeholder'
import CustomPaste from './extensions/custom-paste'
import ClearMarksOnEnter from './extensions/clear-mark-on-enter'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { content2Doc, countClozeGroup, doc2Content } from '@/utils/card'
import TextStyle, { DotText } from './extensions/text-style'
import ClozeGroupIcon from '@/components/ClozeGroupIcon.vue'
import { useCommonStore } from '@/stores'

import type { Content } from '@/types/core'

const store = useCommonStore()

const props = withDefaults(
  defineProps<{
    id?: number
    content: Content
    editable?: boolean
    limit?: number
    autofocus?: boolean
    placeholder?: string
    toolbtns?: boolean
  }>(),
  {
    id: undefined,
    limit: undefined,
    editable: true,
    autofocus: false,
    placeholder: '在此输入卡片内容',
    toolbtns: true,
  }
)

const emit = defineEmits<{
  update: [Content]
  focus: []
  blur: []
  prev: []
  next: []
  createNext: []
}>()

const extensions: Extensions = [
  Document,
  // Block
  Paragraph,
  // Inline
  Text,
  // marks
  Cloze,
  PlainText,
  TextStyle,
  Placeholder.configure({
    placeholder: props.placeholder,
  }),
  History,
  CustomPaste,
  ClearMarksOnEnter,
]

// 编辑器某些 feature 在 prod 隐藏
// 注意需要检查一下其他地方是否依赖相应的插件如浮动菜单
if (!_global.isProd) {
  extensions.push(...[DotText])
}

const CLOZE_GROUPS = [
  {
    group: PresetClozeGroup.c1,
    icon: 'cloze-group-1',
    color: getClozeColor(PresetClozeGroup.c1),
  },
  {
    group: PresetClozeGroup.c2,
    icon: 'cloze-group-2',
    color: getClozeColor(PresetClozeGroup.c2),
  },
  {
    group: PresetClozeGroup.c3,
    icon: 'cloze-group-3',
    color: getClozeColor(PresetClozeGroup.c3),
  },
  {
    group: PresetClozeGroup.c4,
    icon: 'cloze-group-4',
    color: getClozeColor(PresetClozeGroup.c4),
  },
  {
    group: PresetClozeGroup.c5,
    icon: 'cloze-group-5',
    color: getClozeColor(PresetClozeGroup.c5),
  },
]

const showClozeGroupPanel = ref(false)
const editor = useEditor({
  editable: props.editable,
  content: content2Doc(props.content),
  extensions,
  editorProps: {
    attributes: {
      class: 'focus:outline-none',
    },
  },
  onUpdate({ editor }) {
    const doc = editor.getJSON()

    emit('update', doc2Content(doc))
  },
  onFocus() {
    if (_global.isInsideApp) {
      _bridge.editor.focus()
      _bridge.editor.syncState({ selectedClozeGroup: selectedClozeGroup.value })
    }

    emit('focus')
  },
  onBlur() {
    if (_global.isInsideApp) {
      _bridge.editor.blur()
    }

    emit('blur')
    if (editor.value != null) {
      // 当前字数
      const currentWordCount = editor.value.getText().length
      if (props.limit != null && currentWordCount > props.limit) {
        editor.value.commands.deleteRange({
          from: props.limit + 1,
          to: currentWordCount + 1,
        })
      }
    }
  },
})

const selectedClozeGroup = ref(store.getCardSelectedClozeGroup(props.id))

const clozeColor = computed(() => getClozeColor(selectedClozeGroup.value))

// 超出多少字
const wordOverflow = computed(() => {
  if (editor.value != null) {
    // 当前字数
    const currentWordCount = editor.value.getText().length
    if (props.limit != null && currentWordCount > props.limit) {
      return currentWordCount - props.limit
    }
  }
  return 0
})

const RECOMMEND_MAX_CLOZE_GROUP = 5
const warningMessage = computed(() => {
  const clozeGroupCount = countClozeGroup(props.content)
  if (clozeGroupCount > RECOMMEND_MAX_CLOZE_GROUP) {
    return _t('💡 这张卡片标记的知识点过多、可能会影响学习效果，建议拆分卡片哦')
  }
  return ''
})

function onKeydown(e: KeyboardEvent) {
  const key = e.key.toLowerCase()

  if (key === 'enter' && (e.metaKey || e.ctrlKey)) {
    emit('createNext')
  }

  if (key === 'tab') {
    e.preventDefault()

    if (e.shiftKey) {
      emit('prev')
    } else {
      emit('next')
    }
  }
}

// 禁用系统的浮动菜单
function onContextMenu(e: PointerEvent) {
  e.preventDefault()
}

function onGroupSelect(group: PresetClozeGroup) {
  selectedClozeGroup.value = group
  showClozeGroupPanel.value = false
  editor.value?.chain().toggleCloze(group).run()

  if (props.id != null) {
    store.setCardSelectedClozeGroup(props.id, group)
  }
}

function onClozeClear() {
  showClozeGroupPanel.value = false
  editor.value?.chain().unsetCloze().run()
}

watch(
  () => selectedClozeGroup.value,
  () => {
    if (editor.value) {
      editor.value.clozeGroup = selectedClozeGroup.value
    }
  },
  { immediate: true }
)

function handleToggleCloze(group: PresetClozeGroup) {
  if (editor.value == null || !editor.value.isFocused) return

  editor.value.chain().toggleCloze(group).run()

  if (props.id != null) {
    store.setCardSelectedClozeGroup(props.id, group)
    _bridge.editor.syncState({ selectedClozeGroup: group })
  }
}

function handleClearCloze() {
  if (editor.value == null || !editor.value.isFocused) return

  editor.value.chain().unsetCloze().run()
}

onUnmounted(() => {
  _bridge.editor.offEditorToggleCloze(handleToggleCloze)
  _bridge.editor.offEditorClearCloze(handleClearCloze)
})

onMounted(() => {
  if (editor.value) {
    editor.value.id = props.id
  }
  _bridge.editor.onEditorToggleCloze(handleToggleCloze)
  _bridge.editor.onEditorClearCloze(handleClearCloze)
})
</script>
<style lang="stylus">
.tiptap
  outline: none

  p.is-editor-empty:first-child::before {
    color: var(--gray-500);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  p {
    margin-bottom: 0px;
  }

  span.dot {
    margin: 0px 4px;
  }

  span.dot:before,span.dot:after {
    content: '·';
    display: inline;
  }
  span.dot:before {
    margin-right: 2px;
  }
  span.dot:after {
    margin-left: 2px;
  }

.toolbtn
  border-radius: 4px
  user-select: none
  cursor: pointer;

  &:hover
    background-color: var(--gray-200)

.cloze-tooltip.n-tooltip
  background-color: var(--surface-900)
  color: white

.cloze-tooltip.n-tooltip .n-popover__content
  display: flex
  flex-direction: column
  align-items: center
</style>
