<template>
  <SafeAreaTopSpacer
    v-if="_global.isMobileMode"
    class="bg-ld-brand-100"
  />

  <div
    class="overflow-y-auto pb-4 h-full"
    @scroll="onScroll"
  >
    <RankHeader
      :rank="groupRank"
      :class="[
        'w-full sticky transition-top duration-500 z-2',
        isRankHeaderSticky ? 'top-0' : 'top--100px',
      ]"
    />

    <!--
      primevue divider content 有个 1 的 zindex
      所以这里需要加个 2 z-index，否则滚动时排名提升的分割线会穿过顶部栏
    -->
    <div
      :class="[
        'sticky bg-ld-brand-100 py-10px z-2 transition-top duration-500',
        isRankHeaderSticky ? 'top-100px' : 'top-0',
      ]"
    >
      <div class="mb-1 text-center text-21px font-semibold">
        {{ rankTitle }}
      </div>

      <div class="font-semibold text-15px text-center">
        {{ _t('本轮排位将在') }}
        <span class="text-ld-brand-500">{{ leftTimeLabel }}</span>
        {{ _t('后结束，前') }}
        <span class="text-ld-brand-500">{{ upCount }}</span>
        {{ _t('名可晋级') }}
      </div>
    </div>

    <div class="p-4 g-body-width">
      <template v-if="userGroups.up2.length > 0">
        <div class="user-group">
          <GroupUserItem
            v-for="user in userGroups.up2"
            :key="user.userId"
            :user="user"
          ></GroupUserItem>
        </div>

        <GroupDivider
          iconName="rank-up2"
          :label="up2Label"
          class="text-ld-premium-500"
        />
      </template>

      <template v-if="userGroups.up1.length > 0">
        <div class="user-group">
          <GroupUserItem
            v-for="user in userGroups.up1"
            :key="user.userId"
            :user="user"
          ></GroupUserItem>
        </div>

        <GroupDivider
          iconName="rank-up1"
          :label="up1Label"
          class="text-green-500"
        />
      </template>

      <div
        v-if="userGroups.keep.length > 0"
        class="user-group"
      >
        <GroupUserItem
          v-for="user in userGroups.keep"
          :key="user.userId"
          :user="user"
        ></GroupUserItem>
      </div>

      <template v-if="userGroups.down1.length > 0">
        <GroupDivider
          iconName="rank-down1"
          :label="down1Label"
          class="text-red"
        />

        <div class="user-group">
          <GroupUserItem
            v-for="user in userGroups.down1"
            :key="user.userId"
            :user="user"
          ></GroupUserItem>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { type RankHomeInGroup } from '@/types/rank'
import { getRankName, getRankTitle, parseRank } from './util'
import { computed, onMounted, ref, toRefs } from 'vue'
import GroupDivider from './GroupDivider.vue'
import GroupUserItem, {
  type DisplayRankUser,
} from '@/components/RankGroupUserItem.vue'
import RankHeader from './RankHeader.vue'
import { formatDeadline } from '@/utils'

type UserGroups = {
  // 跳级用户
  up2: DisplayRankUser[]

  // 晋级用户
  up1: DisplayRankUser[]

  // 保级用户
  keep: DisplayRankUser[]

  // 降级用户
  down1: DisplayRankUser[]
}

const props = defineProps<{
  rankHome: RankHomeInGroup
}>()

const { users, upCount, up2Count, down1Index, seasonEndTime, position } =
  toRefs(props.rankHome.data)

const groupRank = computed(() => parseRank(props.rankHome.data.groupRank))
const down1Rank = computed(() => parseRank(props.rankHome.data.down1Rank))

const leftTimeLabel = computed(() => formatDeadline(seasonEndTime.value))

const rankTitle = computed(() => getRankTitle(groupRank.value))

const userGroups = computed<UserGroups>(() => {
  const groups: UserGroups = {
    up2: [],
    up1: [],
    keep: [],
    down1: [],
  }
  for (let i = 0; i < users.value.length; i++) {
    const user = users.value[i]
    const displayUser: DisplayRankUser = {
      ...user,
      index: i,
      isSelf: position.value === i + 1,
    }

    if (i < up2Count.value) {
      groups.up2.push(displayUser)
      displayUser.status = 'up'
    } else if (i < upCount.value) {
      groups.up1.push(displayUser)
      displayUser.status = 'up'
    } else if (i >= down1Index.value) {
      groups.down1.push(displayUser)
      displayUser.status = 'down'
    } else {
      groups.keep.push(displayUser)
    }
  }

  return groups
})

const up2Label = computed(() => {
  if (up2Count.value > 1) {
    return _t(`前 ${up2Count.value} 名可跳级晋升 2 个段位`)
  } else {
    return _t(`第 1 名可跳级晋升 2 个段位`)
  }
})

const up1Label = computed(() => _t(`前 ${upCount.value} 名可晋升至下一段位`))
const down1Label = computed(() => {
  const lastRankName = getRankName(down1Rank.value.type)

  return _t(`${down1Index.value} 名之后将滑落回${lastRankName}段位`)
})

// 需求：当刚进入页面时，先定位到用户所在的位置
// 此时要求顶部的段位列表不要隐藏，当手动滚动时再隐藏
const isRankHeaderSticky = ref(true)
const isManualScroll = ref(false)

function onScroll() {
  if (isManualScroll.value) {
    isRankHeaderSticky.value = false
  }
}

onMounted(() => {
  const selfUserItem = document.querySelector('.user-item.self')
  selfUserItem?.scrollIntoView({
    block: 'center',
  })

  // 当自动滚动结束后，此时打开手动滚动的标记
  setTimeout(() => {
    isManualScroll.value = true
  }, 10)
})
</script>
<style scoped>
.user-group {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.user-group div:first-child {
  padding-top: 12px;
}

.user-group div:last-child {
  padding-bottom: 12px;
}
</style>
