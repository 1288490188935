<template>
  <NTooltip
    :show="props.show"
    :z-index="props.zIndex"
    :trigger="props.trigger"
    :placement="props.placement"
    :showArrow="props.showArrow"
    @update:show="emit('update:show', $event)"
    @clickoutside="emit('clickoutside')"
  >
    <slot></slot>
    <template #trigger>
      <slot name="trigger"></slot>
    </template>
  </NTooltip>
</template>
<script lang="ts" setup>
import zindexMgmt from '@/utils/zindexMgmt'
import { NTooltip } from 'naive-ui'

const props = withDefaults(
  defineProps<{
    show?: boolean
    // 不传则默认是 hover
    trigger?: 'click' | 'manual' | 'hover'
    zIndex?: number
    placement?: 'top' | 'bottom'
    showArrow?: boolean
  }>(),
  {
    show: undefined,
    trigger: 'hover',
    zIndex: zindexMgmt.nextToastZIndex(),
    placement: undefined,
    showArrow: true,
  }
)

const emit = defineEmits<{
  'update:show': [boolean]
  clickoutside: []
}>()
</script>
<style scoped></style>
