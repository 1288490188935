<template>
  <RatioSpacedContainer class="p-4 w-full h-full g-body-width">
    <Img
      name="ld-look"
      class="w-100px mb-8"
    />

    <div class="text-21px font-semibold">
      {{ _t('下一轮排位赛将于') }}

      <span class="text-ld-brand-500">
        {{ formatDeadline(props.rankHome.data.nextSeasonStartTime) }}
      </span>

      {{ _t('后开始') }}
    </div>
  </RatioSpacedContainer>
</template>
<script lang="ts" setup>
import type { RankHomeFreezeTime } from '@/types/rank'
import { formatDeadline } from '@/utils'

const props = defineProps<{
  rankHome: RankHomeFreezeTime
}>()
</script>
<style scoped></style>
