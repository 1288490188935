<template>
  <div class="flex flex-col g-h-screen p-4 bg-ld-background overflow-auto">
    <div class="hstack flex-wrap">
      <Button
        :label="disabled ? 'disabled(true)' : 'disabled(false)'"
        outlined
        @click="
          () => {
            disabled = !disabled
          }
        "
      />

      <Button
        :label="loading ? 'loading(true)' : 'loading(false)'"
        outlined
        @click="
          () => {
            loading = !loading
          }
        "
      />

      <Button
        :label="icon ? 'icon(true)' : 'icon(false)'"
        outlined
        @click="
          () => {
            icon = !icon
          }
        "
      />

      <Button
        :label="label ? 'label(true)' : 'label(false)'"
        outlined
        @click="
          () => {
            label = !label
          }
        "
      />
      <span>size:</span>
      <SelectButton
        v-model="size"
        :options="['small', 'normal']"
      />

      <span>固定宽度:</span>
      <input
        v-model="isFixedWidth"
        type="checkbox"
      />
    </div>

    <hr class="my-8" />

    <div class="flex flex-col w-300px space-y-2">
      <template
        v-for="scene in sceneList"
        :key="scene"
      >
        <span>
          scene:
          <b>{{ scene }}</b>
        </span>
        <Button
          :scene="scene"
          :label="label ? scene : undefined"
          :icon="icon ? 'pi pi-check' : undefined"
          :disabled="disabled"
          disabled-msg="Button has been disabled!"
          :loading="loading"
          :size="size"
          :class="isFixedWidth ? undefined : 'w-fit'"
        ></Button>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue'
import type { ButtonScene } from '@/components/Button.vue'
import SelectButton from 'primevue/selectbutton'
import { ref } from 'vue'

const disabled = ref(false)
const loading = ref(false)
const icon = ref(false)
const label = ref(true)
const size = ref<'small' | 'normal'>('normal')
const isFixedWidth = ref(true)

const sceneList: ButtonScene[] = [
  'primary',
  'secondary',
  'correct',
  'wrong',
  'danger',
  'choice',
  'choiceSelected',
  'choiceCorrect',
  'choiceWrong',
  'choiceUsed',
  'wechat',
  'invite',
  'vip',
  'passionate',
]
</script>

<style scoped></style>
