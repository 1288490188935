import NotificationUserGuide from '@/components/NotificationUserGuide.vue'
import { randomPick } from '.'
import dayjs from 'dayjs'
import { range } from 'lodash-es'
import type { LocalNotification } from './bridge'

const NOTIFICATION_ID_START = {
  DAILY_REMINDER: 1000,
}
const DAILY_REMINDER_LENGTH = 7

// App 是否支持 notification 的功能
export async function checkNotificationAvaiable() {
  if (!_global.isInsideApp) return false

  // 如果 bridge 直接返回 null，则表示这个接口没有，认为不支持通知功能
  return (await _bridge.checkNotificationStatus()) != null
}

export function clearDailyReminders() {
  const startId = NOTIFICATION_ID_START.DAILY_REMINDER

  // 先移除所有的定时提醒
  const ids = range(startId, startId + DAILY_REMINDER_LENGTH)
  _bridge.clearLocalNotifications(ids)
}

// 重置每日定时提醒，设置 7 天内通知
export async function resetDailyReminder() {
  // 推送文案 {title,content } 从数组中随机选择
  const pushTexts = [
    {
      title: _t('⏰ 叮咚！学习时间到~'),
      content: _t("鸭鸭喊你学习啦！ Let's go!"),
    },
    {
      title: _t('⏰ 嘎嘎~鸭鸭喊你学习啦！'),
      content: _t('就现在！让我们一起学个痛快！'),
    },
    {
      title: _t('💪 学习时间到'),
      content: _t('你的大脑已经饥渴难耐了！快冲鸭！'),
    },
    {
      title: _t('🚀 滴滴！学习时间到'),
      content: _t('是时候展现真正的技术了！走起！ '),
    },
    {
      title: _t('✨ 嘿~学习时间到啦！'),
      content: _t('去吧，去成为那个闪闪发光的自己吧~ '),
    },
    {
      title: _t('🔥 开始学习，就现在'),
      content: _t('相信自己，你比想象中更强大！'),
    },
  ]

  clearDailyReminders()

  // 功能未开启
  const notiConfig = _store.notiConfig
  if (!notiConfig.enabled || !notiConfig.dailyReminder.enabled) {
    return
  }
  const startId = NOTIFICATION_ID_START.DAILY_REMINDER

  const time = notiConfig.dailyReminder.time

  const notifications: LocalNotification[] = []

  for (let index = 0; index < DAILY_REMINDER_LENGTH; index++) {
    const hour = time.hour
    const minute = time.minute
    const now = new Date()
    const fireTime = dayjs(now)
      .set('hour', hour)
      .set('minute', minute)
      .set('second', 0)
      .add(index, 'day')
      .valueOf()
    const pushText = randomPick(pushTexts)
    notifications.push({
      id: index + startId,
      title: pushText.title,
      content: pushText.content,
      fireTime: fireTime,
    })
  }
  _bridge.sendLocalNotifications(notifications)
}

// 尝试开启通知权限 并且轮询检查通知权限状态
export async function tryToEnableNotification(
  abortSignal?: AbortSignal
): Promise<'enabled' | 'disabled'> {
  return new Promise(async (resolve, reject) => {
    const initStatus = await _bridge.checkNotificationStatus()
    if (initStatus === 'enabled') {
      resolve('enabled')
      return
    }

    let timer = 0

    // 轮询 获取通知开关状态
    // 如果开始是 'unset' 则不是'unset'时停止轮询
    // 如果开始是 'disabled' 则是'enabled'时停止轮询
    async function pollNotificationStatus() {
      if (abortSignal?.aborted) {
        clearTimeout(timer)
        reject('timeout')
        return
      }

      const stopStatus =
        initStatus === 'unset' ? ['enabled', 'disabled'] : ['enabled']

      const status = await _bridge.checkNotificationStatus()
      if (stopStatus.includes(status)) {
        resolve(status as any)
        return
      }

      timer = setTimeout(pollNotificationStatus, 1000)
    }

    // 检查通知权限状态
    pollNotificationStatus()

    await _bridge.tryToEnableNotification()
  })
}

// 引导通知
// 这里的引导通知，有多种，优先级从高到低依次是
// 完成任意 1 次闯关后的引导
// 累计完成 3 关后的引导
// 累计完成 10 关后的引导
export async function checkNotificationGuide() {
  const status = await _bridge.checkNotificationStatus()

  if (status === 'unset') {
    await _store.fetchStatsOverview()
    const stageCount = _store.statsOverview?.stageCount ?? 0

    if (stageCount > 0) {
      // 完成任意 1 次闯关后的引导
      if (!_store.notiConfig.guideCompleted.dailyReminderAnyStage) {
        showNotificationGuide()
        _store.notiConfig.guideCompleted.dailyReminderAnyStage = true

        return
      }
      // 累计完成 3 关后的引导
      if (
        stageCount == 3 &&
        !_store.notiConfig.guideCompleted.dailyReminderThreeStages
      ) {
        showNotificationGuide()
        _store.notiConfig.guideCompleted.dailyReminderThreeStages = true
        return
      }
      // 累计完成 10 关后的引导
      if (
        stageCount == 10 &&
        !_store.notiConfig.guideCompleted.dailyReminderTenStages
      ) {
        showNotificationGuide()
        _store.notiConfig.guideCompleted.dailyReminderTenStages = true
        return
      }
    }
  }
}

async function showNotificationGuide() {
  // 默认替用户设置的时间 最近学习的开始时间(如没有最近学习时间，取当前时间)
  // 时间往前，取 quarter 整数倍时间
  // eg：最近学习是 19:57 分开始的，则默认设为 19:45 分
  // 使用 24 小时制的时间格式
  const nowDate = new Date()
  const m = nowDate.getMinutes()
  const quarter = Math.floor(m / 15)
  nowDate.setMinutes(quarter * 15)

  const time = await _presentContent<{
    hour: number
    minute: number
  }>(NotificationUserGuide, {
    rootClass: 'min-w-400px max-w-600px',
    props: {
      initialTime: {
        hour: nowDate.getHours(),
        minute: nowDate.getMinutes(),
      },
    },
  })
  if (time) {
    _store.notiConfig.dailyReminder.time = time

    const status = await tryToEnableNotification()
    if (status === 'enabled') {
      _store.notiConfig.enabled = true
      // 开启每日定时提醒
      _store.notiConfig.dailyReminder.enabled = true
      resetDailyReminder()
    }
  }
}
