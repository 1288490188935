import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import i18n from '@/i18n'
import { displayCurrency, formatDate } from '@/utils'
import { createApp, watch } from 'vue'
import type { App, Component } from 'vue'
import * as Sentry from '@sentry/vue'
import { useCommonStore } from './stores'

import 'vue3-json-viewer/dist/index.css'

// primevue
import PrimeVue from 'primevue/config'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'
import Textarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'
import Message from 'primevue/message'
import Password from 'primevue/password'
import Dialog from 'primevue/dialog'
import Card from 'primevue/card'
import Divider from 'primevue/divider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import 'primeicons/primeicons.css'

// custome components
import Icon from '@/components/Icon.vue'
import IconBg from '@/components/IconBg.vue'
import Img from '@/components/Img.vue'
import Loading from '@/components/Loading.vue'
import DebugLabel from '@/components/DebugLabel.vue'
import DebugButton from '@/components/DebugButton.vue'
import Avatar from '@/components/Avatar.vue'
import Button from '@/components/Button.vue'
import RatioSpacedContainer from '@/components/RatioSpacedContainer.vue'
import OverlayPanel from '@/components/OverlayPanel.vue'
import TextButton from '@/components/TextButton.vue'
import SafeAreaTopSpacer from '@/components/SafeAreaTopSpacer.vue'
import Tooltip from '@/components/Tooltip.vue'

// vue virtual scroller
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

// vant
import 'vant/lib/popup/index.css'
import 'vant/lib/toast/index.css'
import 'vant/lib/index.css'
import { Toast, TimePicker } from 'vant'

// Vue App global settings
export function createMyApp(
  rootComponent: Component,
  rootProps?: Record<string, unknown> | null
): App<Element> {
  const app = createApp(rootComponent, rootProps)

  // store
  const pinia = createPinia()
  app.use(pinia)

  // i18n
  app.use(i18n)

  // vant
  app.use(Toast)
  app.use(TimePicker)

  // head
  const head = createHead()
  app.use(head)

  // PrimeVue
  app.use(PrimeVue, { ripple: true })
  app.use(DialogService)
  app.use(ToastService)
  app.component('InputText', InputText)
  app.component('InputSwitch', InputSwitch)
  app.component('Dropdown', Dropdown)
  app.component('Message', Message)
  app.component('Password', Password)
  app.component('Dialog', Dialog)
  app.component('Card', Card)
  app.component('Textarea', Textarea)
  app.component('Divider', Divider)
  app.component('DataTable', DataTable)
  app.component('Column', Column)

  // custom components
  app.component('Icon', Icon)
  app.component('IconBg', IconBg)
  app.component('Img', Img)
  app.component('Loading', Loading)
  app.component('DebugLabel', DebugLabel)
  app.component('DebugButton', DebugButton)
  app.component('Avatar', Avatar)
  app.component('Button', Button)
  app.component('RatioSpacedContainer', RatioSpacedContainer)
  app.component('OverlayPanel', OverlayPanel)
  app.component('TextButton', TextButton)
  app.component('SafeAreaTopSpacer', SafeAreaTopSpacer)
  app.component('Tooltip', Tooltip)

  app.component('DynamicScroller', DynamicScroller)
  app.component('DynamicScrollerItem', DynamicScrollerItem)

  // global filters
  app.config.globalProperties.$f = {
    date: formatDate,
    currency: displayCurrency,
  }
  app.config.globalProperties._t = _t
  app.config.globalProperties._global = _global

  // sentry
  initSentry(app)

  return app
}

function initSentry(app: App<Element>) {
  if (_global.isLocal) return

  Sentry.init({
    app,
    dsn: _global.isPcMode
      ? 'https://48ea49c160946043d9d397448a1d8b29@sentry.qianmo8.com/6'
      : 'https://be2009cf49e97421325dd13912eb7706@sentry.qianmo8.com/7',
    environment: _global.sentryEnvironment,
    normalizeDepth: 10,

    release: _global.gitCommit,

    beforeSend(event, hint) {
      const err = hint.originalException as any

      // sentry event 默认不会带上 error 中的额外信息，这里需要手动添加一下
      if (event.extra != null && err.extra != null) {
        Object.assign(event.extra, err.extra)
      }

      // 手动标记忽略
      if (err?.skipSentry) return null

      // 忽略 404, 401
      if (err?.isAxiosError && [404, 401].includes(err?.response?.status))
        return null

      // 使用 abort singal 中断请求
      if (err?.isAxiosError && err.name === 'CanceledError') return null

      return event
    },
  })

  const store = useCommonStore()

  watch(
    () => store.user,
    val => {
      if (val != null) {
        Sentry.setUser({ id: val.id })
      }
    }
  )
}

window._reportError = function (
  source:
    | Error
    | string
    | {
        msg: string
        attachments?: {
          filename: string
          data: string | Uint8Array
          contentType: string
        }[]
        [key: string]: any
      }
) {
  if (source instanceof Error) {
    Sentry.captureException(source)
    return
  }

  if (typeof source === 'string') {
    Sentry.captureMessage(source)
    return
  }

  const { msg, attachments, ...rest } = source
  if (attachments) {
    Sentry.withScope(scope => {
      for (const a of attachments) {
        scope.addAttachment({
          filename: a.filename,
          data: a.data,
          contentType: a.contentType,
        })
      }
      Sentry.captureMessage(msg, { extra: rest })
    })
  } else {
    Sentry.captureMessage(msg, { extra: rest })
  }
  return
}
