<template>
  <div
    class="text-ld-brand-500 font-600 inline"
    :class="{
      'opacity-60': props.loading,
    }"
    :style="style"
    @click="onClick"
  >
    {{ props.label }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  label: string
  loading?: boolean
}>()

const style = computed(() => ({
  cursor: props.loading ? 'not-allowed' : 'pointer',
}))

const emit = defineEmits<{
  click: [any]
}>()

function onClick(evt: any) {
  if (props.loading) {
    return
  }
  emit('click', evt)
}
</script>

<style scoped></style>
