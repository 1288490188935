import CardDetailPopup from '@/components/CardDetailPopup/CardDetailPopup.vue'
import type { Card } from '@/types/core'

export function showCardDetail(
  card: Card,
  isFirstLearn: boolean,
  onNext?: VoidFunction
) {
  return _presentContent(CardDetailPopup, {
    rootClass: 'w-600px min-h-400px max-h-650px',
    props: {
      card,
      isFirstLearn,
    },
    dialog: {
      dismissableMask: false,
      closeOnEscape: false,
      pt: {
        root: {
          // TODO: tailwind css 生成不了使用 calc 的高度，这里先用 style
          style: !_global.isPcMode
            ? 'max-height: calc(var(--ld-viewport-height) - 100px);'
            : '',
        },
        content: {
          style: 'padding: 0px',
        },
      },
    },
  }).then(onNext)
}
