<template>
  <Loading class="h-full bg-ld-background" />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { wxLogin, wxRegister, type UserInfo } from '@/api/auth'
import { queryWechatOpenId } from '@/api/public'
import { useCommonStore } from '@/stores'
import { Code } from '@/api/code'
import { afterLogin } from '@/shared/landing'

// query:
//  - redirect?: redirect url
//  - wechatType: "MP" | "APP", default to "MP"

const router = useRouter()
const route = useRoute()
const code = route.query.code as string
const store = useCommonStore()

onMounted(async () => {
  const wechatType = (route.query.wechatType as 'MP' | 'APP') ?? 'MP'

  // 用于获取 openId 的场景
  if (route.query.login === 'false') {
    const res = await queryWechatOpenId(code, wechatType)

    if (res.code === Code.Ok) {
      _db.wxOpenId = res.data.openId
    } else {
      _store.getWechatOpenIdFailed = true
      _reportError({ msg: 'queryWechatOpenId failed', res })
    }

    // 跳转回 redirect 地址
    let targetPage = _global.homePage
    if (route.query.redirect) {
      targetPage = decodeURIComponent(route.query.redirect as string)
    }
    router.replace(targetPage)
    return
  }

  wxLogin({ code, wechatType }).then(res => {
    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    _db.wxOpenId = res.data.wxOpenId

    // 已经注册了
    if (res.data.loginResponse) {
      onAuthDone(res.data.loginResponse.token, res.data.loginResponse.user)
      return
    }

    // 自动注册
    return wxRegister({ wxToken: res.data.wxToken }).then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      onAuthDone(res.data.token, res.data.user)
    })
  })
})

function onAuthDone(token: string, user: UserInfo) {
  store.login(token, user)
  afterLogin(router, route)
}
</script>

<style scoped></style>
