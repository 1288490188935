<template>
  <div class="h-full w-full flex flex-col flex-1 overflow-hidden items-center">
    <div
      class="flex-1 min-h-0 g-body-width overflow-y-auto card-container pt-16px"
      :class="[
        'pb-72px',
        {
          'justify-between': viewSize.width <= 700,
        },
      ]"
    >
      <div class="p-4">
        <div class="relative">
          <slot name="content" />
        </div>
      </div>
      <div class="p-4">
        <slot name="options" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const viewSize = window._viewSize
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 16px;
}
</style>
