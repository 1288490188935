<template>
  <span
    :class="[
      'g-cloze cursor-pointer cloze',
      {
        focused: props.focused,
      },
    ]"
    @click="onTextClick"
  >
    <template v-if="props.rectifyMode">
      <span
        v-if="props.inputText === props.node.text"
        class="correct"
      >
        {{ props.inputText }}
      </span>

      <template v-else-if="props.inputText">
        <span class="wrong">{{ props.inputText }}</span>
        <span class="correct">&nbsp;{{ props.node.text }}</span>
      </template>

      <span
        v-else
        class="miss"
      >
        {{ props.node.text }}
      </span>
    </template>

    <span
      v-else-if="props.inputText"
      class="filled"
    >
      {{ props.inputText }}
    </span>

    <!-- 这里是占位用的，实际不会展示内容 -->
    <span v-else>
      {{ props.node.text }}
    </span>
  </span>
</template>

<script lang="ts" setup>
import type { Cloze } from '@/types/core'

const props = withDefaults(
  defineProps<{
    node: Cloze

    focused?: boolean
    rectifyMode?: boolean

    // 用户填入的文字，也可以是通过选择的
    inputText?: string
  }>(),
  {
    focused: false,
    inputText: undefined,
    rectifyMode: undefined,
  }
)

function onTextClick(e: MouseEvent) {
  e.stopPropagation()
}
</script>

<style scoped>
.cloze {
  color: transparent;
}

.filled {
  color: var(--ld-brand-500);
}

.wrong {
  color: var(--red-500);
  text-decoration: line-through;
}

.miss {
  color: var(--yellow-500);
}

.correct {
  color: var(--green-500);
}

.focused {
  border-bottom-color: var(--ld-brand-500);
}
</style>
