<template>
  <div class="h-full flex flex-col bg-ld-background">
    <AppBar
      :title="_t('提醒通知')"
      @back="onBack"
    />
    <div class="flex flex-col p-4 gap-6">
      <div>
        <ListCell
          :title="_t('提醒通知')"
          :arrow="false"
        >
          <template #action>
            <InputSwitch
              :model-value="store.notiConfig.enabled"
              @update:modelValue="onNotificationSwitch"
            />
          </template>
        </ListCell>
      </div>

      <div v-if="store.notiConfig.enabled">
        <ListCell
          :title="_t('每日定时提醒')"
          :arrow="false"
        >
          <template #action>
            <InputSwitch
              :model-value="store.notiConfig.dailyReminder.enabled"
              @update:modelValue="onSwitchDailyReminder"
            />
          </template>
        </ListCell>

        <ListCell
          :title="_t('提醒时间')"
          :arrow="false"
          @click="onSetDailyReminderTime"
        >
          <template #action>
            <div class="flex items-center justify-center">
              <div>{{ dailyReminderTime }}</div>
              <i class="pi pi-angle-right text-20px"></i>
            </div>
          </template>
        </ListCell>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ListCell from '@/components/ListCell/ListCell.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'
import { computed, onUnmounted } from 'vue'
import TimePickerSheet from '@/components/TimePickerSheet.vue'
import {
  tryToEnableNotification,
  resetDailyReminder,
  clearDailyReminders,
} from '@/utils/notification'
import { formatDate } from '@/utils'

const store = useCommonStore()

const abortController = new AbortController()

onUnmounted(() => {
  abortController.abort()
})

// 提醒时间显示 HH:mm
const dailyReminderTime = computed(() => {
  const { hour, minute } = store.notiConfig.dailyReminder.time
  const date = new Date()
  date.setHours(hour)
  date.setMinutes(minute)

  return formatDate(date, 'HH:mm')
})

function resetAllNotifications() {
  resetDailyReminder()
}

async function onNotificationSwitch(enabled: boolean) {
  if (enabled) {
    const status = await _bridge.checkNotificationStatus()

    if (status === 'enabled') {
      store.notiConfig.enabled = true
      resetAllNotifications()
    } else {
      tryToEnableNotification(abortController.signal)
        .then(status => {
          if (status === 'enabled') {
            store.notiConfig.enabled = true
            resetAllNotifications()
          }
        })
        .catch(_err => {
          // ignore
        })
    }
  } else {
    store.notiConfig.enabled = false
    _bridge.clearAllLocalNotifications()
  }
}

// 开关学习定时提醒
function onSwitchDailyReminder(enabled: boolean) {
  store.notiConfig.dailyReminder.enabled = enabled

  if (enabled) {
    resetDailyReminder()
  } else {
    clearDailyReminders()
  }
}

// 设置提醒时间
async function onSetDailyReminderTime() {
  const time = await _presentContent<{
    hour: number
    minute: number
  }>(TimePickerSheet, {
    rootClass: 'min-w-400px max-w-600px',
    props: {
      title: _t('提醒时间'),
      initialTime: store.notiConfig.dailyReminder.time,
    },
  })

  if (time) {
    store.notiConfig.dailyReminder.time = time
    resetDailyReminder()
  }
}

const emit = defineEmits<{
  back: []
}>()

function onBack() {
  emit('back')
}
</script>

<style scoped></style>
