import { reactive, watch } from 'vue'
import merge from 'lodash-es/merge'

import type { ApiEnv } from './init'
import type { Locale } from './i18n'
import type { Theme } from './stores/common-store'
import type { Unit, UnitEvent } from './api/learn'
import type { DuelQueueState } from './pages/UnitLearn/DuelQueue/queue'
import type { QueueType } from '@/types/core'
import {
  type InsertQueueState,
  type InsertQueueConfig,
  defaultConfig as defaultInsertConfig,
} from './pages/UnitLearn/InsertQueue/queue'

const LOCAL_STORAGE_KEY = '_data'

export type PerUserCache = {
  // 上一张卡片引导提示是否完成过
  lastCardGuideCompleted: boolean

  // 狂热模式引导提示是否完成过
  passionateModeGuideCompleted: boolean

  // 狂热学习是否开启
  passionateMode: boolean

  // 提醒通知
  notification: NotificationConfig
}
export type NotificationConfig = {
  // 是否开启通知
  enabled: boolean
  // 每日定时提醒
  dailyReminder: {
    enabled: boolean
    time: {
      hour: number
      minute: number
    }
  }
  // 记录引导提示是否完成过
  guideCompleted: {
    // 每日定时提醒
    // 任意一关后
    dailyReminderAnyStage: boolean
    // 累计3关后
    dailyReminderThreeStages: boolean
    // 累计10关后
    dailyReminderTenStages: boolean
  }
}

export interface StageUnit extends Unit {
  queueType: QueueType
  updatedAt: number
  completed: boolean
  completedCards: number[]

  challenge?: {
    index: number
    npcName: string
  }

  events: {
    [cardId in string]: UnitEvent[]
  }
  cardDurations: Record<number, number>
  cardLearnTimes: Record<number, number>
  currentComboCount: number
  maxComboCount: number
}

export interface DB {
  locale: Locale
  theme: Theme
  loginToken: string
  wxOpenId: string
  packageState: Record<
    number,
    {
      expandedKeys: Record<string, boolean>
      selectedChapterId: string
      selectedCardId?: string
    }
  >
  // 每个用户单独存储的数据, key 是 LID
  userCache: Record<string, PerUserCache>
  debug: {
    apiEnv: ApiEnv
    initialUserId?: string
    floatingBallTop: number
    floatingBallLeft: number
    skip: boolean
    skipEnergy: boolean
    forceOrderPriceToZero: boolean
    showDebugLabel: boolean
    showDebugFloatingBall: boolean

    insertQueueConfig: Partial<InsertQueueConfig>
  }
  stageUnit: StageUnit | null
  tmp: {
    queueCards: Record<string, { cardId: number; review: boolean }[]>
  }

  // 游客模式相关
  guest: {
    identityTags: string[]
  }

  // 学习进度缓存
  unitProgressCacheV2: {
    [QueueType.Duel]?: DuelQueueState
    [QueueType.Insert]?: InsertQueueState
  }

  // 是否开启音效反馈
  soundFeedbackOn: boolean
  // 是否开启震动反馈
  vibrateFeedbackOn: boolean
}

const saved = localStorage.getItem(LOCAL_STORAGE_KEY)

export function mergeDefaultDb(db: Partial<DB>): DB {
  return merge(
    {
      locale: 'zh',
      theme: 'light',
      loginToken: '',
      wxOpenId: '',
      packageState: {},
      userCache: {},
      passionateMode: false,
      debug: {
        floatingBallTop: 32,
        floatingBallLeft: 32,
        apiEnv: 'staging',
        skip: false,
        skipEnergy: false,
        forceOrderPriceToZero: false,
        showDebugLabel: false,
        showDebugFloatingBall: true,
        insertQueueConfig: {
          practiceCardTimes: defaultInsertConfig.practiceCardTimes,
        },
      },
      stageUnit: null,
      tmp: {
        queueCards: {},
      },
      guest: {
        identityTags: [] as string[],
      },
      unitProgressCacheV2: {},
      soundFeedbackOn: true,
      vibrateFeedbackOn: true,
    } as DB,
    db
  ) as DB
}

// TODO(xunyi): 需要有一种机制来清理旧数据
const db = reactive<DB>(mergeDefaultDb(saved ? (JSON.parse(saved) as DB) : {}))

watch(db, () => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(db))
})

export default db
