import { getCurrentUser } from '@/api/auth'
import { fetchUserTags } from '@/api/user'
import { wechatRedirect } from '@/utils/wechat'
import { ref, type Ref } from 'vue'
import { confirmStageUnitContinue } from '@/shared'
import {
  type RouteLocationNormalizedLoaded,
  type RouteLocationRaw,
  type Router,
} from 'vue-router'

// 确保获取到微信 openId，微信支付需要用
// PC 由于不在微信环境，所以这里执行了也没有影响

// 执行登录后逻辑，负责跳转到新页面
export async function afterLogin(
  router: Router,
  route: RouteLocationNormalizedLoaded
) {
  // 注销中
  if (_store.user!.terminationResponse.willTerminateAt) {
    router.replace({
      name: 'terminating',
      query: {
        willTerminateAt: _store.user!.terminationResponse.willTerminateAt,
      },
    })
    return
  }

  // 确保 openId
  if (
    _global.isInsideWechat &&
    !_db.wxOpenId &&
    !_store.getWechatOpenIdFailed
  ) {
    wechatRedirect()
    return
  }

  // 执行回调
  _store.afterLoginCallbacks.forEach(fn => fn())

  // 如果是未设置过身份标签的用户，则跳转到选身份标签页面
  {
    const res = await fetchUserTags()
    if (res.identityTagResponses.length === 0) {
      router.replace({
        name: 'identity-onboarding',
      })
      return
    }
  }

  // 从哪里跳转到 landing 页面的再跳转回去，否则跳转到首页
  {
    let targetPage = _global.homePage as string | RouteLocationRaw
    if (route.query.redirect) {
      targetPage = decodeURIComponent(route.query.redirect as string)
    }
    await router.replace(targetPage)
  }

  // 【学习中断】提醒弹窗
  confirmStageUnitContinue(router)
}

// return loading status
export function useLandingLogic(
  router: Router,
  route: RouteLocationNormalizedLoaded
): Ref<boolean> {
  if (_store.isLoggedIn) {
    afterLogin(router, route)
    return ref(true)
  }

  const loading = _store.token !== '' ? ref(true) : ref(false)

  if (loading.value) {
    // 自动登录
    getCurrentUser(true)
      .then(res => {
        if (res.user) {
          _store.login(_store.token, res.user)
          return afterLogin(router, route)
        }
      })
      .finally(() => {
        loading.value = false
      })
  }

  return loading
}
