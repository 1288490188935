import type { EnWordCard } from '@/types/core'
import { shuffle, uniqBy } from 'lodash-es'

export interface Option {
  word: string
  definition: string
  isAnswer: boolean
  illustration?: string
}

const MAX_OPTIONS = 4
export function genOptions(card: EnWordCard, altCards: EnWordCard[]) {
  const answerOption: Option = {
    word: card.word,
    definition: card.definition,
    isAnswer: true,
    illustration: card.illustration,
  }

  const distractors: Option[] = []

  card.distrators.forEach(item => {
    distractors.push({
      word: item.word,
      definition: item.definition,
      isAnswer: false,
    })
  })

  altCards.forEach(item => {
    distractors.push({
      word: item.word,
      definition: item.definition,
      illustration: item.illustration,
      isAnswer: false,
    })
  })

  const selectedDistractors = filterAndSelectDisctractors(distractors, card)

  return shuffle([...selectedDistractors, answerOption])
}

export function genWordPickOptions(card: EnWordCard, altCards: EnWordCard[]) {
  const answerOption: Option = {
    word: card.word,
    definition: card.definition,
    isAnswer: true,
    illustration: card.illustration,
  }

  const distractors: Option[] = []

  altCards.forEach(item => {
    if (item.illustration) {
      distractors.push({
        word: item.word,
        definition: item.definition,
        illustration: item.illustration,
        isAnswer: false,
      })
    }
  })

  const selectedDistractors = filterAndSelectDisctractors(distractors, card)

  return shuffle([...selectedDistractors, answerOption])
}

// 过滤干扰项中释义重复的以及和单词释义重复的选项，并从中随机选择 MAX - 1 个选项出来
function filterAndSelectDisctractors(
  options: Option[],
  card: EnWordCard
): Option[] {
  // 先根据释义去重，再过滤掉和当前单词释义一样的
  const filteredDistractors = uniqBy(options, op =>
    op.definition.trim()
  ).filter(op => op.definition.trim() !== card.definition.trim())

  return shuffle(filteredDistractors).slice(0, MAX_OPTIONS - 1)
}
