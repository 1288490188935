import type { DictEnWord } from '@/api/learn'
import WordSheet from './WordSheet.vue'

export function showWordSearchSheet(enWord: DictEnWord) {
  return _presentContent(WordSheet, {
    rootClass: 'w-600px max-h-650px',
    props: {
      enWord,
    },
    dialog: {
      pt: {
        root: {
          style: !_global.isPcMode
            ? 'max-height: calc(var(--ld-viewport-height) - 100px);'
            : '',
        },
        content: {
          style: 'padding: 0px',
        },
      },
    },
  })
}
