<template>
  <div class="w-full p-4 flex flex-col items-center">
    <div class="w-full flex items-enter h-24px">
      <span class="text-17px leading[1.4] mx-auto">
        {{ title }}
      </span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 z-1 w-25px"
        @click="emit('done')"
      ></Icon>
    </div>

    <video
      ref="videoElement"
      class="w-full my-5 video-container"
      :style="style"
      :src="src"
      autoplay
      @click="onClickVideo"
    ></video>

    <Button
      class="w-full"
      :label="_t('我知道了')"
      @click="emit('done')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

const props = defineProps<{
  title: string
  src: string
  ratio: number
}>()
const videoElement = ref<HTMLVideoElement | null>(null)

const style = computed(() => {
  return {
    aspectRatio: props.ratio,
  }
})

function onClickVideo() {
  // 如果视频播放完毕，再次点击视频时，重置视频播放
  if (videoElement.value?.ended) {
    videoElement.value.currentTime = 0
    videoElement.value.play()
  }
}

const emit = defineEmits<{
  done: []
}>()
</script>

<style scoped>
.video-container {
  border: 2px solid var(--ld-border);
  border-radius: 8px;
  box-shadow: 0px 0px 2px 2px var(--ld-shadow);
  width: 100%;
}
</style>
