<template>
  <Loading
    v-if="data.loading"
    class="h-full"
  />

  <div
    v-else
    class="w-full bg-ld-background h-full overflow-auto flex flex-col relative"
  >
    <SafeAreaTopSpacer v-if="_global.isMobileMode" />
    <div class="py-3 px-4">
      <div class="search-panel">
        <Icon
          name="search"
          class="w-20px text-gray-500"
        />

        <div
          class="flex-1 border-none ml-1 py-1 text-ld-label-secondary"
          @click="onSearchInputFocus"
        >
          {{ _t('搜索卡包') }}
        </div>

        <Divider
          layout="vertical"
          class="h-20px !p-0"
        />

        <Icon
          name="pkg-create"
          class="w-20px ml-auto cursor-pointer text-gray-500"
          @click="onPackageGet"
        />
      </div>
    </div>

    <PkgList
      :packages="data.packages"
      :empty-hint="_t('暂无卡包')"
      class="px-4 flex-1 overflow-auto"
      @edit="onPackageEdit"
      @share-setting="onPackageShareSetting"
      @delete="onPackageDelete"
      @remove-shelf="onPackageRemoveShelf"
      @click="onClickPackage"
    />

    <div
      class="floating-create-btn cursor-pointer"
      @click="onPackageGet"
    >
      <i class="pi pi-plus text-white font-semibold text-18px"></i>
    </div>
  </div>
</template>

<script setup lang="ts">
import { deletePackageById, fetchPackages } from '@/api/package-source'
import { useCommonStore } from '@/stores'
import { onMounted, onUnmounted, watch } from 'vue'
import { reactive } from 'vue'
import Loading from '@/components/Loading.vue'
import PkgList from '@/components/PkgList/PkgList.vue'
import PkgForm from '@/components/PkgList/PkgForm.vue'
import ShareForm from '@/components/PkgList/ShareForm.vue'
import PkgSearch from '@/components/pkg-search/PkgSearch.vue'
import { useRouter } from 'vue-router'
import hotkeys from 'hotkeys-js'
import { removeShelfPackageById } from '@/api/user'

import type { PackageBasic } from '@/api/package-source'
import { getPackageShowDate } from '@/utils/package'
import { Code } from '@/api/code'
import { FeatureType } from '@/api/beta-feature'
import { openPackageCreateDialog } from '@/shared'
import type { OpenDialogPromise } from '@/types/global'

const store = useCommonStore()
const router = useRouter()

const data = reactive({
  packages: [] as PackageBasic[],
  loading: true,
})

onMounted(() => {
  hotkeys('ctrl+k,command+k', () => {
    onSearchInputFocus()
  })
})

onUnmounted(() => {
  hotkeys.unbind('ctrl+k,command+k')
})

fetchData()

watch(() => store.user?.id, fetchData)

function onSearchInputFocus() {
  if (_global.isPcMode) {
    showPkgSearchDialog()
  } else {
    router.push({ name: 'pkg-search' })
  }
}

function onClickPackage(id: number) {
  router.push({ name: 'package', params: { id: id } })
}

let searchDialog: OpenDialogPromise<void> | undefined
async function showPkgSearchDialog() {
  if (searchDialog != null) return

  searchDialog = _openDialog(PkgSearch, {
    props: {
      isPage: false,
      navigator: 'modal',
      onPackageChallenge(pkgId: number) {
        router.push({
          name: 'atlas',
          query: {
            pkgId,
          },
        })
      },
    },
    dialog: {
      contentClass: 'bg-[var(--surface-hover)] ',
      showHeader: false,
      pt: {
        content: {
          style: 'padding: 0px;',
        },
      },
    },
    rootClass: 'w-1024px mx-10 h-[80vh] min-w-[480px] min-h-[480px]',
  })
  searchDialog.then(() => {
    searchDialog = undefined
  })
}

async function fetchData() {
  if (!store.isLoggedIn) {
    data.packages = []
    return
  }
  data.loading = true

  try {
    await fetchPackagesData()
  } finally {
    data.loading = false
  }
}

async function fetchPackagesData() {
  const res = await fetchPackages()

  data.packages = res.packages.sort(
    (a, b) => getPackageShowDate(b) - getPackageShowDate(a)
  )
}

function onPackageEdit(id: number) {
  const pkg = data.packages.find(pkg => pkg.id === id)!

  _openDialog(PkgForm, {
    title: '编辑卡包',
    rootClass: 'p-0',
    dialog: {
      showHeader: false,
      dismissableMask: true,
      contentClass: 'px-4 py-3',
    },
    props: {
      package: pkg,
      onUpdate(newPkg: PackageBasic) {
        pkg.name = newPkg.name
        pkg.style = newPkg.style
        pkg.contentTags = newPkg.contentTags
      },
    },
  })
}

function onPackageShareSetting(id: number) {
  if (!store.isAlphaCreator) {
    _confirm({
      scene: 'confirm',
      content: _t('卡包共享功能尚未开放，你可以申请抢先测试资格'),
      primaryText: _t('去申请'),
      secondaryText: _t('暂不'),
      async onPrimaryClick(resolve) {
        resolve(true)
        router.push({
          name: 'beta-feature',
          query: {
            name: FeatureType.AlphaCreator,
          },
        })
      },
    })

    return
  }

  const pkg = data.packages.find(pkg => pkg.id === id)!

  _openDialog(ShareForm, {
    title: '共享',
    rootClass: 'p-0 g-dialog',
    dialog: {
      showHeader: false,
      contentClass: 'px-4 py-3',
    },
    props: {
      package: pkg,
      onUpdate: (pkgRes: PackageBasic) => {
        const index = data.packages.findIndex(pkg => pkg.id === id)
        data.packages[index] = pkgRes
      },
    },
  })
}

function getPkgTitleForDialog(name: string) {
  return `《${name.length > 20 ? name.slice(0, 20) + '...' : name}》`
}

function onPackageDelete(id: number) {
  const pkg = data.packages.find(pkg => pkg.id === id)!

  _confirm({
    scene: 'warn',
    title: _t(`是否删除${getPkgTitleForDialog(pkg.name)}？`),
    content: _t('删除后无法恢复，请确认'),
    primaryText: _t('暂不'),
    secondaryText: _t('删除'),
    async onSecondaryClick(resolve) {
      const res = await deletePackageById(pkg.id)

      if (res.code !== Code.Ok) {
        resolve(false)
        _message.info(res.message)
      }

      resolve(true)
      _message.info(_t('卡包已删除'))
      const index = data.packages.findIndex(pkg => pkg.id === id)
      data.packages.splice(index, 1)
    },
  })
}

function onPackageRemoveShelf(id: number) {
  const pkg = data.packages.find(pkg => pkg.id === id)!

  _confirm({
    scene: 'warn',
    content: _t(`是否将${getPkgTitleForDialog(pkg.name)}从我的卡包中移除？`),
    primaryText: _t('暂不'),
    secondaryText: _t('移除'),
    async onSecondaryClick(resolve) {
      try {
        await removeShelfPackageById(pkg.id)

        const index = data.packages.findIndex(pkg => pkg.id === id)
        data.packages.splice(index, 1)
        _message.success(`[${pkg.name}] 已从我的卡包中移除`)

        resolve(true)
      } catch (_e) {
        resolve(false)
      }
    },
  })
}

function onPackageGet() {
  openPackageCreateDialog({
    onCreate(pkg: PackageBasic) {
      onClickPackage(pkg.id)
    },
  })
}
</script>

<style scoped>
.search-panel {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 40px;
  border: 1px solid var(--ld-border);
  padding: 10px 12px;
}

.floating-create-btn {
  width: 36px;
  height: 36px;
  position: absolute;
  background-color: var(--ld-brand-500);
  bottom: 24px;
  right: 12px;
  border-radius: 999px;
  box-shadow: 0px 0px 8px 0px var(--ld-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
