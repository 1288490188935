<template>
  <Loading class="mb-8 mt-auto h-400px"></Loading>

  <div class="p-4">
    <div class="break-words">
      Full URL:
      <span class="font-bold">{{ url }}</span>
    </div>

    <div class="mt-4">Query Params:</div>
    <ul class="pl-4">
      <li
        v-for="(item, idx) in params"
        :key="idx"
        class="hstack"
      >
        <span class="font-bold">{{ item[0] }}</span>
        <span>{{ item[1] }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const url = location.href
const params = [...new URLSearchParams(location.search).entries()]
</script>

<style scoped></style>
