<template>
  <div></div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

const route = useRoute()

var form = document.createElement('form')
form.id = 'form'
form.name = 'form'
document.body.appendChild(form)

// 设置相应参数
for (var key in route.query) {
  var input = document.createElement('input')
  input.type = 'text'
  input.name = key
  input.value = route.query[key] as String
  // 将该输入框插入到 form 中
  form.appendChild(input)
}

// form 的提交方式
form.method = 'POST'
// form 提交路径
form.action = 'https://support.qq.com/product/' + route.params.productId
// 对该 form 执行提交
form.submit()
// 删除该 form
document.body.removeChild(form)
</script>

<style scoped></style>
