<template>
  <div class="h-full px-4 pb-4 flex flex-col bg-ld-background g-safe-area">
    <SafeAreaTopSpacer />

    <AppBar
      :title="_t('注销中')"
      class="px-0"
    >
      <template #back>
        <span></span>
      </template>
    </AppBar>

    <div class="g-body-width flex-1 flex flex-col">
      <RatioSpacedContainer class="flex-1">
        <Img
          name="ld-cry"
          class="w-85px"
        />
        <div class="text-21px font-semibold text-center px-4">
          <div>{{ _t('你已申请注销') }}</div>
          <div>
            <div>{{ _t('你的账户及所有相关数据将于') }}</div>
            <span class="text-ld-brand-500">{{ willTerminateAt }}</span>
            {{ _t('被删除') }}
          </div>
        </div>

        <div class="text-17px font-semibold text-ld-label-secondary mt-3">
          {{ _t('如需恢复账户、可点击下方「终止注销」') }}
        </div>
      </RatioSpacedContainer>

      <div class="mt-auto flex flex-col gap-2">
        <Button
          :label="_t('终止注销')"
          @click="onTerminationCancel"
        />
        <Button
          :label="_t('退出登录')"
          scene="secondary"
          @click="onLogout"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'
import { useRouter } from 'vue-router'
import { cancelTermination } from '@/api/user'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import dayjs from 'dayjs'

const store = useCommonStore()
const router = useRouter()

// 注销中的准确注销时间通过 query 传递
const route = useRoute()
const willTerminateAt = computed(() => {
  const time = route.query.willTerminateAt

  if (time == null) return ''
  return dayjs(time as string).format('YYYY-MM-DD')
})

function onTerminationCancel() {
  cancelTermination().then(() => {
    router.replace(_global.homePage)

    // 如果先进了首页或者其他页面，会导致因为 460 拿不到数据
    // 这里直接刷新一下 app 即可
    _refreshApp()

    // 因为刷新 app 后，primevue 会被重新挂载，pc 上的 message 信息就显示不了了
    // 所以这里需要用 setTimeout 等刷新完成后再提示
    setTimeout(() => {
      _message.info(_t('账号已恢复，继续学习吧！'))
    })
  })
}

function onLogout() {
  store.logout()

  router.replace({ name: 'landing' })
}

onInit(() => {
  // 如果有时间参数，则直接跳转到 landing
  if (willTerminateAt.value === '') {
    router.replace({ name: 'landing' })
  }
})
</script>
<style scoped></style>
