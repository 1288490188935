<template>
  <div class="flex flex-col justify-center items-center min-w-300px gap-4">
    <div class="w-full flex items-center relative">
      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-0 w-22px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div
      :class="[
        'w-full flex flex-col gap-4',
        {
          'opacity-0': config == null || energyLimitConfig == null,
        },
      ]"
    >
      <div class="flex flex-col items-center gap-2">
        <div class="text-19px font-semibold">
          {{ _t('已额外扩充面包上限') }}
        </div>

        <div class="text-32px leading-[34px] font-bold font-[DIN]">
          {{
            `${energyLimitConfig?.addedEnergyLimit ?? ''}/${
              energyLimitConfig?.maxEnergyLimitCapacity ?? ''
            }`
          }}
        </div>

        <div class="text-15px text-ld-label-secondary font-semibold">
          {{
            _t(
              `每有 1 位好友助力，面包上限 + ${energyLimitConfig?.diamondExchange.energyLimit}`
            )
          }}
        </div>
      </div>

      <div
        class="text-15px leading-[1.8] bg-gray-100 rounded-12px p-3 flex flex-col gap-3"
      >
        <div class="flex items-end">
          <div class="flex flex-col gap-2">
            <div>{{ _t('① 复制下方接头暗号给好友') }}</div>

            <div class="text-ld-brand-500 flex items-center">
              <span class="text-15px leading-none">{{ _t('接头暗号：') }}</span>
              <span
                class="font-[DIN] tracking-2px text-24px leading-[22px] font-bold"
              >
                {{ config?.code }}
              </span>
            </div>
          </div>

          <Button
            class="h-fit ml-auto"
            @click="onCopy"
          >
            <span class="text-17px leading-[1.4]">{{ _t('复制') }}</span>
          </Button>
        </div>

        <div>
          {{ _t('② 请好友在「奖励」页面双击鸭鸭， 填入你的接头暗号') }}

          <div class="text-ld-brand-500">
            {{ _t(`好友注册 ${newUserHours} 小时内有效，仅限一次`) }}
          </div>
        </div>

        <div>
          {{ _t('③ 暗号对接无误即可完成助力、获取奖励') }}

          <div class="text-ld-brand-500">
            {{
              _t(
                `你的面包上限+${config?.inviterEnergyLimit ?? ''}、好友获得 ${
                  config?.inviteeGetDiamond ?? ''
                } 钻石`
              )
            }}
          </div>
        </div>
      </div>

      <div class="mt-4 mx-3 text-15px leading-[1.8]">
        {{
          _t('你也可以使用钻石扩充面包上限，使用钻石与好友助力，共享扩充次数。')
        }}

        <span
          class="text-ld-brand-500 font-600 inline-flex items-center align-bottom cursor-pointer"
          @click="onEnergyLimitExchangeByDiamonds"
        >
          <Icon
            name="diamond"
            class="w-20px mr-2px"
          />
          {{ _t('钻石扩充') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, h } from 'vue'
import { fetchInvitationConfig, type RcodeInvitationConfig } from '@/api/rcode'
import { useCopy } from '@/hooks'
import {
  fetchEnergyLimitExchangeConfig,
  type EnergyLimitExchangeConfig,
} from '@/api/product'
import { exchangeEnergyLimit } from '@/api/order'
import { useCommonStore } from '@/stores'
import { Code } from '@/api/code'
import Icon from './Icon.vue'

const store = useCommonStore()

const emit = defineEmits<{
  done: []
}>()

const config = ref<RcodeInvitationConfig>()
const energyLimitConfig = ref<EnergyLimitExchangeConfig>()
const copy = useCopy()

const newUserHours = computed(() => {
  if (config.value == null) return 0

  return Math.floor(config.value.newUserDuration / 3600)
})

async function onCopy() {
  if (config.value == null) return

  copy(config.value.code).then(() => {
    _message.success(_t('接头暗号复制成功，快去邀请好友吧'))
  })
}

function onEnergyLimitExchangeByDiamonds() {
  if (energyLimitConfig.value == null) return

  const { diamond, energyLimit } = energyLimitConfig.value.diamondExchange

  _confirm({
    icon: {
      type: 'svg',
      name: 'energy',
    },
    scene: 'confirm',
    content: `面包上限+${energyLimit} ，需要 ${diamond} 钻石。确认使用钻石扩充吗？`,
    primaryText: _t('立即扩充'),
    primaryIcon: h(
      'span',
      {
        class: 'flex items-center gap-1 mr-4',
      },
      [
        h(Icon, {
          name: 'diamond',
          class: 'w-20px ',
        }),
        diamond,
      ]
    ),
    secondaryText: _t('暂不'),
    onPrimaryClick(resolve) {
      exchangeEnergyLimit()
        .then(res => {
          if (res.code !== Code.Ok) {
            _message.info(res.message)

            if (res.code === Code.NotEnoughDiamonds) {
              _openDiamondGetDialog()
            }

            resolve(false)
            return
          }

          _message.info(_t(`🍞 面包上限 + ${energyLimit}`))
          if (energyLimitConfig.value) {
            energyLimitConfig.value.addedEnergyLimit += energyLimit
          }

          _am.playAudio('energy_gain')

          store.setEnergyStatus(res.data)
          store.fetchUserAssets()
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    },
  })
}

onInit(() => {
  fetchInvitationConfig().then(res => (config.value = res))
  fetchEnergyLimitExchangeConfig().then(res => {
    energyLimitConfig.value = res
  })
})
</script>
<style scoped></style>
