<template>
  <Loading
    v-if="loading"
    class="h-full"
  />

  <div
    v-else
    class="flex flex-col px-4 items-center h-full g-safe-area bg-ld-background"
  >
    <SafeAreaTopSpacer />
    <div class="flex flex-col flex-1 mb-50px g-body-width gap-32px relative">
      <div
        v-if="store.isIOSReviewing"
        class="absolute h-44px flex items-center gap-12px px-4px"
        @click="onSkip"
      >
        <Icon
          name="close"
          class="w-20px"
        ></Icon>
        <div>
          {{ _t('跳过') }}
        </div>
      </div>

      <RatioSpacedContainer
        class="flex-1 w-full"
        :top="1"
        :bottom="1"
      >
        <div class="flex flex-col gap-64px w-full">
          <div class="flex flex-col items-center">
            <Img
              name="ld-greet"
              class="w-107px"
            />

            <div class="my-4 flex flex-col">
              <div
                ref="typewriterText"
                class="text-21px"
              ></div>
            </div>
          </div>
        </div>
      </RatioSpacedContainer>

      <div class="w-full flex flex-col gap-16px">
        <Button
          v-if="isWeChatInstalled"
          class="mt-auto w-full"
          label="Wechat"
          scene="wechat"
          @click="onWechatLogin"
        >
          <div class="flex justify-center items-center w-full">
            <Icon
              name="wechat-pure"
              class="h-28px"
            />
            <span class="ml-1 text-lg">{{ _t('微信登录') }}</span>
          </div>
        </Button>

        <Button
          v-else
          class="mt-auto w-full justify-center"
          :label="_t('马上开始')"
          @click="onLogin"
        ></Button>

        <DebugButton
          label="访客登录"
          @click="debugGuestLogin(router, route)"
        ></DebugButton>

        <AuthFooter
          v-if="isWeChatInstalled"
          v-model:isAgree="isAgree"
          :action="_t('登录')"
          :showAgree="isAndroid"
          :showBubble="showBubble"
          @update:is-agree="onIsAgree"
        />
      </div>

      <MoreLoginMethods
        v-if="showAppleLogin || isWeChatInstalled"
        :apple-login="showAppleLogin"
        :user-login="isWeChatInstalled"
        :we-chat-login="false"
        @apple-login="onAppleLogin"
        @user-login="onLogin"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Typewriter from 'typewriter-effect/dist/core'
import { appleLogin, guestLogin, type UserInfo } from '@/api/auth'

import { Platform, VibrateType } from '@/utils/bridge'
import MoreLoginMethods from '@/components/inapp/MoreLoginMethods.vue'
import AuthFooter from '@/components/AuthFooter.vue'
import { afterLogin, useLandingLogic } from '@/shared/landing'
import { debugGuestLogin } from '@/debug'

const store = useCommonStore()
const router = useRouter()
const route = useRoute()

const loading = useLandingLogic(router, route)
const isAgree = ref(false)
const isWeChatInstalled = ref<boolean>()
const isAndroid = computed(() => store.appInfo?.platform === Platform.Android)
const showAppleLogin = computed(() => store.isIOSReviewing)

const showBubble = ref(false)
onInit(() => {
  _bridge.isWeChatInstalled().then(res => {
    isWeChatInstalled.value = res
  })
})

function onCreateTextNode(character: string) {
  // 这个震动和「震动开关」无关，必然震动
  if (_global.isInsideApp) {
    _bridge.vibrate(VibrateType.Medium)
  }

  return document.createTextNode(character)
}

const typewriterText = ref()
watch(
  () => typewriterText.value,
  () => {
    if (typewriterText.value == null) return

    const typewriter = new Typewriter(typewriterText.value, {
      delay: 100,
      onCreateTextNode,
    })

    typewriter
      .typeString('闯关玩游戏，')
      .pauseFor(200)
      .typeString('学习零压力')
      .pauseFor(1000)
      .start()
  }
)

function onLogin() {
  router.replace({
    name: 'auth',
    query: {
      redirect: route.query.redirect || undefined,
    },
  })
}

async function onWechatLogin() {
  if (isAndroid.value && !isAgree.value) {
    showBubble.value = true
    return
  }

  const code = await _bridge.wechatAuth()
  if (!code) {
    return
  }

  // 跳转到 wxcallback 页面
  router.replace({
    name: 'wxcallback',
    query: { code, wechatType: 'APP' },
  })
}

async function onAppleLogin() {
  const identityToken = await _bridge.appleAuth()

  if (!identityToken) {
    return
  }

  const res = await appleLogin({ identityToken })

  if (res.code !== 0) {
    _message.info(res.message)
    return
  }

  if (res.data) {
    onAuthDone(res.data.token, res.data.user)
  } else {
    _message.info('登录失败')
  }
}

function onIsAgree(value: boolean) {
  if (value) {
    showBubble.value = false
  }
}
// 跳过登录使用游客模式
function onSkip() {
  _confirm({
    content: _t('即将以游客身份开始体验，请及时绑定账号，以免数据丢失哦'),
    primaryText: _t('开始体验'),
    secondaryText: _t('暂不'),
    scene: 'confirm',
    onPrimaryClick(resolve) {
      onGuestLogin()
      resolve(true)
    },
  })
}
// 游客登录
async function onGuestLogin() {
  const res = await guestLogin()
  if (res.code !== 0) {
    _message.info(res.message)
    return
  }

  if (res.data) {
    onAuthDone(res.data.token, res.data.user)
  } else {
    _message.info('登录失败')
  }
}

function onAuthDone(token: string, user: UserInfo) {
  store.login(token, user)
  afterLogin(router, route)
}
</script>
<style scoped></style>
