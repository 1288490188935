<template>
  <RatioSpacedContainer
    class="h-full overflow-auto"
    :top="4"
    :bottom="6"
  >
    <div class="w-full">
      <AuthForm
        class="px-4 mx-auto py-10"
        @done="onAuthDone"
      />

      <div class="mt-6 text-center">
        <span>{{ _t('没有账号? ') }}</span>
        <TextButton
          class="register-btn"
          :label="_t('去微信注册')"
          @click="onWxRegister"
        />
      </div>
    </div>
  </RatioSpacedContainer>
</template>

<script lang="ts" setup>
import type { UserInfo } from '@/api/auth'
import AuthForm from '@/components/AuthForm/AuthForm.vue'
import { useCopy } from '@/hooks'
import { afterLogin } from '@/shared/landing'
import { useCommonStore } from '@/stores'
import { openWechatApp } from '@/utils/wechat'
import { useRoute, useRouter } from 'vue-router'

// query:
//  - redirect?: redirect url

const store = useCommonStore()
const route = useRoute()
const router = useRouter()

const copy = useCopy()

async function onAuthDone(token: string, user: UserInfo) {
  store.login(token, user)
  afterLogin(router, route)
}

function onWxRegister() {
  _notice({
    scene: 'wechat',
    okText: _t('复制名称、去微信'),
    content: _t('目前仅支持微信注册\n请前往微信搜索：小灵鸭公众号'),
    onConfirm() {
      copy('小灵鸭公众号')

      openWechatApp()
    },
  })
}
</script>

<style scoped></style>
