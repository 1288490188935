<template>
  <div class="g-safe-area h-full bg-ld-background">
    <SafeAreaTopSpacer />
    <TerminateApply @back="onBack" />
  </div>
</template>
<script setup lang="ts">
import TerminateApply from '@/pages/TerminateApply.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.push({ name: 'mine' })
  }
}
</script>
<style scoped></style>
