<template>
  <div class="flex flex-col">
    填空题（单空）
    <JsonEditor
      v-model="singleClozeCardConfigJson"
      class="w-full max-h-240px flex flex-col"
      mode="text"
      :mainMenuBar="false"
    />

    填空题（多空）
    <JsonEditor
      v-model="multiClozeCardConfigJson"
      class="w-full max-h-240px flex flex-col"
      mode="text"
      :mainMenuBar="false"
    />

    英语单词
    <JsonEditor
      v-model="enWordCardConfigJson"
      class="w-full max-h-240px flex flex-col"
      mode="text"
      :mainMenuBar="false"
    />

    <div class="mt-4 ml-auto">
      <Button
        class="ml-2"
        :label="_t('提交配置')"
        @click="onSubmit"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import db from '@/db'
import JsonEditor from 'json-editor-vue'
import Ajv, { type ValidateFunction } from 'ajv'
import { ref } from 'vue'
import { ClozeCardFaceType, EnWordCardFaceType } from '@/types/core'
import {
  EnWordCardDifficulty,
  MultiClozeCardDifficulty,
  SingleClozeCardDifficulty,
} from '@/pages/UnitLearn/InsertQueue/queue'
import { warn } from '@/utils'

const emit = defineEmits<{
  done: []
}>()

const ajv = new Ajv()

const singleClozeCardConfigJson = ref(
  JSON.stringify(
    db.debug.insertQueueConfig.singleClozeCardDifficulty ??
      SingleClozeCardDifficulty,
    null,
    2
  )
)
const multiClozeCardConfigJson = ref(
  JSON.stringify(
    db.debug.insertQueueConfig.multiClozeCardDifficulty ??
      MultiClozeCardDifficulty,
    null,
    2
  )
)
const enWordCardConfigJson = ref(
  JSON.stringify(
    db.debug.insertQueueConfig.enWordCardDifficulty ?? EnWordCardDifficulty,
    null,
    2
  )
)

const clozeCardDifficultySchema = {
  type: 'array',
  items: {
    type: 'string',
    enum: Object.values(ClozeCardFaceType),
  },
}

const enWordCardDifficultySchema = {
  type: 'array',
  items: {
    type: 'string',
    enum: Object.values(EnWordCardFaceType),
  },
}

const clozeCardValidate = ajv.compile({
  type: 'object',
  properties: {
    1: clozeCardDifficultySchema,
    2: clozeCardDifficultySchema,
    3: clozeCardDifficultySchema,
  },
  required: ['1', '2', '3'],
  additionalProperties: false,
})

const enWordCardValidate = ajv.compile({
  type: 'object',
  properties: {
    1: enWordCardDifficultySchema,
    2: enWordCardDifficultySchema,
    3: enWordCardDifficultySchema,
  },
  required: ['1', '2', '3'],
  additionalProperties: false,
})

function validate(validator: ValidateFunction, config: object) {
  if (!validator(config)) {
    const msgs = validator.errors!.map(e => {
      if (e.instancePath) {
        return (
          e.instancePath.slice(1) +
          ': ' +
          e.message +
          `\n${JSON.stringify(e.params)}`
        )
      }

      return e.message ?? '' + `\n${JSON.stringify(e.params)}`
    })

    _message.error(msgs.join('\n'))
    return false
  }

  return true
}

function onSubmit() {
  try {
    const singleClozeCardConfig = JSON.parse(singleClozeCardConfigJson.value)
    const multiClozeCardConfig = JSON.parse(multiClozeCardConfigJson.value)
    const enWordCardConfig = JSON.parse(enWordCardConfigJson.value)
    if (
      validate(clozeCardValidate, singleClozeCardConfig) &&
      validate(clozeCardValidate, multiClozeCardConfig) &&
      validate(enWordCardValidate, enWordCardConfig)
    ) {
      db.debug.insertQueueConfig.singleClozeCardDifficulty =
        singleClozeCardConfig
      db.debug.insertQueueConfig.multiClozeCardDifficulty = multiClozeCardConfig
      db.debug.insertQueueConfig.enWordCardDifficulty = enWordCardConfig
      _message.success(_t('队列配置已更新'))
      emit('done')
    }
  } catch (_) {
    warn(_)
    _message.error('请输入合法的 json')
  }
}
</script>
<style scoped>
:deep(.jse-main) {
  flex: 1;
}
</style>
