<template>
  <div class="max-w-md">
    <div ref="mainRef">
      <label class="block text-900 text-sm font-medium">当前邮箱</label>

      <InputText
        :model-value="props.email"
        :pt="{ root: { 'data-focus': 'true' } }"
        readonly
        disabled
        class="mt-2 w-full"
      />
    </div>

    <div class="mt-4">
      <CodeField
        v-model="code"
        :email="email"
        scene="RESET_PWD"
      />
    </div>

    <div class="mt-4">
      <label
        data-required
        class="block text-900 text-sm font-medium"
      >
        {{ _t('密码') }}
      </label>

      <Password
        v-model="password"
        :feedback="false"
        class="mt-2 w-full"
        inputClass="w-full"
        toggleMask
        @keydown.enter="onSubmit"
      />
    </div>

    <Button
      class="w-full mt-4"
      label="修改密码"
      :disabled="!isValid"
      :loading="loading"
      @click="onSubmit"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import CodeField from '@/components/AuthCodeField.vue'
import { resetPwd } from '@/api/auth'

const props = defineProps<{
  email: string
}>()

// 绑定成功时，组件会直接修改 store 信息
const emit = defineEmits<{
  done: [string]
}>()

const code = ref('')
const password = ref('')

const isValid = computed(() => {
  return code.value !== '' && password.value !== ''
})

const loading = ref(false)

function onSubmit() {
  if (!isValid.value) return

  loading.value = true

  resetPwd({
    email: props.email,
    code: code.value,
    password: password.value,
  })
    .then(res => {
      if (res.code === 0) {
        emit('done', password.value)
      } else {
        _message.info(res.message)
      }
    })
    .finally(() => {
      loading.value = false
    })
}

// auto focus
const mainRef = ref()
onMounted(() => {
  setTimeout(() => {
    mainRef.value.querySelector('input[data-focus]').focus()
  }, 0)
})
</script>

<style scoped></style>
