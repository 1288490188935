<template>
  <div class="relative w-36px h-36px">
    <Icon
      name="energy"
      class="w-36px"
    />
    <div class="energy-count absolute absolute-center">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.energy-count {
  flex-wrap: nowrap;
  font-size: 15px;
  color: var(--yellow-900);
  font-weight: 700;
  font-family: DIN;
  line-height: 20px;
  margin-left: -3px;
}
</style>
