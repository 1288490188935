<template>
  <div
    v-if="parsed"
    :class="[
      'flex items-start gap-2',
      {
        'flex-col': !_global.isPcMode,
      },
    ]"
  >
    <TextClamp class="w-full">
      <Content :content="parsed.card.content">
        <template #text="{ node }">
          <Text
            class="content"
            :node="node"
            :show-dot-style="false"
          ></Text>
        </template>

        <template #cloze="{ node }">
          <span class="font-bold g-cloze">
            {{ node.text }}
          </span>
        </template>
      </Content>
    </TextClamp>

    <Image
      v-if="card.illustration"
      preview
      :class="[
        'illustration shrink-0',
        {
          'ml-auto': _global.isPcMode,
        },
      ]"
    >
      <template #image>
        <img
          :src="_global.assetUrl(card.illustration)"
          class="h-78px w-78px rounded-4px object-cover"
          @click.stop
        />
      </template>
      <template #preview="slotProps">
        <img
          :src="_global.assetUrl(card.illustration)"
          class="object-cover"
          :style="slotProps.style"
          @click="slotProps.onClick"
        />
      </template>
    </Image>
  </div>
  <div v-else>
    {{ _t('卡片解析失败') }}
  </div>
</template>
<script setup lang="ts">
import { parseClozeCard } from '@/utils/card'
import Text from '../card-render/Text.vue'
import Content from '../card-render/Content.vue'
import Image from 'primevue/image'

import type { ClozeCard } from '@/types/core'
import TextClamp from '@/components/TextClamp.vue'

const props = defineProps<{
  card: ClozeCard
}>()

const parsed = parseClozeCard(props.card)
</script>
<style scoped>
.illustration :deep(.p-image-preview-indicator) {
  opacity: 0;
}
</style>
