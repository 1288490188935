import { verifyApplePay } from '@/api/order'
import { Bus } from '@/bus/bus'
import type { PresetClozeGroup } from '@/components/ClozeEditor/extensions/cloze'
import { getDailyMonsterId, getHomeWidgetFallbackText } from '..'
import { checkInStats } from '@/api/user'
import dayjs from 'dayjs'
import { last } from 'lodash-es'

export type AppBridge = typeof bridge

export enum Platform {
  Android = 'android',
  IOS = 'ios',
}
export type AppInfo = {
  platform: Platform
  isReviewing: boolean
  fullVersion: string
  channel: string
  updateConfig?: {
    force: boolean
    title: string
    content: string
    link: string
    latestVersion: string
    forManualCheck: boolean
  }
  insets?: {
    top: number
    bottom: number
    left: number
    right: number
  }
}
type AppImageFile = {
  path: string
  name: string
  bytes: number[]
}

export type IAPItem = {
  productId?: string
  localizedPrice?: string
  title?: string
}
export type LocalNotification = {
  title: string
  content: string
  id: number
  fireTime: number
  extra?: Record<string, string>
}

type EditorState = {
  selectedClozeGroup: PresetClozeGroup
}

const EDITOR_TOGGLE_CLOZE = 'EditorToggleCloze'
const EDITOR_CLEAR_CLOZE = 'EditorClearCloze'
const EDITOR_BOTTOM_INSETS = 'EditorBottomInsets'

const channel = window.flutter_inappwebview

// TODO(buding): bridge 兼容问题，如果旧版 app 没有相应的 bridge 方法，调用时会直接返回一个立即结束的 promise
// promise 返回值为 null

const bridge = {
  //
  // 补一个 fetchV2
  //

  // 获取应用信息
  getAppInfo(): Promise<AppInfo> {
    return flutter_inappwebview.callHandler('getAppInfo')
  },

  // 微信是否安装
  isWeChatInstalled(): Promise<boolean> {
    return channel.callHandler('isWeChatInstalled')
  },

  // 支付宝是否安装
  isAlipayInstalled(): Promise<boolean> {
    return channel.callHandler('isAliPayInstalled')
  },

  // apple 授权
  appleAuth(): Promise<string> {
    return channel.callHandler('appleAuth')
  },

  // 微信授权，返回 code, 空表示用户取消
  wechatAuth(): Promise<string> {
    return channel.callHandler('wechatAuth')
  },

  // 微信支付
  wechatPay(params: {
    appId: string
    partnerId: string
    prepayId: string
    packageValue: string
    nonceStr: string
    timestamp: number
    sign: string
    signType?: string
    extData?: string
  }): Promise<unknown> {
    return channel.callHandler('wechatPay', params)
  },

  // 支付宝支付 使用前需要调用 isAlipayInstalled 判断是否安装支付宝
  // resultStatus 9000 表示支付成功 memo 是描述信息
  // 详情内容查看支付宝文档 https://opendocs.alipay.com/open/204/105302
  aliPay(params: { order: string }): Promise<{
    resultStatus: string
    memo: string
  }> {
    return channel.callHandler('aliPay', params)
  },

  // 请求相册权限
  requestPhotosPermission(): Promise<boolean> {
    return flutter_inappwebview.callHandler('requestPhotosPermission')
  },
  // 请求相机权限
  requestCameraPermission(): Promise<boolean> {
    return flutter_inappwebview.callHandler('requestCameraPermission')
  },

  // 打开 app 设置页面
  openAppSettings(): Promise<unknown> {
    return flutter_inappwebview.callHandler('openAppSettings')
  },

  // 选择图片 camera: true 拍照，false 相册 （不包含权限判断）
  async pickImage(params: { camera: boolean }): Promise<File | null> {
    const res: AppImageFile | null = await channel.callHandler(
      'pickImage',
      params
    )
    if (res == null) return null

    const uint8ArrayData = new Uint8Array(res.bytes)
    const ext = res.name.split('.').pop()?.toLowerCase()
    let type: string | undefined
    switch (ext) {
      case 'jpg':
      case 'jpeg':
        type = 'image/jpeg'
        break
      case 'png':
        type = 'image/png'
        break
      case 'gif':
        type = 'image/gif'
        break
      case 'svg':
        type = 'image/svg+xml'
        break
      case 'bmp':
        type = 'image/bmp'
        break
      case 'webp':
        type = 'image/webp'
        break
    }

    const blob = new Blob([uint8ArrayData], { type: type })
    return new File([blob], res.name, { type: blob.type })
  },

  // 购买 IAP 商品
  async iapBuyProduct(
    productId: string,
    orderId: string
  ): Promise<{
    ok: boolean
    msg: string
  }> {
    return channel.callHandler('iapBuyProduct', productId, orderId)
  },

  // 弹出 app debug 页面
  async showDebugPage(): Promise<void> {
    return channel.callHandler('showDebugPage')
  },

  // Web 已经加载完毕
  async webReady(): Promise<void> {
    return channel.callHandler('webReady')
  },

  // launch
  async launch(url: string): Promise<void> {
    return channel.callHandler('launch', url)
  },

  async fetch<T>(
    url: string,
    options: {
      responseType: 'json' | 'bytes'
      headers: Record<string, string>
    }
  ): Promise<T> {
    return channel.callHandler('fetch', url, options)
  },

  // 检查更新 true 有更新 false 无更新
  async checkUpdate(): Promise<boolean> {
    return channel.callHandler('checkUpdate')
  },

  // 刷新 webview 页面
  async reloadWebview() {
    return channel.callHandler('reloadWebview')
  },

  // 禁用返回手势
  async disableBackGesture() {
    return channel.callHandler('disableBackGesture')
  },

  // 启用返回手势
  async enableBackGesture() {
    return channel.callHandler('enableBackGesture')
  },

  // 震动 默认 light
  async vibrate(type: VibrateType): Promise<void> {
    return channel.callHandler('vibrate', type)
  },

  // 设置 webview init path
  async setInitPath(path: string): Promise<void> {
    return channel.callHandler('setInitPath', path)
  },

  //
  // Notification
  //

  // 检查通知权限状态: "enabled" | "disabled" | "unset"
  async checkNotificationStatus(): Promise<'enabled' | 'disabled' | 'unset'> {
    return channel.callHandler('checkNotificationStatus')
  },
  // 批量发起通知
  // fireTime: in milliseconds
  async sendLocalNotifications(notifications: LocalNotification[]) {
    return channel.callHandler('sendLocalNotifications', notifications)
  },
  async clearLocalNotifications(ids: number[]): Promise<void> {
    return channel.callHandler('clearLocalNotifications', ids)
  },
  async clearAllLocalNotifications(): Promise<void> {
    return channel.callHandler('clearAllLocalNotifications')
  },
  async tryToEnableNotification(): Promise<void> {
    return channel.callHandler('tryToEnableNotification')
  },
  // 检查 bridge 是否有某个方法
  async has(funcName: string): Promise<boolean> {
    const res = await channel.callHandler('has', funcName)
    // 旧版没有这个方法 会返回 null。所以需要 === true
    return res === true
  },

  // app 主动调用的 web 方法
  callback: {
    // 通知 web 苹果支付成功
    async verifyApplePay(orderId: string, transactionId: string) {
      const res = await verifyApplePay(orderId, transactionId)
      return {
        code: res.code,
        message: res.message,
      }
    },
    async onOpenNotification(event: { extras: Record<string, string> }) {
      // TODO: 打开通知
    },
    // 获取签到数据
    async getCheckInData(): Promise<{
      detail?: { continuousDays: number; isCheckedInToday: boolean }
      fallbackText: string
      monsterId: string
    }> {
      const monsterId = getDailyMonsterId()
      // 未登录 显示『当天没学习』的未签到文案
      if (_store.user == null) {
        return {
          fallbackText: getHomeWidgetFallbackText(1),
          monsterId: monsterId,
        }
      }
      // 更新签到统计数据, 下次触发的时候就是最新的数据
      _store.fetchCheckInCtRes()
      if (_store.checkInCtRes == null) {
        return {
          fallbackText: getHomeWidgetFallbackText(1),
          monsterId: monsterId,
        }
      }
      const checkInCtRes = _store.checkInCtRes
      return {
        detail: {
          continuousDays: checkInCtRes.continuousDays,
          isCheckedInToday: checkInCtRes.todayCheckIn,
        },
        fallbackText: getHomeWidgetFallbackText(checkInCtRes.lastUncheckInDays),
        monsterId: monsterId,
      }
    },
  },

  editor: {
    bus: new Bus(),

    focus() {
      return channel.callHandler('editor.focus')
    },

    blur() {
      return channel.callHandler('editor.blur')
    },

    syncState(state: EditorState): void {
      return channel.callHandler('editor.syncState', state)
    },

    onEditorToggleCloze(callback: (group: PresetClozeGroup) => void) {
      _bridge.editor.bus.on(EDITOR_TOGGLE_CLOZE as any, callback)
    },
    offEditorToggleCloze(callback: (group: PresetClozeGroup) => void) {
      _bridge.editor.bus.off(EDITOR_TOGGLE_CLOZE as any, callback)
    },

    onEditorClearCloze(callback: () => void) {
      _bridge.editor.bus.on(EDITOR_CLEAR_CLOZE as any, callback)
    },
    offEditorClearCloze(callback: () => void) {
      _bridge.editor.bus.off(EDITOR_CLEAR_CLOZE as any, callback)
    },

    onSetBottomInsets(callback: (insets: number) => void) {
      _bridge.editor.bus.on(EDITOR_BOTTOM_INSETS as any, callback)
    },
    offSetBottomInsets(callback: (insets: number) => void) {
      _bridge.editor.bus.off(EDITOR_BOTTOM_INSETS as any, callback)
    },

    callback: {
      setBottomInsets(insets: number): void {
        _bridge.editor.bus.emit(EDITOR_BOTTOM_INSETS as any, insets)
      },
      toggleCloze(clozeGroup: PresetClozeGroup): void {
        _bridge.editor.bus.emit(EDITOR_TOGGLE_CLOZE as any, clozeGroup)
      },
      clearCloze(): void {
        _bridge.editor.bus.emit(EDITOR_CLEAR_CLOZE as any)
      },
    },
  },
}

export enum VibrateType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Heavy = 'heavy',
  Medium = 'medium',
  Light = 'light',
  Soft = 'soft',
  Rigid = 'rigid',
}

export default bridge
