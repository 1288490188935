<template>
  <div class="h-full flex flex-col flex-1">
    <Choice
      v-if="
        finalFace.type === MCQCardFaceType.Choice ||
        finalFace.type === MCQCardFaceType.MinimalChoice
      "
      :face="finalFace"
    />
  </div>
</template>

<script lang="ts" setup>
import Choice from './Choice.vue'
import { processMCQFace } from '@/utils/card'

import { MCQCardFaceType } from '@/types/core'
import type { MCQCardFace } from '@/types/core'

const props = defineProps<{
  face: MCQCardFace
}>()

const finalFace = processMCQFace(props.face)
</script>

<style scoped></style>
