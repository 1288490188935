<template>
  <div>
    <div class="title">{{ model.title }}</div>

    <div class="flex flex-col gap-2">
      <div
        v-for="(item, k) in model.content"
        :key="k"
        class="p"
      >
        <span
          v-for="(i, index) in item.content"
          :key="index"
          :class="getInlineNodeClass(i)"
          @click="onInlineNodeClick(i)"
        >
          {{ i.text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  ExternalLinkMark,
  InlineNode,
  InternalLinkLinkMark,
  MsgModel,
} from '@/types/message'
import { openStore } from '@/shared'
import { useRouter } from 'vue-router'
import { openLink } from '@/utils'

const router = useRouter()

const props = defineProps<{
  content: string
}>()

// 解析content
const parseContent = (content: string) => {
  const json = JSON.parse(content)
  return json as MsgModel
}
const model = parseContent(props.content)

function getInlineNodeClass(node: InlineNode) {
  const classList: string[] = []
  const marks = node.marks ?? []

  for (const mark of marks) {
    if (mark.type === 'highlight') {
      classList.push('highlight')
    } else if (['internalLink', 'externalLink'].includes(mark.type)) {
      classList.push('link')
    }
  }
  return classList.join(' ')
}

function onInlineNodeClick(i: InlineNode) {
  if (i.marks == null) {
    return
  }

  const internalLinkMark = i.marks?.find(
    item => item.type === 'internalLink'
  ) as InternalLinkLinkMark

  if (internalLinkMark) {
    switch (internalLinkMark.attrs.scene) {
      case 'store':
        openStore()
        break
      case 'shelf':
        router.push({ name: 'shelf' })
        break
    }
    return
  }

  const externalLinkMark = i.marks?.find(
    item => item.type === 'externalLink'
  ) as ExternalLinkMark

  if (externalLinkMark) {
    let href = externalLinkMark.attrs.href

    if (!href.startsWith('http')) {
      href = 'https://' + href
    }
    openLink(href)
  }
}
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  font-weight: 600;
  white-space: pre-line;
}

.p {
  font-size: 16px;
  font-weight: 400;
  white-space: pre-line;
  word-wrap: break-word;
}

.highlight {
  font-weight: 700;
}

.link {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}
</style>
