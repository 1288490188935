<template>
  <div class="flex flex-col w-full gap-18px items-center justify-center">
    <div class="flex w-full items-center">
      <Divider class="flex-1" />
      <div class="px-2 text-12px text-ld-label-secondary">
        {{ _t('其他登录方式') }}
      </div>
      <Divider class="flex-1" />
    </div>

    <div class="flex justify-center gap-44px">
      <div
        v-if="userLogin"
        class="rounded-full overflow-hidden bg-gray-200 w-44px h-44px flex justify-center items-center"
        @click="onUserLogin"
      >
        <Icon
          name="user"
          class="w-24px text-gray-600"
        />
      </div>

      <div
        v-if="weChatLogin"
        class="rounded-full overflow-hidden bg-gray-200 w-44px h-44px flex justify-center items-center"
        @click="onWechatLogin"
      >
        <Icon
          name="wechat-icon"
          class="w-28px text-[var(--ld-wechat)]"
        />
      </div>

      <div
        v-if="appleLogin"
        class="rounded-full overflow-hidden bg-gray-200 w-44px h-44px flex justify-center items-center"
        @click="onAppleLogin"
      >
        <Icon
          name="apple"
          class="w-24px text-gray-600"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  // apple 登录
  appleLogin?: boolean
  // 微信登录
  weChatLogin?: boolean
  // 账号登录
  userLogin?: boolean
}>()

function onAppleLogin() {
  emit('appleLogin')
}
function onWechatLogin() {
  emit('wechatLogin')
}
function onUserLogin() {
  emit('userLogin')
}
const emit = defineEmits<{
  appleLogin: []
  wechatLogin: []
  userLogin: []
}>()
</script>

<style scoped></style>
