<template>
  <CardTypeSelect
    v-if="!cardTypeConfirmed || cardType == null"
    :type="cardType"
    @done="emit('done')"
    @confirmed="onCardTypeConfirmed"
  />

  <PkgForm
    v-else
    showBack
    :cardType="cardType"
    @back="onPkgCreateBack"
    @done="emit('done')"
    @create="emit('create', $event)"
  />
</template>
<script lang="ts" setup>
import CardTypeSelect from './CardTypeSelect.vue'
import PkgForm from '../PkgList/PkgForm.vue'
import { CardType } from '@/types/core'
import { ref } from 'vue'
import type { PackageBasic } from '@/api/package-source'

const emit = defineEmits<{
  done: []
  create: [PackageBasic]
}>()

const cardType = ref<CardType>()
const cardTypeConfirmed = ref(false)

function onCardTypeConfirmed(type: CardType) {
  cardType.value = type
  cardTypeConfirmed.value = true
}

function onPkgCreateBack() {
  cardTypeConfirmed.value = false
}
</script>
<style scoped></style>
