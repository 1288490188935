import { ref } from 'vue'

// 用于缓存 group 所需数据
// cardId + index 才能定位到是哪个 group
// eg: 1001:0, 表示 id 为 1001 的卡片中的第一个 group
export type GroupKey = string

export const aiLoading = ref(new Map<GroupKey, boolean>())
export const aiAbortControllers = ref(new Map<GroupKey, AbortController>())
export const distractorInputCache = ref(new Map<GroupKey, string>())
