<template>
  <SafeAreaTopSpacer v-if="showHeader" />
  <div
    v-if="showHeader"
    class="flex items-center mt-4 px-4 relative g-header-width gap-3"
  >
    <Icon
      name="close"
      class="cursor-pointer w-20px shrink-0"
      @click="emit('close')"
    ></Icon>

    <ProgressBar :percent="data.practiceProgress" />

    <LastCardButton
      :showGuide="data.showLastCardGuide"
      :disabled="lastCardDisabled"
      @click="onLastCardShow"
    />

    <transition
      name="monster-hint"
      mode="out-in"
    >
      <span
        v-if="data.hint"
        class="monster-hint"
        :style="hintStyle"
      >
        {{ data.hint }}
      </span>
    </transition>
  </div>

  <template v-if="data.activeFrame != null">
    <transition
      name="card-slide"
      mode="out-in"
      @afterEnter="onCardEnter"
    >
      <BossStartFrame
        v-if="data.activeFrame.type === FrameType.BossStart"
        :name="data.activeFrame.name"
        @next="onEmptyFrameTick"
        @close="emit('close')"
      />

      <ConcreteCardFrame
        v-else-if="data.activeFrame.type === FrameType.Practice"
        :key="data.cardLearnRound"
        :face="data.activeFrame.face"
        class="flex-1"
        @event="onEventCollect"
        @star="onStar"
        @next="onNext"
        @close="emit('close')"
      ></ConcreteCardFrame>

      <BossCardFrame
        v-else-if="data.activeFrame.type === FrameType.Boss"
        :face="data.activeFrame.boss"
        :combo="combo"
        class="flex-1 min-h-0"
        @event="onEventCollect"
        @star="onStar"
        @next="onNext"
        @done="onBossDone"
        @card-enter="onCardEnter"
        @close="emit('close')"
      >
        <template #actions>
          <LastCardButton
            :showGuide="data.showLastCardGuide"
            :disabled="lastCardDisabled"
            @click="onLastCardShow"
          />
        </template>
      </BossCardFrame>
    </transition>
  </template>
</template>

<script lang="ts" setup>
import { UnitEventType, QueueType, type Card } from '@/types/core'
import { inject, reactive, computed } from 'vue'
import ConcreteCardFrame from '@/components/ConcreteCard/ConcreteCard.vue'
import BossCardFrame from '@/components/VirtualCard/Boss.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import BossStartFrame from '../BossStartFrame.vue'
import { useCommonStore } from '@/stores'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'
import { InsertQueue, FrameType, type Frame } from './queue'
import { completeReport } from '../report'
import CardDetailPopup from '@/components/CardDetailPopup/CardDetailPopup.vue'
import LastCardButton from './LastCardButton.vue'

import db from '@/db'
import { vibrate, VibrateType } from '@/utils'

const onAudioPlay = inject<(type: 'correct' | 'incorrect') => void>(
  'onAudioPlay',
  () => {}
)

const emit = defineEmits<{
  close: []
}>()

const store = useCommonStore()

const data = reactive({
  cardLearnRound: 0,
  activeFrame: null as Frame | null,
  lastCard: null as Card | null,
  practiceProgress: 0,
  hint: '',
  // 在答题完成后会先计算是否需要展示下一张卡片的提示，存储在该字段
  preShowLastCardGuide: false,
  // 当卡片动画结束跳转到下一张卡片时再具体显示对应的提示
  showLastCardGuide: false,
})

const showHeader = computed(() => {
  return data.activeFrame?.type === FrameType.Practice
})

const lastCardDisabled = computed(() => data.lastCard == null)

let queue: InsertQueue
function loadUnit() {
  if (!store.checkStageUnit()) {
    _global.unreachable(store.setStageUnit)
  }

  const stageUnit = store.stageUnit!

  queue = new InsertQueue(
    stageUnit.schedules,
    _global.isProd ? undefined : db.debug.insertQueueConfig
  )
  const progressCache = db.unitProgressCacheV2[QueueType.Insert]

  if (progressCache) {
    queue.setState(progressCache)
  }

  data.activeFrame = queue.currentFrame()
  data.lastCard = queue.getLastCard()
  data.practiceProgress = queue.practiceProgress

  if (queue.isDone) {
    onDone()
  }
}

const hintStyle = computed(() => {
  const left = Math.min(Math.max(data.practiceProgress - 10, 15), 85)

  return `left: ${left}%;`
})

const combo = computed(() => store.stageUnit?.currentComboCount ?? 0)

onInit(loadUnit)

function showHint() {
  if (combo.value > 1) {
    data.hint = `Combo x ${combo.value}`
  } else {
    data.hint = `Hit + 1`
  }

  setTimeout(() => {
    data.hint = ''
  }, 500)
}

function onEventCollect(cardId: number | undefined, event: UnitEventType) {
  // 答对答错时震动
  if (event === UnitEventType.CORRECT) {
    vibrate(VibrateType.Success)
  }
  if (event === UnitEventType.WRONG) {
    vibrate(VibrateType.Warning)
  }
  if (!data.activeFrame || cardId == null) return

  store.insertLearningEvent(cardId, {
    event,
    timestamp: Date.now(),
  })
}

function onNext() {
  if (queue.isDone) {
    onDone()
    return
  }

  // 由于从 bossstar 进入 boss 时，lastcardbutton 组件发生了变化
  // 如果此时 showGuide 为 true 的话，直接显示会导致 tooltip 报错
  // 所以先设置为 false，等卡片显示时会走到 onCardEnter 逻辑重新显示提示
  if (data.activeFrame?.type === FrameType.BossStart) {
    data.showLastCardGuide = false
  }

  data.activeFrame = queue.currentFrame()
  data.lastCard = queue.getLastCard()
  data.cardLearnRound++

  const state = queue.getState()
  db.unitProgressCacheV2[QueueType.Insert] = state

  // 如果 boss 被打败，关闭上一张卡片的提示
  if (
    data.activeFrame?.type === FrameType.Boss &&
    data.activeFrame.boss.hp === 0
  ) {
    data.preShowLastCardGuide = false
    data.showLastCardGuide = false
  }
}

function onEmptyFrameTick() {
  queue.tick()
  onNext()
}

function onBossDone() {
  queue.tick()
  onNext()
}

function onStar(star: FeedbackStar) {
  onAudioPlay('correct')

  queue.tick(star)
  data.practiceProgress = queue.practiceProgress

  if (star === FeedbackStar.Three) {
    const newCombo = combo.value + 1
    store.setComboCount(newCombo)
    showHint()
  } else {
    // 答错时尝试展示上一张卡片的引导
    tryShowLastCardGuide()
    store.setComboCount(0)
  }
}

function onDone() {
  const cardIds = (store.stageUnit?.schedules ?? []).map(item => item.cardId)

  for (const id of cardIds) {
    store.completeCard(id)
  }
  completeReport()

  delete db.unitProgressCacheV2[QueueType.Insert]
}

function onLastCardShow() {
  if (data.lastCard == null) return

  if (data.showLastCardGuide) {
    data.preShowLastCardGuide = false
    data.showLastCardGuide = false
    store.completeLastCardGuide()
  }

  return _openDialog(CardDetailPopup, {
    rootClass: _global.isPcMode
      ? 'w-600px min-h-400px max-h-650px'
      : 'g-dialog',
    props: {
      card: data.lastCard,
      isFirstLearn: false,
      primaryButtonText: _t('继续答题'),
      closeable: true,
      title: _t('回看上一张'),
    },
    dialog: {
      showHeader: false,
      contentStyle: 'padding: 0px;',
      // TODO: tailwind css 生成不了使用 calc 的高度，这里先用 style
      style: !_global.isPcMode
        ? 'max-height: calc(var(--ld-viewport-height) - 100px);'
        : '',
    },
  })
}

function onCardEnter() {
  data.showLastCardGuide = data.preShowLastCardGuide
}

// 尝试显示上一张卡片的引导提示
function tryShowLastCardGuide() {
  if (store.isLastCardGuideCompleted) {
    return
  }

  // 如果上一张卡片答错过 & 不是第一次学习，则展示上一张卡片的引导提示
  if (data.activeFrame?.type === FrameType.Practice) {
    if (!data.activeFrame.face.isFirstLearn) {
      data.preShowLastCardGuide = true
    }
  } else if (data.activeFrame?.type === FrameType.Boss) {
    if (!data.activeFrame.boss.cardFace?.isFirstLearn) {
      data.preShowLastCardGuide = true
    }
  }
}
</script>

<style scoped>
.card-slide-enter-active,
.card-slide-leave-active {
  transition: transform 300ms;
}
.card-slide-enter-from {
  transform: translateX(100%);
}
.card-slide-enter-to {
  transform: translateX(0%);
}
.card-slide-leave-from {
  transform: translateX(0%);
}
.card-slide-leave-to {
  transform: translateX(-100%);
}

.monster-hint-enter-active,
.monster-hint-leave-active {
  transition: opacity 500ms;
}

.monster-hint-enter {
  opacity: 1; /* 初始位置 */
}
.monster-hint-leave-to {
  opacity: 0; /* 最终位置 */
}

.monster-hint {
  position: absolute;
  top: 20px;
  color: var(--red-400);
  font-family: Comic Sans MS;
  font-size: 15px;
  font-weight: 400;
  transform: rotate(-5.89deg);
  z-index: 1;
  white-space: nowrap;
}
</style>
