<template>
  <Loading
    v-if="pageLoading"
    class="h-full"
  ></Loading>

  <RatioSpacedContainer
    v-else-if="endLoading"
    class="items-center text-22px leading-30px g-body-width h-full"
  >
    <Loading
      class="mb-8"
      :full="false"
    ></Loading>
    <div>{{ _t('正在收拾行囊') }}</div>
    <div>{{ _t('准备进入灵鸭世界') }}</div>
  </RatioSpacedContainer>

  <div
    v-else-if="identityTagRoot != null"
    class="h-full flex flex-col bg-ld-background"
  >
    <AppBar @back="onBack" />

    <div class="flex flex-col flex-1 h-0 p-8 pt-6 g-body-width">
      <div class="flex greet items-center">
        <Img name="ld-greet" />
        <div
          ref="chatBox"
          class="chat"
          @animationend="onChatBoxAnimationEnd"
        ></div>
      </div>

      <div class="overflow-y-auto flex-1 basis-0 mt-44px">
        <div
          class="flex flex-col gap-4 relative transition-left duration-300"
          :style="{
            left: showTags ? '0' : '100%',
          }"
        >
          <Button
            v-for="e in identityTagRoot.children"
            :key="e.key"
            :scene="
              selectedFirstTag?.key === e.key ? 'choiceSelected' : 'choice'
            "
            @click="selectedFirstTagKey = e.key"
          >
            <div class="py-1">{{ e.name }}</div>
          </Button>

          <Textarea
            v-if="isOtherTag"
            ref="otherTagInput"
            v-model="otherTagReason"
            class="w-full mb-2 max-h-304px"
            rows="4"
            autoResize
            placeholder="请输入"
          />
        </div>
      </div>

      <Button
        class="mt-4 w-full"
        label="继续"
        :disabled="!selectedFirstTagKey"
        @click="onEnd"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'
import { fetchIdentityTags } from '@/api/package-source'

import { tree2Map } from '@/utils/tag'
import { useRoute, useRouter } from 'vue-router'
import { FeedbackType, postFeedback, updateIdentityTags } from '@/api/user'
import AppBar from '@/mobile/components/AppBar.vue'
import Typewriter from 'typewriter-effect/dist/core'

import type { IdentityTag } from '@/types/core'

// 「其他」标签的 key，这个是飞书表格中的配置，不会改变
const OTHER_TAG_KEY = '5'

let tagCacheMap = new Map<string, IdentityTag>()

const route = useRoute()
const router = useRouter()

const identityTagRoot = ref<IdentityTag | null>(null)
const selectedFirstTagKey = ref<string | null>(null)

const pageLoading = ref(false)
const endLoading = ref(false)

const otherTagReason = ref('')

const selectedFirstTag = computed(() => {
  if (selectedFirstTagKey.value == null || identityTagRoot.value == null) {
    return null
  }

  return tagCacheMap.get(selectedFirstTagKey.value)
})

const isOtherTag = computed(() => selectedFirstTagKey.value === OTHER_TAG_KEY)

pageLoading.value = true
fetchIdentityTags('main')
  .then(res => {
    identityTagRoot.value = res.tree
    tagCacheMap = tree2Map(res.tree)
  })
  .finally(() => {
    pageLoading.value = false
  })

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.push({
      name: 'atlas',
    })
  }
}

async function onEnd() {
  if (!selectedFirstTagKey.value) {
    return
  }

  // 如果选择的是「其他」标签，则提交一下填写的内容
  // 不阻断提交流程
  if (isOtherTag.value) {
    postFeedback({
      type: FeedbackType.IdentityTag,
      content: otherTagReason.value,
    })
  }

  await updateIdentityTags([selectedFirstTagKey.value])

  if (route.query.backAfterEnd) {
    onBack()
  } else {
    router.replace({
      name: _global.homePage.name,
      query: {
        to: 'recommend-list',
      },
    })
  }
}

// 初始只显示：
//   左上角鸭子图标
//   底部 disable 的继续按钮
// 其他元素，按以下时间轴逐步显示：
//   气泡框：从左往右放大出现，150ms
//   气泡框内文字：打字机效果出现， 字间隔 15ms
//   同时有震动效果
//   文字都出现后，停顿 200 ms
//   选项：从右往左滑入，300ms
const showTags = ref(false)
const chatBox = ref<HTMLDivElement>()

function onChatBoxAnimationEnd() {
  const typewriter = new Typewriter(chatBox.value, {
    delay: 15,
    cursor: '',
  })

  typewriter
    .typeString('请选择你的学习阶段')
    .pauseFor(200)
    .start()
    .callFunction(() => {
      setTimeout(() => {
        showTags.value = true
      }, 200)
    })
}

const otherTagInput = ref<any>()
// 当「其他」标签的输入框出现时，滚动到视口位置
watch(
  () => otherTagInput.value,
  input => {
    if (input) {
      input.$el.scrollIntoView({
        block: 'center',
      })
      nextTick(() => {
        input.$el.focus()
      })
    }
  }
)
</script>

<style scoped>
.greet :deep(img) {
  width: 77px;
}

.greet .chat {
  background-color: white;
  border-radius: 18px;
  padding: 12px 16px;
  font-size: 17px;
  line-height: 22px;
  height: fit-content;
  animation: chatScale 0.15s;
  animation-fill-mode: forwards;
  white-space: nowrap;
}

@keyframes chatScale {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    height: 46px;
    width: 192px;
  }
}
</style>
