<template>
  <div
    ref="lottieBox"
    class="inline-block"
  ></div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

import lottie, { type AnimationItem } from 'lottie-web'
import { wait } from '@/utils'

const props = defineProps<{
  name: string
  loop: boolean
  autoplay: boolean
  inline?: boolean
  start?: number | 'end'
  delay?: number
}>()

let anim: AnimationItem | null = null

const lottieBox = ref(null)
const loading = ref(false)

function startFrame(): number {
  if (props.start === 'end') {
    return anim?.totalFrames ?? 0
  }
  return props.start ?? 0
}

onMounted(async () => {
  if (lottieBox.value) {
    loading.value = true

    try {
      await _am.preload([{ type: 'lottie', name: props.name }])
      const animationData = _am.getLottie(props.name)

      if (props.start) {
        anim = lottie.loadAnimation({
          container: lottieBox.value,
          renderer: 'svg',
          loop: false,
          autoplay: false,
          animationData,
        })
        anim.goToAndStop(startFrame(), true)
        anim.addEventListener('complete', () => {
          if (props.loop) {
            anim?.goToAndPlay(startFrame(), true)
          }
        })
      } else {
        anim = lottie.loadAnimation({
          container: lottieBox.value,
          renderer: 'svg',
          loop: props.loop,
          autoplay: false,
          animationData,
        })
      }

      if (props.autoplay) {
        if (props.delay) {
          await wait(props.delay)
        }

        anim.play()
      }
    } finally {
      loading.value = false
    }
  }
})
onUnmounted(() => {
  anim?.destroy()
})
</script>

<style scoped></style>
