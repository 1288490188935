<template>
  <div class="h-full flex flex-col flex-1">
    <div class="content">
      <img
        v-if="parsed.illustration"
        :src="_global.assetUrl(parsed.illustration)"
        class="rounded-8px"
      />
      {{ parsed.question }}
    </div>

    <div class="options">
      <div
        v-for="item in options"
        :key="item.index"
      >
        <span>{{ item.index }}.</span>

        <span>{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { parseMCQCard } from '@/utils/card'

import type { MCQCard } from '@/types/core'
import { genOptions } from '../ConcreteCard/MCQCardFace/common'

const props = defineProps<{
  card: MCQCard
}>()

const parsed = parseMCQCard(props.card).card
const options = genOptions(parsed.options, false, false)
</script>

<style scoped>
.content {
  margin-top: 32px;
  padding: 16px;
  font-size: 19px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  background: var(--opacityWhite);
  border: 1px solid var(--opacityWhite);
  box-shadow: 0px 0px 2px 0px var(--slate-300);
  white-space: pre-line;
}

.options {
  margin-top: 32px;
  padding: 16px;
  font-size: 19px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  background: var(--opacityWhite);
  border: 1px solid var(--opacityWhite);
  box-shadow: 0px 0px 2px 0px var(--slate-300);
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
