<template>
  <div class="question">
    <!--
      这里的 最小高度是为了实现顶部的间距，当 role 不显示的时候，顶部是有个间距的
      这个间距在 boss 和 非 boss 下不同
    -->
    <div :class="[bossName ? 'min-h-8px' : 'min-h-48px']">
      <RoleImage
        v-if="!props.hideRoleImage"
        :use-give-away-role="props.useGiveAwayRoleImage"
        :style="roleImageStyle"
      />
    </div>

    <div
      class="w-full flex flex-col items-center justify-center question-text-background"
    >
      <img
        v-if="props.assetId"
        :src="_global.assetUrl(props.assetId)"
        :style="normalImageStyle"
      />

      <div ref="textRef">
        <slot name="text"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getTextLineCount } from '@/utils'
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import RoleImage from '../common/RoleImage.vue'

const props = withDefaults(
  defineProps<{
    assetId?: string
    hideRoleImage?: boolean
    useGiveAwayRoleImage?: boolean
    roleImage?: {
      hide: boolean
      useGiveAway: boolean
    }
  }>(),
  {
    assetId: undefined,
    hideRoleImage: false,
    useGiveAwayRoleImage: false,
  }
)

const textRef = ref(null)

// 传入 boss name
// boss 阶段/挑战模式 问题顶部的间距(没有 role 时留的间距)改为 8px
const bossName = inject<string | undefined>('bossName', undefined)

// 文字行数
const lines = ref(0)

const isRoleImage = computed(() => {
  return props.assetId == null || props.assetId.trim() === ''
})

// 角色图片样式
const roleImageStyle = computed(() => {
  if (lines.value >= 6 || props.assetId) {
    return {
      height: '48px',
      display: 'block',
    }
  }

  const defaultWidth = 114
  let imageWidth = defaultWidth

  if (lines.value <= 2) {
    imageWidth = defaultWidth
  } else if (lines.value == 3) {
    imageWidth = defaultWidth * (8 / 9)
  } else if (lines.value == 4) {
    imageWidth = defaultWidth * (7 / 9)
  } else if (lines.value === 5) {
    imageWidth = defaultWidth * (6 / 9)
  }

  return {
    width: `${imageWidth}px`,
    display: 'block',
  }
})

const normalImageStyle = computed(() => {
  // 根据行数调整图片大小
  // 图片的宽度最大为 432
  const defaultPct = 100
  let widthPct = defaultPct
  if (lines.value == 3) {
    widthPct = defaultPct * (7 / 8)
  } else if (lines.value == 4) {
    widthPct = defaultPct * (6 / 8)
  } else if (lines.value >= 5) {
    widthPct = defaultPct * (5 / 8)
  }

  return {
    marginBottom: '12px',
    marginLeft: '16px',
    marginRight: '16px',
    maxWidth: '432px',
    width: `${widthPct}%`,
  }
})

function adjustForQuestion() {
  const textElement = textRef.value as HTMLElement | null
  if (!textElement) return
  if (isRoleImage.value) {
    let count = getTextLineCount(textElement)
    lines.value = count
  } else {
    lines.value = getTextLineCount(textElement)
  }
}

function autoLayout() {
  adjustForQuestion()
}

onMounted(() => {
  autoLayout()
  // 监听窗口大小变化
  window.addEventListener('resize', autoLayout)
})
onUnmounted(() => {
  window.removeEventListener('resize', autoLayout)
})
</script>

<style scoped>
.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  line-height: 1.5;
}

.question-text-background {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px var(--slate-300);
  padding: 16px;
}
</style>
