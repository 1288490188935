<template>
  <div
    class="rounded-full overflow-hidden bg-gray-200 flex justify-center items-center"
  >
    <img
      v-if="props.avatar"
      :src="src"
      class="w-full h-full object-cover object-center"
    />

    <PrimeVueAvatar
      v-else-if="props.label"
      :label="props.label"
      shape="circle"
    />

    <Icon
      v-else
      name="logo"
      class="w-[80%] h-[80%]"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import PrimeVueAvatar from 'primevue/avatar'

const props = withDefaults(
  defineProps<{
    avatar?: string
    label?: string
    imgStyle?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
  }>(),
  {
    avatar: undefined,
    label: undefined,
    imgStyle: 'm',
  }
)

const src = computed(() => {
  if (!props.avatar) return ''

  // 兼容一下 完整链接
  if (props.avatar.startsWith('http')) {
    return props.avatar
  }

  const src = _global.assetUrl(props.avatar, props.imgStyle)

  return src
})
</script>
<style scoped></style>
