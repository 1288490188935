<template>
  <Loading v-if="loading" />

  <div
    v-else
    class="h-full g-safe-area"
  >
    <LessonEndView
      class="h-full bg-ld-background"
      :stat="mockStat"
      :mode="AtlasStageMode.PASSIONATE"
      @continue="onContinue"
    />
  </div>
</template>

<script setup lang="ts">
import { AtlasStageMode } from '@/api/atlas'
import type { UnitCompleteStat } from '@/api/learn'
import LessonEndView from '@/components/LessonEndView.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()

const loading = ref(true)

const mockStat: UnitCompleteStat = {
  unitId: 0,
  debutCount: 5,
  reviewCount: 5,
  comboCount: 10,
  duration: 300,
  exp: 20,
  comboExp: 5,
  star: 3,
  checkInPopped: true,
}

function onContinue() {
  router.back()
}

onInit(() => {
  _am.preload([{ type: 'audio', name: 'lesson_end' }]).then(() => {
    loading.value = false
  })
})
</script>

<style scoped></style>
