import { random, shuffle, uniqBy } from 'lodash-es'

import type { Cloze, ClozeCard, Content } from '@/types/core'
import { getContentClozes } from '@/utils/card'

export interface Option {
  text: string
  used: boolean
  wrong?: boolean
}

export interface IndexedCloze extends Cloze {
  index: number
}

export function getAllIndexedClozes(content: Content): IndexedCloze[] {
  const clozes: IndexedCloze[] = []
  let i = 0
  for (const blockNode of content) {
    for (const n of blockNode.content) {
      if (n.type === 'cloze') {
        const result = n as IndexedCloze
        result.index = i
        clozes.push(result)
        i++
      }
    }
  }
  return clozes
}

export const MAX_OPTIONS = 10
export const MIN_OPTIONS = 4

export function genOptions(
  clozes: Cloze[],
  {
    lessOptions,
    useGiveAwayDistrators,
    altCards,
  }: {
    lessOptions: boolean
    useGiveAwayDistrators: boolean
    altCards: ClozeCard[]
  }
): Option[] {
  const clozeOptions: Option[] = clozes.map(item => {
    return {
      text: item.text,
      used: false,
    }
  })

  if (clozeOptions.length === 0) {
    return []
  }

  const answerTextSet = new Set<string>(clozeOptions.map(item => item.text))

  const allDistratorOptions = clozes.reduce<Option[]>((acc, cur: Cloze) => {
    let distrators =
      (useGiveAwayDistrators ? cur.giveAwayDistrators : cur.distrators) ?? []

    if (!Array.isArray(distrators)) {
      distrators = []
    }

    return acc.concat(
      distrators.map(item => ({
        text: item,
        used: false,
      }))
    )
  }, [])

  if (allDistratorOptions.length === 0) {
    for (const card of altCards) {
      const altClozes = getContentClozes(card.content)

      for (const cloze of altClozes) {
        allDistratorOptions.push({
          text: cloze.text,
          used: false,
        })

        let distrators =
          (useGiveAwayDistrators
            ? cloze.giveAwayDistrators
            : cloze.distrators) ?? []

        if (!Array.isArray(distrators)) {
          distrators = []
        }

        allDistratorOptions.push(
          ...distrators.map(item => ({
            text: item,
            used: false,
          }))
        )
      }
    }
  }

  // 对所有干扰项 做一下 trim
  allDistratorOptions.forEach(item => {
    item.text = item.text.trim()
  })

  const uniqedDistratorOptions = uniqBy(
    allDistratorOptions,
    item => item.text
  ).filter(item => !answerTextSet.has(item.text))

  // 更少选项
  if (lessOptions) {
    if (clozeOptions.length > 1) {
      return shuffle(clozeOptions)
    }

    if (uniqedDistratorOptions.length === 0) {
      return clozeOptions
    }

    const distrators = sortDistratorOptions(
      uniqedDistratorOptions,
      clozes
    ).slice(0, 1)

    return shuffle(clozeOptions.concat(distrators))
  }

  if (clozeOptions.length >= MAX_OPTIONS) {
    return shuffle(clozeOptions)
  }

  const distratorCount = Math.max(
    MIN_OPTIONS - clozeOptions.length,
    Math.min(MAX_OPTIONS - clozeOptions.length, 2)
  )
  const distratorOptions = sortDistratorOptions(
    uniqedDistratorOptions,
    clozes
  ).slice(0, distratorCount)

  return shuffle(clozeOptions.concat(distratorOptions))
}

function sortDistratorOptions(options: Option[], clozes: Cloze[]): Option[] {
  const sortKeyMap1 = new Map<Option, number>()
  const sortKeyMap2 = new Map<Option, number>()

  for (const op of options) {
    let minCharDiff = Infinity
    for (const cloze of clozes) {
      const diff = Math.abs(cloze.text.length - op.text.length)

      if (diff < minCharDiff) {
        minCharDiff = diff
      }
    }

    sortKeyMap1.set(op, minCharDiff)
    sortKeyMap2.set(op, random(100))
  }

  options.sort((a, b) => {
    const aKey1 = sortKeyMap1.get(a) ?? 0
    const aKey2 = sortKeyMap2.get(a) ?? 0
    const bKey1 = sortKeyMap1.get(b) ?? 0
    const bKey2 = sortKeyMap2.get(b) ?? 0

    if (aKey1 < bKey1) return -1
    if (aKey1 > bKey1) return 1
    if (aKey2 < bKey2) return -1
    if (aKey2 > bKey2) return 1

    return 0
  })

  return options
}

// 每个挖空的结果，key 为挖空的位置，value 为挖空的状态，其中 null 为做对
type ResultMap = Record<number, 'replace' | 'remove' | 'wrong' | null>

export function checkClozes(
  clozes: Cloze[],
  inputMap: Record<number, Option | null>
): {
  correct: boolean
  resultMap: ResultMap
  remainingNeedAnswers: string[]
} {
  // 先根据挖空的 group 收集每个 group 所需要的答案
  const groupAnswers = clozes.reduce(
    (acc, cur) => {
      if (acc[cur.group] == null) {
        acc[cur.group] = [cur.text]
      } else {
        acc[cur.group].push(cur.text)
      }
      return acc
    },
    {} as Record<string, string[]>
  )

  const resultMap: ResultMap = {}
  const remainingNeedAnswers: string[] = []

  // 先把所需要的答案放在一起
  for (const clozeIndex in groupAnswers) {
    remainingNeedAnswers.push(...groupAnswers[clozeIndex])
  }

  for (const clozeIndex in clozes) {
    const op = inputMap[clozeIndex]

    if (op == null) {
      resultMap[clozeIndex] = 'wrong'
      continue
    }

    const cloze = clozes[clozeIndex]

    const groupNeedAnswers = groupAnswers[cloze.group]
    const index = groupNeedAnswers.indexOf(op.text)

    if (index > -1) {
      resultMap[clozeIndex] = null
      groupNeedAnswers.splice(index, 1)
      remainingNeedAnswers.splice(remainingNeedAnswers.indexOf(op.text), 1)
    } else {
      resultMap[clozeIndex] = 'wrong'
    }
  }

  for (const clozeIndex in clozes) {
    if (resultMap[clozeIndex] === 'wrong') {
      const op = inputMap[clozeIndex]

      if (op == null) continue

      if (remainingNeedAnswers.includes(op.text)) {
        resultMap[clozeIndex] = 'replace'
        remainingNeedAnswers.splice(remainingNeedAnswers.indexOf(op.text), 1)
      } else {
        resultMap[clozeIndex] = 'remove'
      }
    }
  }

  const correct = Object.values(resultMap).every(item => item == null)

  return {
    correct,
    resultMap,
    remainingNeedAnswers,
  }
}
