<template>
  <div class="flex items-start gap-2">
    <div
      v-if="_global.isPcMode"
      class="flex flex-1 items-start"
    >
      <TextClamp>
        <div class="w-174px text-21px font-bold">
          {{ props.card.word }}
        </div>
      </TextClamp>

      <Divider
        v-if="card.definition"
        layout="vertical"
      />
      <TextClamp>
        <span class="flex-1 text-19px">{{ card.definition }}</span>
      </TextClamp>
    </div>

    <div
      v-else
      class="flex flex-col flex-1"
    >
      <TextClamp>
        <div class="text-21px font-bold">
          {{ card.word }}
        </div>
      </TextClamp>
      <TextClamp>
        <span class="flex-1">{{ card.definition }}</span>
      </TextClamp>
    </div>

    <Image
      v-if="card.illustration"
      preview
      class="illustration ml-6 shrink-0"
    >
      <template #image>
        <img
          :src="_global.assetUrl(card.illustration)"
          class="h-78px w-78px rounded-4px object-cover"
          @click.stop
        />
      </template>
      <template #preview="slotProps">
        <img
          :src="_global.assetUrl(card.illustration)"
          class="object-cover"
          :style="slotProps.style"
          @click="slotProps.onClick"
        />
      </template>
    </Image>
  </div>
</template>
<script setup lang="ts">
import type { EnWordCard } from '@/types/core'
import Image from 'primevue/image'
import TextClamp from '@/components/TextClamp.vue'

const props = defineProps<{
  card: EnWordCard
}>()
</script>
<style scoped></style>
