<template>
  <div class="header text-center">
    <span class="text-19px leading-24px font-semibold">
      {{ _t('创建卡包') }}
    </span>

    <Icon
      name="close-circle"
      class="cursor-pointer absolute right-4 top-3 w-22px"
      @click="emit('done')"
    ></Icon>
  </div>
  <div class="text-19px font-semibold text-ld-brand-500 mt-6 mb-3">
    {{ _t('请选择卡包类型：') }}
  </div>

  <div class="flex flex-col gap-2">
    <div
      :class="[
        'cardtype',
        {
          selected: type === CardType.CLOZE,
        },
      ]"
      @click="type = CardType.CLOZE"
    >
      <Icon
        name="cardtype-cloze"
        class="w-48px"
      />

      <div class="flex flex-col gap-1">
        <div class="title flex items-center gap-1">
          {{ _t('知识点') }}

          <span
            class="text-white bg-rose-500 rounded-4px text-13px leading-[1.5] font-semibold px-6px"
          >
            {{ _t('热门') }}
          </span>
        </div>
        <div class="text-15px text-gray-600">
          {{ _t('已支持 AI 智能制卡') }}
        </div>
      </div>
    </div>

    <div
      :class="[
        'cardtype',
        {
          selected: type === CardType.EN_WORD,
        },
      ]"
      @click="type = CardType.EN_WORD"
    >
      <Icon
        name="cardtype-enword"
        class="w-48px"
      />
      <span class="title">{{ _t('英语单词') }}</span>
    </div>

    <div
      :class="[
        'cardtype',
        {
          selected: type === CardType.MCQ,
        },
      ]"
      @click="type = CardType.MCQ"
    >
      <Icon
        name="cardtype-mcq"
        class="w-48px"
      />
      <span class="title">{{ _t('选择题') }}</span>
    </div>
  </div>

  <Button
    class="mt-6"
    :disabled="type == null"
    :disabledMsg="_t('请选择卡包类型')"
    :label="_t('继续')"
    @click="onCardTypeConfirmed"
  ></Button>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { CardType } from '@/types/core'

const props = defineProps<{
  type?: CardType
}>()

const emit = defineEmits<{
  done: []
  confirmed: [CardType]
}>()

const type = ref<CardType | undefined>(props.type)

function onCardTypeConfirmed() {
  if (type.value == null) return

  emit('confirmed', type.value)
}
</script>
<style scoped>
.cardtype {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--ld-border);
  cursor: pointer;
}

.cardtype.selected {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-100);
}

.cardtype .title {
  font-size: 19px;
  font-weight: 600;
}
</style>
