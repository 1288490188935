<template>
  <div class="flex-1 flex flex-col items-center bg-[--ld-background] relative">
    <Icon
      name="check-in-bg"
      class="absolute top-0 z-0 w-full h-260px"
    />

    <SafeAreaTopSpacer
      v-if="_global.isMobileMode"
      class="bg-ld-background z-2 w-full"
    />

    <AppBar
      class="w-full"
      title=""
      style="z-index: 2"
      @back="clickBack"
    ></AppBar>
    <div class="flex flex-col items-center justify-center m-b-2 z-1">
      <Icon
        name="icon_check_in"
        class="w-130px"
      ></Icon>
      <div class="text-19px font-semibold">
        连续签到
        <span class="font-[DIN] text-40px font-bold text-orange-400">
          {{ store.checkInCtRes?.continuousDays }}
        </span>
        天
      </div>
    </div>

    <CheckInMonthCalendar
      class="max-w-600px w-full"
      :currentYear="currentYear"
      :currentMonth="currentMonth"
      :checkInDates="checkInStatsRes"
      :count="count"
      :duration="0"
      :card="0"
      :nextMonthEnable="nextMonthEnable"
      @onNextMonth="onNextMonth"
      @onPrevMonth="onPrevMonth"
    />
  </div>
</template>

<script setup lang="ts">
import { checkInStats, type CheckInStatsItem } from '@/api/user'
import Icon from '@/components/Icon.vue'
import CheckInMonthCalendar from '@/components/CheckMonthCalendar.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { formatDate } from '@/utils'
import { computed, ref } from 'vue'
import { useCommonStore } from '@/stores'

const store = useCommonStore()

const today = new Date()

const emit = defineEmits<{
  done: []
}>()

const currentYear = ref(today.getFullYear())
const currentMonth = ref(today.getMonth())

const checkInStatsResMap = ref<Map<string, CheckInStatsItem[]>>()
const checkInStatsRes = computed(() => {
  const key = `${currentYear.value}-${currentMonth.value}`
  return checkInStatsResMap.value?.get(key) || []
})

const nextMonthEnable = computed(() => {
  return (
    currentYear.value < today.getFullYear() ||
    (currentYear.value === today.getFullYear() &&
      currentMonth.value < today.getMonth())
  )
})
// 本月签到天数
const count = computed(() => {
  return checkInStatsRes.value?.filter(item => item.type).length || 0
})
store.fetchCheckInCtRes()
fetchCheckInStats()
async function fetchCheckInStats() {
  // 获取当月的数据
  const currentMonthFirstDay = new Date(
    currentYear.value,
    currentMonth.value,
    1
  )
  const currentMonthLastDay = new Date(
    currentYear.value,
    currentMonth.value + 1,
    0
  )
  const key = `${currentYear.value}-${currentMonth.value}`
  if (!checkInStatsResMap.value) {
    checkInStatsResMap.value = new Map()
  }
  if (!checkInStatsResMap.value.has(key)) {
    const res = await checkInStats(
      formatDate(currentMonthFirstDay, 'YYYY-MM-DD'),
      formatDate(currentMonthLastDay, 'YYYY-MM-DD')
    )
    checkInStatsResMap.value.set(key, res.stats)
  }
}

function onNextMonth() {
  currentMonth.value++
  if (currentMonth.value > 11) {
    currentYear.value++
    currentMonth.value = 0
  }
  fetchCheckInStats()
}
function onPrevMonth() {
  currentMonth.value--
  if (currentMonth.value < 0) {
    currentYear.value--
    currentMonth.value = 11
  }
  fetchCheckInStats()
}
function clickBack() {
  emit('done')
}
</script>

<style scoped></style>
