<template>
  <div class="relative">
    <Img
      :name="rankIcon"
      class="w-full h-full"
    />

    <div
      v-if="star > 1"
      class="absolute bottom-18% left-50% translate-x--1/2"
    >
      <ColorNumber
        :value="star"
        :fontWidth="props.starFontWidth"
        color="legendary"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import ColorNumber from '@/components/ColorNumber.vue'
import { type Rank } from '@/types/rank'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    rank: Rank
    starFontWidth?: number
    locked?: boolean
  }>(),
  {
    starFontWidth: 9,
    locked: false,
  }
)

const star = computed(() => props.rank.star ?? 0)

const rankIcon = computed(() => {
  const rankIconName = `rank-${props.rank.type.toLocaleLowerCase()}`

  return props.locked ? `${rankIconName}-locked` : rankIconName
})
</script>
<style scoped></style>
