<template>
  <h1 class="px-6 my-4 font-bold text-xl">Debug Components</h1>
  <ul class="list-disc list-inside px-6">
    <li
      v-for="item in items"
      :key="item.path"
      class="my-2"
    >
      <RouterLink :to="DEBUG_ROUTE_PREFIX + item.path">
        {{ item.path }}
      </RouterLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { debugRoute, DEBUG_ROUTE_PREFIX } from '@/shared'

const items = debugRoute.children.filter(r => r.path !== '')
</script>

<style scoped></style>
