import api, { type CommonResponse } from './base'

export function consumeRcode(rcode: string) {
  return api.post<
    unknown,
    CommonResponse<{
      diamonds: number
    }>
  >('/v1/rcode/consume', {
    rcode,
  })
}

export type RcodeInvitationConfig = {
  // 接头暗号
  code: string

  // 注册多长时间内的用户可使用，单位为秒
  newUserDuration: number

  // 邀请的用户增长的面包上限值
  inviterEnergyLimit: number

  // 被邀请的用户获得的钻石数量
  inviteeGetDiamond: number
}

export function fetchInvitationConfig() {
  return api.get<unknown, RcodeInvitationConfig>('/v1/rcode/invitation-config')
}
