<template>
  <Loading
    v-if="loading"
    class="h-ld-screen"
  />

  <div
    v-else
    class="vstack px-4 min-h-full g-safe-area"
  >
    <RatioSpacedContainer
      class="flex-1"
      :top="4"
      :bottom="6"
    >
      <Img
        name="ld-greet"
        class="w-107px"
      />

      <div class="my-4 flex flex-col">
        <div
          ref="typewriterText"
          class="text-24px"
        ></div>
      </div>
    </RatioSpacedContainer>

    <div class="mb-50px w-full">
      <Button
        v-if="_global.isInsideWechat"
        class="mt-auto w-full"
        label="Wechat"
        scene="wechat"
        @click="onWechatLogin"
      >
        <div class="flex justify-center items-center w-full">
          <Icon
            name="wechat-pure"
            class="h-28px"
          />
          <span class="ml-1 text-lg">{{ _t('微信登录') }}</span>
        </div>
      </Button>

      <Button
        v-else
        class="mt-auto w-full justify-center"
        :label="_t('马上开始')"
        @click="onLogin"
      ></Button>

      <DebugButton
        label="访客登录"
        class="mt-4 w-full"
        @click="debugGuestLogin(router, route)"
      ></DebugButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { wechatLogin } from '@/utils/wechat'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Typewriter from 'typewriter-effect/dist/core'
import Loading from '@/components/Loading.vue'
import { useLandingLogic } from '@/shared/landing'
import { debugGuestLogin } from '@/debug'

const router = useRouter()
const route = useRoute()
const typewriterText = ref()

// query:
//  - redirect?: redirect url

const loading = useLandingLogic(router, route)

watch(
  () => typewriterText.value,
  () => {
    if (typewriterText.value == null) return

    const typewriter = new Typewriter(typewriterText.value, {
      delay: 100,
    })

    typewriter
      .typeString('闯关玩游戏，')
      .pauseFor(200)
      .typeString('学习零压力')
      .pauseFor(1000)
      .start()
  }
)

function onLogin() {
  router.push({
    name: 'auth',
    query: {
      redirect: route.query.redirect || undefined,
    },
  })
}

function onWechatLogin() {
  wechatLogin()
}
</script>
<style scoped></style>
