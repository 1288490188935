<template>
  <main class="flex flex-col h-full">
    <div
      :class="{
        'app-content': !route.meta.fullscreen,
        'h-full': route.meta.fullscreen,
      }"
    >
      <RouterView v-slot="{ Component, route }">
        <KeepAlive>
          <component
            :is="Component"
            v-if="route.meta.keepAlive"
          />
        </KeepAlive>

        <component
          :is="Component"
          v-if="!route.meta.keepAlive"
        />
      </RouterView>
    </div>

    <AppFooter
      v-if="!route.meta.fullscreen"
      class="bg-gray-100 g-safe-area"
    />
  </main>
</template>

<script setup lang="ts">
import AppFooter from './components/AppFooter.vue'
import { useRoute } from 'vue-router'
const route = useRoute()
</script>

<style>
.app-content {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
</style>
