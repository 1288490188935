<template>
  <div class="h-full px-4 pb-4 flex flex-col bg-ld-background">
    <AppBar
      :title="_t('申请注销')"
      class="px-0"
      @back="onBack"
    />

    <div class="overflow-y-auto mb-4">
      <div class="px-2 mb-18px flex flex-col gap-3">
        <div class="text-21px leading-22px font-semibold mt-9">
          {{ _t('申请注销小灵鸭') }}
        </div>
        <div class="text-17px leading-[1.8]">
          {{ _t('请仔细阅读以下注销须知，以确保您了解注销账户的影响') }}
        </div>
      </div>

      <div class="bg-white rounded-18px py-4 px-3">
        <ol class="pl-5 list-disc list-outside text-17px leading-[1.8]">
          <li>{{ _t('若你有共享中的卡包，请先取消共享后再申请注销。') }}</li>
          <li>
            {{
              _t('注销后，你将无法登录或使用该账户，所有登录方式都将被移除。')
            }}
          </li>
          <li>
            {{ _t('该账户的所有个人资料、学习数据都将被清空，且无法恢复。') }}
          </li>
          <li>
            {{
              _t('该账户的钻石、经验等货币资产都将被清空，且无法恢复或转移。')
            }}
          </li>
          <li>
            {{ _t('注销无法取消 iOS 订阅，需要自行前往 AppStore 取消订阅') }}
          </li>
          <li>
            {{
              _t('所有账户数据将在申请注销 14 天后删除，在此之前你可以终止注销')
            }}
          </li>
        </ol>
      </div>
    </div>

    <Button
      :label="buttonLabel"
      :disabled="disabled"
      scene="danger"
      class="mt-auto"
      @click="onTerminateSubmit"
    />
  </div>
</template>
<script setup lang="ts">
import { Code } from '@/api/code'
import { applyTermination } from '@/api/user'
import AppBar from '@/mobile/components/AppBar.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const emit = defineEmits<{
  back: []
}>()

const router = useRouter()

const freezeSeconds = ref(10)

const disabled = computed(() => freezeSeconds.value > 0)
const buttonLabel = computed(() => {
  if (freezeSeconds.value > 0) return _t(`申请注销（${freezeSeconds.value}）`)

  return _t(`申请注销`)
})
let timer: number

function startTimer() {
  timer = setInterval(function () {
    freezeSeconds.value--

    if (freezeSeconds.value <= 0) {
      clearInterval(timer)
    }
  }, 1000)
}

function onTerminateSubmit() {
  _confirm({
    scene: 'warn',
    icon: {
      name: 'ld-cry',
      type: 'img',
    },
    content: _t('注销后将清除你的所有数据、且无法恢复，确认要注销吗？'),
    primaryText: _t('暂不注销'),
    secondaryText: _t('确认注销'),
    onSecondaryClick(resolve) {
      applyTermination().then(res => {
        if (res.code !== Code.Ok) {
          resolve(false)
          _message.info(res.message)
          return
        }

        resolve(true)
        router.replace({
          name: 'terminating',
          query: {
            willTerminateAt: res.data.willTerminateAt,
          },
        })
      })
    },
  })
}

function onBack() {
  emit('back')
}

onInit(startTimer)
</script>
<style scoped></style>
