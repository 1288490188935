<template>
  <div
    :class="[
      'flex',
      {
        'flex-col items-stretch': !_global.isPcMode,
        'items-center': _global.isPcMode,
      },
    ]"
  >
    <div
      :class="[
        'flex-1 mr-2 my-2 flex items-start',
        {
          'gap-4': _global.isPcMode,
          'gap-2 flex-col': !_global.isPcMode,
        },
      ]"
    >
      <div
        :class="[
          'relative',
          {
            'basis-2/5': _global.isPcMode,
            'w-full': !_global.isPcMode,
          },
        ]"
      >
        <Textarea
          :modelValue="card.word"
          class="g-underline-input w-100%"
          rows="1"
          autoResize
          placeholder="单词"
          maxlength="1000"
          @focus="onEnWordInputFocus"
          @update:model-value="onEnWordCardUpdate({ word: $event })"
          @keydown.up="onPrevWordRecommend"
          @keydown.down="onNextWordRecommend"
          @keydown.enter="onWordInputEnter"
          @blur="onWordInputBlur"
        />

        <div
          v-if="wordInvalidMessage && showInvalidTip"
          class="text-red text-14px"
        >
          {{ wordInvalidMessage }}
        </div>

        <div
          v-if="showEnWordResult"
          ref="listRef"
          class="absolute enword-list flex flex-col"
        >
          <div
            v-for="(item, index) in enWordRecommends"
            :key="index"
            :class="[
              'text-15px leading-24px flex cursor-pointer hover:bg-[var(--surface-100)] px-12px py-2',
              {
                'bg-[var(--surface-100)]': selectedWordRecommend === index,
              },
            ]"
            @mouseenter="selectedWordRecommend = index"
            @click="onWordRecommendPick(item)"
          >
            <div>{{ item.spelling }}</div>
            <div
              class="text-[var(--text-color-secondary)] flex-1 ml-2 truncate"
            >
              {{ item.explain }}
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{
          'basis-3/5': _global.isPcMode,
          'w-full': !_global.isPcMode,
        }"
      >
        <Textarea
          :modelValue="card.definition"
          class="g-underline-input w-full"
          autoResize
          rows="1"
          placeholder="释义"
          maxlength="1000"
          @focus="onEnWordInputFocus"
          @update:model-value="onEnWordCardUpdate({ definition: $event })"
          @blur="showInvalidTip = true"
        />
        <div
          v-if="definitionInvalidMessage && showInvalidTip"
          class="text-red text-14px"
        >
          {{ definitionInvalidMessage }}
        </div>
      </div>
    </div>

    <div
      v-if="(!card.illustration && _global.isPcMode) || illustrationUploading"
      :class="[
        'h-78px w-78px flex items-center justify-center border border-dashed rounded bg-[var(--surface-100)] cursor-pointer',
        {
          'ml-auto': !_global.isPcMode,
        },
      ]"
      @click="emit('add-illustration')"
    >
      <ProgressSpinner
        v-if="illustrationUploading"
        class="w-36px h-36px p-11px"
      />

      <Icon
        v-else
        name="illustration"
        class="w-23px text-gray-600"
      />
    </div>

    <Image
      v-else-if="card.illustration"
      preview
      :class="[
        'illustration w-fit',
        {
          'mt-2 ml-auto': !_global.isPcMode,
        },
      ]"
    >
      <template #image>
        <div class="relative">
          <Img
            :assetId="card.illustration"
            class="h-78px w-78px rounded-8px object-cover"
            @click.stop
          />

          <Icon
            name="image-delete"
            class="absolute top-0 right-0 z-1 cursor-pointer w-18px"
            @click="emit('remove-illustration')"
          />
        </div>
      </template>
      <template #preview="slotProps">
        <img
          :src="_global.assetUrl(card.illustration)"
          class="object-cover"
          :style="slotProps.style"
          @click="slotProps.onClick"
        />
      </template>
    </Image>
  </div>
</template>
<script setup lang="ts">
import { searchEnWord, type DictEnWord } from '@/api/learn'
import type { CardResponse } from '@/api/package-source'
import type { EnWordCard, PronunciationLanguage } from '@/types/core'
import { debounce } from 'lodash-es'
import { computed, ref } from 'vue'
import Image from 'primevue/image'
import ProgressSpinner from 'primevue/progressspinner'
import Textarea from 'primevue/textarea'
import { onClickOutside } from '@vueuse/core'
import { watch } from 'vue'
import { removeCardEmptyFields } from '@/utils/card'

const props = defineProps<{
  cardRes: CardResponse
  card: EnWordCard
  illustrationUploading: boolean
}>()

const emit = defineEmits<{
  focus: []
  update: [EnWordCard]
  'add-illustration': []
  'remove-illustration': []
}>()

const listRef = ref<HTMLDivElement>()
const showInvalidTip = ref(false)
const showEnWordResult = ref(false)
const enWordRecommends = ref<DictEnWord[]>([])
const selectedWordRecommend = ref(-1)

const filteredCard = computed(
  () => removeCardEmptyFields(props.card) as EnWordCard
)

const wordInvalidMessage = computed(() => {
  if (filteredCard.value.word.trim() === '') {
    return _t('请填写「单词」')
  }
  return ''
})

const definitionInvalidMessage = computed(() => {
  if (filteredCard.value.definition.trim() === '') {
    return _t('请填写「释义」')
  }
  return ''
})

function onEnWordInputFocus() {
  emit('focus')
  showInvalidTip.value = false
}

function onWordInputBlur() {
  showInvalidTip.value = true
}

function onWordInputEnter(e: KeyboardEvent) {
  if (showEnWordResult.value) {
    const item = enWordRecommends.value[selectedWordRecommend.value]
    onWordRecommendPick(item)
    e.preventDefault()
  }
}

function onNextWordRecommend() {
  if (!showEnWordResult.value) return

  if (selectedWordRecommend.value === enWordRecommends.value.length - 1) {
    selectedWordRecommend.value = 0
  } else {
    selectedWordRecommend.value++
  }
}

function onPrevWordRecommend() {
  if (!showEnWordResult.value) return

  if (selectedWordRecommend.value === 0) {
    selectedWordRecommend.value = enWordRecommends.value.length - 1
  } else {
    selectedWordRecommend.value--
  }
}

function onWordRecommendPick(enWord: DictEnWord) {
  showEnWordResult.value = false

  onEnWordCardUpdate(
    {
      word: enWord.spelling,
      definition: enWord.explain,
      prons: [
        {
          label: enWord.phoneticUk,
          language: 'en-GB',
        },
        {
          label: enWord.phoneticUs,
          language: 'en-US',
        },
      ],
    },
    false
  )
}

function onEnWordCardUpdate(
  {
    word,
    definition,
    prons,
  }: {
    word?: string
    definition?: string
    prons?: {
      label: string
      language: PronunciationLanguage
    }[]
  },
  search = true
) {
  if (word != null && search) {
    onEnWordKeywordChange(word)
  }

  const enWordCard = filteredCard.value

  emit('update', {
    ...enWordCard,
    illustration: enWordCard.illustration?.trim() || undefined,
    word: word ?? enWordCard.word,
    definition: definition ?? enWordCard.definition,
    prons: prons ?? enWordCard.prons,
  } as EnWordCard)
}

const onEnWordKeywordChange = debounce(async function onEnWordKeywordChange(
  keyword: string
) {
  if (keyword.trim() === '') {
    showEnWordResult.value = false
    return
  }

  const result = await searchEnWord(keyword)

  if (result.dictEnList.length > 0) {
    enWordRecommends.value = result.dictEnList
    showEnWordResult.value = true
  }
}, 300)

// 每次点击单词列表外面时关闭单词列表
watch(
  () => listRef.value,
  function () {
    if (listRef.value) {
      onClickOutside(listRef.value, function () {
        showEnWordResult.value = false
      })
    }
  }
)
</script>
<style scoped>
.enword-list {
  width: 100%;
  top: 100%;
  box-shadow: 0px 4px 4px 0px var(--slate-300);
  border: 1px solid var(--surface-300);
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  z-index: 2;
}
</style>
