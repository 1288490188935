import api, { type CommonResponse } from './base'

type AlphaCreatorData = {
  purpose: string
  customReason: string
  suggestion: string
}

export enum FeatureType {
  AlphaCreator = 'alphaCreator',
}

export async function applyBetaFeature(
  name: FeatureType,
  data: AlphaCreatorData
) {
  return api.post<unknown, CommonResponse<never>>('/v1/beta-feature/apply', {
    name,
    data,
  })
}

export enum ApplyStatus {
  // 已提交申请
  PENDING = 'PENDING',

  // 已通过
  APPROVED = 'APPROVED',

  // 可申请
  AVAILABLE = 'AVAILABLE',

  // 入口已关闭，不可申请
  UNAVAILABLE = 'UNAVAILABLE',
}

export async function fetchBetaFeatureStatus(name: FeatureType) {
  return api.get<
    unknown,
    {
      status: ApplyStatus
      beta?: {
        title: string
        content: string
      }
    }
  >('/v1/beta-feature/status', {
    params: {
      name,
    },
  })
}

export async function updateSubscribeScene(name: FeatureType, scene: number) {
  return api.post<unknown, never>('/v1/beta-feature/subscribe-scene', {
    name,
    wxOnetimeScene: scene,
  })
}
