import api from './base'

import type { CommonResponse } from './base'

// 使用微信 code 查询 openId
export function queryWechatOpenId(
  code: string,
  wechatType?: 'WEB' | 'APP' | 'MP'
) {
  return api.post<unknown, CommonResponse<{ openId: string }>>(
    `/public/misc/query-wechat-openid`,
    {
      code,
      wechatType,
    }
  )
}

// 获取微信 jsapi config 参数
export function fetchWechatJsapiConfig(url: string) {
  return api.post<
    unknown,
    {
      appId: 'string'
      nonceStr: 'string'
      timestamp: 0
      url: 'string'
      signature: 'string'
    }
  >(`/public/misc/wechat/jsapi-signature`, {
    url,
  })
}
