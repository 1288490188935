<template>
  <div class="header mx-2">
    <div>{{ _t(`第 ${props.stageLevel} 关`) }}</div>

    <Icon
      name="close-circle"
      class="cursor-pointer absolute right-0 top-0 w-22px"
      @click="onClose"
    ></Icon>
  </div>

  <div class="h-162px my-6">
    <Loading
      v-if="loading"
      class="h-full"
    />

    <Swiper
      v-else
      effect="coverflow"
      slidesPerView="auto"
      :centeredSlides="true"
      :grabCursor="true"
      :pagination="true"
      :coverflowEffect="{
        rotate: 0,
        stretch: 0,
        depth: 0,
        scale: 0.8,
        slideShadows: false,
      }"
      :modules="[EffectCoverflow]"
      @slideChange="onSlideChange"
      @swiper="onSetSwiper"
    >
      <SwiperSlide
        v-for="(item, index) in preStageInfo.packages"
        :key="item.id"
        class="w-130px h-165px"
      >
        <PkgCard
          :package="item"
          :selected="activeIndex === index"
          class="w-full h-full"
          @click="onPkgClick(index)"
        />
      </SwiperSlide>

      <SwiperSlide class="w-130px h-165px">
        <div
          class="pkg-add"
          @click="onPkgAddClick"
        >
          <Icon
            name="pkg-add"
            class="w-72px"
          />
          <span class="text-sm mt-3 h-36px flex items-center">
            {{ _t('添加卡包') }}
          </span>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>

  <DebugLabel class="absolute top-0 left-0">
    <DebugButton
      v-if="pkg"
      label="完成关卡"
      size="small"
      @click="onDebugFinishStage"
    ></DebugButton>
  </DebugLabel>

  <div class="px-3">
    <div
      v-if="pkg != null"
      class="h-27px mb-2 flex justify-center items-center text-15px font-semibold gap-1"
    >
      <div v-if="isPkgTrailAllUsed(pkg)">
        {{ _t('该卡包体验次数已用完') }}
      </div>

      <template v-else-if="isPassionateModeEnabled">
        <Icon
          name="crazy-mode"
          class="w-20px text-rose-500"
        />
        <span class="text-rose-500 font-semibold">
          {{ _t('狂热学习：不消耗面包、不增加经验') }}
        </span>
      </template>

      <ExpInfo
        v-else
        :exp="getPkgExp(pkg)"
      />
    </div>

    <div class="min-h-54px">
      <template v-if="pkg != null">
        <Button
          v-if="!isPkgLearnable(pkg)"
          class="w-full"
          :label="_t('解锁完整版')"
          @click="onPackageUnlock(pkg)"
        />

        <template v-else>
          <Button
            class="w-full"
            :scene="isPassionateModeEnabled ? 'passionate' : 'primary'"
            :loading="stageLoading"
            @click="onStageStart(pkg)"
          >
            <div
              class="flex justify-center w-full font-semibold items-center text-21px"
            >
              <span>{{ _t('开始闯关') }}</span>
              <EnergyCount
                v-if="!isPassionateModeEnabled"
                :count="preStageInfo.requiredEnergy"
                class="ml-4"
              />
            </div>
          </Button>

          <div
            v-if="store.isVipValid"
            class="w-full flex items-center justify-center mt-2 mb-1 gap-3"
          >
            <div
              :class="[
                'text-17px font-semibold',
                isPassionateModeEnabled ? 'text-rose-500' : 'text-gray',
              ]"
            >
              {{ _t('狂热学习') }}
            </div>

            <Tooltip
              :show="showPassionateModeGuide"
              :z-index="passionateTooltipZindex"
              trigger="manual"
              placement="bottom"
            >
              <div class="text-17px font-semibold">
                {{ _t('当前面包不足，可开启「狂热学习」') }}
              </div>
              <div class="text-17px">
                {{ _t('开启后：闯关不消耗面包、不增加经验') }}
              </div>

              <template #trigger>
                <NSwitch
                  :value="store.passionateMode"
                  :rail-style="passionateSwitchRailStyle"
                  @update:value="onPassionateModeChange"
                />
              </template>
            </Tooltip>
          </div>
        </template>
      </template>

      <Button
        v-else-if="isAddPackage"
        class="w-full"
        :label="_t('去社区搜索')"
        @click="onPkgSearch"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import PkgCard from './PkgCard.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { EffectCoverflow } from 'swiper/modules'
import type { Swiper as TSwiper } from 'swiper'
import 'swiper/css'
import { Code } from '@/api/code'
import { AtlasStageMode, preStartAtlasStage } from '@/api/atlas'
import { useCommonStore } from '@/stores'
import { useRouter } from 'vue-router'
import { startAtlasStage } from '@/api/atlas'
import { getPackageShowDate } from '@/utils/package'
import { debugFinishStage } from '@/debug'
import EnergyCount from '@/components/EnergyCount.vue'
import { PackageAccess, type PackageBasic } from '@/api/package-source'
import ExpInfo from '@/components/ExpInfo.vue'
import Button from '@/components/Button.vue'
import PkgSearch from '@/components/pkg-search/PkgSearch.vue'
import PackageUnlock from '@/components/PackageUnlock.vue'
import { NSwitch } from 'naive-ui'
import zindexMgmt from '@/utils/zindexMgmt'
import { showEnergyDialog } from '@/shared'

const store = useCommonStore()
const router = useRouter()

const props = defineProps<{
  stageLevel: number
  lastPkgId?: number
}>()

const emit = defineEmits<{
  stageCompleted: []
  done: [pkgId?: number]
}>()

const preStageInfo = ref<{
  packages: PackageBasic[]
  packageExpMap: Record<number, number>
  requiredEnergy: number
}>({
  packages: [],
  requiredEnergy: 0,
  packageExpMap: {},
})

const activeIndex = ref(0)
const loading = ref(true)
const stageLoading = ref(false)
const swiperController = ref<TSwiper>()
const showPassionateModeGuide = ref(false)

// 狂热模式的开启有两个条件，一个是在会员有效期内，一个是开启的狂热按钮
const isPassionateModeEnabled = computed(
  () => store.isVipValid && store.passionateMode
)

const passionateTooltipZindex = computed(() => {
  if (_global.isPcMode) return undefined

  return zindexMgmt.nextToastZIndex()
})

const pkg = computed<PackageBasic | undefined>(
  () => preStageInfo.value.packages[activeIndex.value]
)

const isAddPackage = computed(
  () =>
    activeIndex.value === preStageInfo.value.packages.length && !loading.value
)

async function initSwiperIndex() {
  const index = preStageInfo.value.packages.findIndex(
    pkg => pkg.id === props.lastPkgId
  )

  activeIndex.value = index > -1 ? index : 0
  swiperController.value?.slideTo(activeIndex.value)
}

function isPkgLearnable(pkg: PackageBasic) {
  if (pkg.packageAccess == null && pkg.trailStatus != null) {
    return pkg.trailStatus.trailCount < pkg.trailStatus.trailLimit
  }

  return true
}

function isPkgTrailAllUsed(pkg: PackageBasic) {
  if (pkg.packageAccess == null && pkg.trailStatus != null) {
    return pkg.trailStatus.trailCount >= pkg.trailStatus.trailLimit
  }

  return false
}

function getPkgExp(pkg: PackageBasic) {
  return preStageInfo.value.packageExpMap[pkg.id] ?? 0
}

function onSetSwiper(val: TSwiper) {
  swiperController.value = val
  initSwiperIndex()
}

function onSlideChange(swiper: TSwiper) {
  activeIndex.value = swiper.activeIndex
}

async function onDebugFinishStage() {
  if (!pkg.value) return

  const result = await debugFinishStage(pkg.value.id)

  if (result) {
    store.fetchStatsOverview()
    store.fetchEnergyStatus()
    _message.success('关卡已完成')
    emit('stageCompleted')
    emit('done', pkg.value.id)
  }
}

function onPackageUnlock(pkg: PackageBasic) {
  _presentContent(PackageUnlock, {
    rootClass: 'min-w-400px max-w-600px',
    props: {
      package: pkg,
      exitAfterUnlock: true,
      onUnlock(access: PackageAccess) {
        pkg.packageAccess = access
      },
    },
    dialog: {
      pt: {
        content: {
          style: 'padding: 0px;',
        },
      },
    },
  })
}

function onStageStart(pkg: PackageBasic) {
  if (stageLoading.value) return

  stageLoading.value = true

  startAtlasStage(
    pkg.id,
    isPassionateModeEnabled.value
      ? AtlasStageMode.PASSIONATE
      : AtlasStageMode.NORMAL
  )
    .then(async res => {
      if (res.code === Code.NotEnoughEnergy) {
        _message.info('面包不足')

        // 由于 tooltip 的 z-index 较高，所以这里当面包弹窗出现的时候先隐藏
        // 关闭面包弹窗时再显示
        const showGuide = showPassionateModeGuide.value
        showPassionateModeGuide.value = false
        showEnergyDialog(true).finally(() => {
          showPassionateModeGuide.value = showGuide
        })
        return
      } else if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      store.increaseEnergy(-preStageInfo.value.requiredEnergy)

      store.setStageUnit({
        unitId: res.data.unitId,
        atlasStageId: res.data.stageId,
        schedules: res.data.schedules,
      })

      emit('done', pkg.id)
      router.push({
        name: 'unit/learn',
      })
    })
    .finally(() => {
      stageLoading.value = false
    })
}

function onClose() {
  emit('done')
}

function onPkgAddClick() {
  if (isAddPackage.value) {
    onPkgSearch()
  } else {
    onPkgClick(preStageInfo.value.packages.length)
  }
}

function onPkgClick(index: number) {
  activeIndex.value = index
  swiperController.value?.slideTo(activeIndex.value)
}

function onPkgSearch() {
  _presentContent(PkgSearch, {
    rootClass: 'h-680px w-480px',
    bottomSheetClass: 'h-85vh',
    props: {
      isPage: false,
      navigator: _global.isPcMode ? 'modal' : 'bottomSheet',
      onPackagesUpdate(pkgId?: number) {
        fetchUserPackages().then(() => {
          const index = preStageInfo.value.packages.findIndex(
            pkg => pkg.id === pkgId
          )
          swiperController.value?.slideTo(
            index > -1 ? index : preStageInfo.value.packages.length
          )
        })
      },
      onPackageChallenge(pkgId: number) {
        _closeActiveDialog()

        const index = preStageInfo.value.packages.findIndex(
          pkg => pkg.id === pkgId
        )

        activeIndex.value = index > -1 ? index : 0
        swiperController.value?.slideTo(activeIndex.value)
      },
    },
    dialog: {
      pt: {
        content: {
          style: 'padding: 0px;',
        },
      },
    },
  })
}

async function fetchUserPackages() {
  loading.value = true
  try {
    const res = await preStartAtlasStage(props.stageLevel)

    preStageInfo.value.requiredEnergy = res.requiredEnergy
    preStageInfo.value.packages = res.packageList.packages.sort(
      (a, b) => getPackageShowDate(b) - getPackageShowDate(a)
    )
    preStageInfo.value.packageExpMap = res.expMap

    initSwiperIndex()
  } finally {
    loading.value = false
  }
}

function passionateSwitchRailStyle({ checked }: { checked: boolean }) {
  return {
    boxShadow: 'none',
    backgroundColor: checked ? 'var(--rose-500)' : 'var(--ld-switchSecondary)',
  }
}

function onPassionateModeChange(val: boolean) {
  store.setAtlasLearnMode(val)

  // 如果开启了狂热模式且此时正在提示引导信息，则表示引导完成
  if (val && showPassionateModeGuide.value) {
    showPassionateModeGuide.value = false
    store.completePassionateModeGuide()
  }
}

onMounted(async () => {
  await fetchUserPackages()

  const currentEnergy = store.energy?.currentEnergy ?? 0

  // if （面包不足以完成一个关卡）&（未开启狂热学习开关）&（免费补充面包次数已用完）&（未完成过狂热学习引导提示）：
  // 显示气泡引导提示
  if (
    isPassionateModeEnabled.value &&
    currentEnergy < preStageInfo.value.requiredEnergy &&
    store.vipEnergyFreeTimes === 0 &&
    !store.isPassionateModeGuideCompleted
  ) {
    showPassionateModeGuide.value = true
  }
})
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
  height: 24px;
  margin-top: 12px;
}

.pkg-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, var(--gray-100) 0%, var(--gray-300) 100%);
  border: 3px solid var(--gray-700);
  border-style: dashed;
  border-radius: 16px;
  width: 130px;
  height: 165px;
}
</style>
