import Axios, { type AxiosInstance } from 'axios'
import { Howl } from 'howler'
import { last } from 'lodash-es'
import {
  KB,
  MB,
  readBlobUrlAsArrayBuffer,
  resolveAudioPath,
  resolveLottiePath,
} from './utils'
import { resolveImgPath } from './utils/img'

// 优先使用 name
export type AssetImg = {
  type: 'img'

  name?: string

  assetId?: string
  imgStyle?: string
}

// 本地 lottie 文件
export type AssetLottie = {
  type: 'lottie'
  name: string
}

// 本地音频文件
export type AssetAudio = {
  type: 'audio'
  name: string
}

// 有道发音
export type AssetPron = {
  type: 'pron'
  text: string
}

export type Asset = AssetImg | AssetLottie | AssetAudio | AssetPron

// 资源用来做缓存的 key，应该是要加载的资源的路径，可以完整的 url，也可以是 path
// eg: https://role-1.png
//     /audio/public_hit.MP3
//     https://dict.youdao.com/dictvoice?audio=hello
//     @/assets/lottie/lesson-end.json
type AssetUrl = string

type BlobCacheType = 'img' | 'audio'
type BlobCache = Map<
  AssetUrl,
  {
    // objectURL
    objectUrl: string
    size: number // in bytes
    accessedAt: number
    key: string

    // eg: image/png, audio/mp3
    type: string
  }
>

const YOUDAO_HOST = 'https://dict.youdao.com'

export class AssetsManager {
  constructor(options?: {
    // size in bytes
    maxAudioCache?: number
    api?: AxiosInstance
    maxImgCache?: number
  }) {
    this.maxCacheSize.audio = options?.maxAudioCache ?? 20 * MB
    this.maxCacheSize.img = options?.maxImgCache ?? 20 * MB
    this.api =
      options?.api ??
      Axios.create({
        timeout: 5000,
      })
  }

  maxCacheSize = {
    audio: 0,
    img: 0,
  }
  cacheSize = {
    audio: 0,
    img: 0,
  }

  private api: AxiosInstance

  // lottie 缓存，value 为 lottie json 数据
  private lottieCache: Map<AssetUrl, object> = new Map()

  // blob缓存，这个可以被清除，有上限设置
  private cache: { audio: BlobCache; img: BlobCache } = {
    audio: new Map(),
    img: new Map(),
  }

  // 存储资源加载中的 promise，防止同时加载多次
  private preloadLoaders: Map<AssetUrl, Promise<void>> = new Map()

  // 本地音频和发音音频分成两个，各自同时只能有一个正在播放的声音
  // 但是本地音频可以和发音音频一起播放，比如答对时的场景会同时播放答对音效以及正确选项的发音
  private audioSound: Howl | null = null
  private pronSound: Howl | null = null

  async preload(assets: Asset[]): Promise<void> {
    const loaders: Promise<void>[] = []

    for (const asset of assets) {
      const url = this.getAssetUrl(asset)
      const existLoader = this.preloadLoaders.get(url)

      // 如果是正在加载中，则返回正在加载的 promise 即可
      if (existLoader) {
        loaders.push(existLoader)
        continue
      }

      switch (asset.type) {
        case 'img':
          loaders.push(this.preloadImg(asset))
          break
        case 'lottie':
          loaders.push(this.preloadLottie(asset))
          break
        case 'audio':
        case 'pron':
          loaders.push(this.preloadAudioOrPron(asset))
          break
      }
    }

    await Promise.allSettled(loaders)
  }

  playAudio(name: string): Promise<void> {
    if (_store.soundFeedbackOn) {
      return this._audioPlay({ type: 'audio', name }, 'audioSound')
    }
    return Promise.resolve()
  }
  playPron(text: string): Promise<void> {
    return this._audioPlay({ type: 'pron', text }, 'pronSound')
  }

  getLottie(name: string): any {
    const url = this.getAssetUrl({ type: 'lottie', name })
    return this.lottieCache.get(url)
  }

  getImageAssetId(assetId: string, imgStyle?: string): string | undefined {
    return this.getImage({ type: 'img', assetId, imgStyle })
  }
  getImageName(name: string): string | undefined {
    return this.getImage({ type: 'img', name })
  }

  private getImage(asset: AssetImg) {
    const url = this.getAssetUrl(asset)
    const cache = this.cache.img.get(url)

    if (cache == null) {
      return
    }

    this.updateAccessTime(url, 'img')

    return cache.objectUrl
  }

  private async _audioPlay(
    asset: AssetAudio | AssetPron,
    objName: 'pronSound' | 'audioSound'
  ): Promise<void> {
    const url = this.getAssetUrl(asset)
    const cache = this.cache.audio.get(url)

    // 如果没有找到缓存，则直接结束
    if (cache == null) {
      return
    }

    this.updateAccessTime(url, 'audio')

    return new Promise<void>(resolve => {
      this[objName]?.unload()

      // blob type 格式为 audio/mp3、audio/wav，这里只需要取出后面的格式即可
      const ext = last(cache.type.split('/')) as string
      this[objName] = new Howl({
        src: [cache.objectUrl],
        html5: true,
        format: [ext],
      })

      async function reportSentry(msg: string) {
        const bytes = await readBlobUrlAsArrayBuffer(cache!.objectUrl, 100 * KB)

        _reportError({
          msg,
          url,
          cacheSize: cache!.size,
          attachments: [
            { filename: `pron.${ext}`, data: bytes, contentType: cache!.type },
          ],
        })
      }

      this[objName].once('loaderror', () => {
        reportSentry('audio load error')
        resolve()
      })
      this[objName].once('playerror', () => {
        reportSentry('audio play error')
        resolve()
      })

      // 如果 1s 之内没有开始播放，则认为播放遇到了问题，直接返回，避免上层无限等待
      let played = false
      this[objName].once('play', () => {
        played = true
      })
      setTimeout(() => {
        if (!played) {
          reportSentry('audio not play after 1 second.')
          resolve()
        }
      }, 1000)

      this[objName].play()

      this[objName].once('end', () => resolve())
    })
  }

  // 返回资源的 path/url
  private getAssetUrl(asset: Asset): AssetUrl {
    switch (asset.type) {
      case 'img': {
        if (asset.name) {
          const result = resolveImgPath(asset.name)
          _global.assert(
            result != null,
            `image should exist with name ${asset.name}`
          )
          return result!
        }
        _global.assert(
          asset.assetId != null,
          `AssetImage must have assetId if name is null`
        )
        return _global.assetUrl(asset.assetId!, asset.imgStyle)!
      }

      case 'lottie': {
        const result = resolveLottiePath(asset.name)
        _global.assert(
          result != null,
          `lottie should exist with name ${asset.name}`
        )
        return result!
      }

      case 'audio': {
        const result = resolveAudioPath(asset.name)
        _global.assert(
          result != null,
          `audio should exist with name ${asset.name}`
        )
        return result!
      }

      case 'pron':
        // 这里的 type 会有「英音」和「美音」的区别，现在都是用的美音
        // 0 为美音 1 为英音
        const path = `/dictvoice?audio=${encodeURIComponent(asset.text)}&type=0`

        // 如果是在 app 中，则直接使用有道的地址
        if (_global.isInsideApp) {
          return `${YOUDAO_HOST}${path}`
        }

        return `/youdaovoice${path}`
    }
  }

  private async preloadImg(img: AssetImg): Promise<void> {
    const url = this.getAssetUrl(img)

    if (this.cache.img.has(url)) {
      this.updateAccessTime(url, 'img')
      return
    }

    const loader = this.fetchBlob(url, 'image/jpeg')
      .then(blob => {
        this.addBlobToCache(url, blob, 'img')
      })
      .finally(() => {
        // 加载完成时清除正在加载中的 promise，不论成功还是失败
        this.preloadLoaders.delete(url)
      })

    this.preloadLoaders.set(url, loader)

    return loader
  }

  private async preloadLottie(lottie: AssetLottie): Promise<void> {
    const url = this.getAssetUrl(lottie)

    // 如果已经有缓存，则直接返回，不需要再次加载
    if (this.lottieCache.get(url)) {
      return
    }

    const loader = this.fetchJSON(url)
      .then(data => {
        this.lottieCache.set(url, data)
      })
      .finally(() => {
        // 加载完成时清除正在加载中的 promise，不论成功还是失败
        this.preloadLoaders.delete(url)
      })

    this.preloadLoaders.set(url, loader)

    return loader
  }

  private async preloadAudioOrPron(asset: AssetPron | AssetAudio) {
    const url = this.getAssetUrl(asset)

    // 如果已经有缓存，则直接返回，不需要再次加载
    if (this.cache.audio.get(url)) {
      this.updateAccessTime(url, 'audio')
      return
    }

    const ext =
      asset.type === 'pron' ? 'mpeg' : last(url.split('.'))!.toLowerCase()

    const loader = this.fetchBlob(url, 'audio/' + ext)
      .then(blob => this.addBlobToCache(url, blob, 'audio'))
      .finally(() => {
        // 加载完成时清除正在加载中的 promise，不论成功还是失败
        this.preloadLoaders.delete(url)
      })

    this.preloadLoaders.set(url, loader)

    return loader
  }

  private fetchBlob(url: string, mime: string): Promise<Blob> {
    const isYoudaoUrl = url.startsWith(YOUDAO_HOST)

    // 如果是访问有道发音的 url 且在 app 中，则使用 bridge 的 fetch 方法来获取音频
    // 减轻服务器的压力
    if (isYoudaoUrl && _global.isInsideApp) {
      return _bridge
        .fetch(url, {
          responseType: 'bytes',
          headers: {
            'Content-Type': mime,
          },
        })
        .then((data: any) => {
          // 检查一下 response header 的  content-type
          return new Blob([new Uint8Array(data).buffer], {
            type: mime,
          })
        })
    }

    return this.api
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': mime,
        },
        timeout: 5000,
      })
      .then(res => {
        return new Blob([res.data], {
          type: mime,
        })
      })
  }
  private fetchJSON(url: string): Promise<any> {
    return this.api
      .get(url, {
        responseType: 'json',
        timeout: 5000,
      })
      .then(res => res.data)
  }

  private addBlobToCache(url: string, blob: Blob, type: BlobCacheType) {
    const objectURL = URL.createObjectURL(blob)
    this.cacheSize[type] += blob.size
    this.cache[type].set(url, {
      objectUrl: objectURL,
      key: url,
      size: blob.size,
      accessedAt: Date.now(),
      type: blob.type,
    })

    // 缓存超出上限，则清理一波缓存
    if (this.cacheSize[type] > this.maxCacheSize[type]) {
      this.clearCache(type, this.maxCacheSize[type] / 2)
    }
  }
  private updateAccessTime(url: string, type: BlobCacheType) {
    const cache = this.cache[type].get(url)
    if (cache) {
      cache.accessedAt = Date.now()
    }
  }
  private clearCache(type: BlobCacheType, targetSize = 1 * MB) {
    let clearedSize = 0

    // 按照访问时间从小到大排序
    const cached = [...this.cache[type].values()].sort(
      (a, b) => a.accessedAt - b.accessedAt
    )

    for (const item of cached) {
      URL.revokeObjectURL(item.objectUrl)
      this.cache[type].delete(item.key)
      clearedSize += item.size
      if (clearedSize >= targetSize) {
        break
      }
    }

    this.cacheSize[type] -= clearedSize
  }
}

const singleton = new AssetsManager()

export default singleton
