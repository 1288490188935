<template>
  <div class="bg-[var(--surface-hover)] h-full">
    <TagManagement
      :followed-content-tags="[]"
      @done="done"
    />
  </div>
</template>

<script setup lang="ts">
import TagManagement from '@/components/TagManagement/TagManagement.vue'
import router from '../router'
function done() {
  router.back()
}
</script>

<style scoped></style>
