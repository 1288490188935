<template>
  <div class="p-4 flex flex-col items-center">
    <div class="flex items-center gap-2">
      等级：
      <select
        v-model="selectedRank"
        class="w-100px h-30px"
      >
        <option
          v-for="type in allRankTypes"
          :key="type"
          :value="type"
        >
          {{ getRankTitle({ type }) }}
        </option>
      </select>

      星数（仅王者有效）：
      <input
        v-model="selectedRankStar"
        type="number"
        class="w-60px"
      />
    </div>

    <RankList :currentRank="currentRank" />

    <RankIcon
      :rank="currentRank"
      :class="['w-175px h-175px mb-8']"
      :starFontWidth="18"
    />

    <div class="flex items-center gap-4">
      之前名次：
      <InputNumber v-model="beforeRankPosition" />
      之后名次：
      <InputNumber v-model="afterRankPosition" />
      <Button
        label="重置动画"
        @click="key++"
      />
    </div>

    <div v-if="afterRankPosition >= beforeRankPosition">
      之前名次必须大于之后的名次
    </div>

    <div
      v-else
      :key="key"
      class="w-375px h-700px overflow-hidden border border-1 border-solid"
    >
      <RankUpView
        :afterRank="afterRankGroup"
        :beforeRank="beforeRankGroup"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { RankHomeStatus, RankType, type RankHomeInGroup } from '@/types/rank'
import RankUpView from '../UnitLearn/RankUpView.vue'
import { computed, ref } from 'vue'
import InputNumber from 'primevue/inputnumber'
import RankList from '../Rank/RankList.vue'
import RankIcon from '@/components/RankIcon.vue'
import { getRankTitle } from '../Rank/util'

const allRankTypes = Object.values(RankType)

const beforeRankPosition = ref(12)
const afterRankPosition = ref(4)
const key = ref(0)

const selectedRank = ref<RankType>(RankType.Legendary)
const selectedRankStar = ref(10)

const commonRankData = computed<RankHomeInGroup['data']>(() => {
  let rankString = ''

  if (selectedRank.value === RankType.Legendary) {
    rankString =
      selectedRankStar.value > 0
        ? `${selectedRank.value}_${selectedRankStar.value}`
        : selectedRank.value
  } else {
    rankString = selectedRank.value
  }

  return {
    groupRank: rankString,
    seasonEndTime: 'xxxxx',
    position: 1,
    upCount: 5,
    up2Count: 1,
    down1Index: 6,
    down1Rank: RankType.Bronze,

    users: Array.from({ length: 30 }).map((_, i) => {
      return {
        userId: i.toString(),
        nickname: ['测试用户一二三四五六七八', 'Absasdf', '布丁', '测试用户'][
          i % 4
        ],
        exp: 200 - i * 4,
        avatar: [
          'usr/50223/img/3.580.370.jpg',
          'usr/50223/img/4.672.504.jpeg',
          'usr/50223/img/5.265.291.jpg',
          'usr/50223/img/7.492.700.jpeg',
        ][i % 4],
      }
    }),
  }
})

const currentRank = computed(() => {
  if (selectedRank.value === RankType.Legendary) {
    return {
      type: selectedRank.value,
      star: selectedRankStar.value,
    }
  }
  return {
    type: selectedRank.value,
  }
})

const beforeRankGroup = computed<RankHomeInGroup>(() => {
  return {
    type: RankHomeStatus.InGroup,
    data: {
      ...commonRankData.value,
      position: beforeRankPosition.value,
    },
  }
})

const afterRankGroup = computed<RankHomeInGroup>(() => {
  return {
    type: RankHomeStatus.InGroup,
    data: {
      ...commonRankData.value,
      position: afterRankPosition.value,
    },
  }
})
</script>
<style scoped></style>
