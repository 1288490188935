<template>
  <Message
    v-if="parsed.error != null"
    :closable="false"
    severity="error"
  >
    卡片解析失败: {{ parsed.error }}
  </Message>

  <div
    v-else
    class="h-full flex flex-col flex-1 overflow-y-auto"
  >
    <div class="text-4xl font-bold mb-4px">{{ parsed.card.word }}</div>

    <div class="text-sm text-gray-500 space-x-4 flex mb-4px">
      <div
        v-for="(e, i) in parsed.card.prons"
        :key="i"
      >
        {{ e.label }}
      </div>
    </div>

    <div class="text-xl mb-8px">
      {{ parsed.card.definition }}
    </div>

    <Carousel
      v-if="parsed.card.examples.length > 0"
      :value="parsed.card.examples"
      circular
    >
      <template #item="value">
        <div class="flex flex-col gap-2">
          <div>{{ value.data.source }}</div>
          <div>{{ value.data.translation }}</div>
        </div>
      </template>
    </Carousel>

    <div
      v-if="parsed.card.tabs.length > 0"
      class="flex flex-col gap-2 mt-4"
    >
      <TabView>
        <TabPanel
          v-for="(e, i) in parsed.card.tabs"
          :key="i"
          :header="e.title"
        >
          <Content :content="e.content"></Content>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Carousel from 'primevue/carousel'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import Content from '@/components/card-render/Content.vue'
import { parseEnWordCard } from '@/utils/card'
import Message from 'primevue/message'

import type { EnWordCard } from '@/types/core'

const props = defineProps<{
  card: EnWordCard
}>()

const parsed = parseEnWordCard(props.card)
</script>
<style scoped></style>
