import type { OperationLayout } from '@/types/core'
import type { Option } from '../ClozeCardFace/faces/Choice/helper'
import { isClozeTextTooLong } from '@/utils/card'

export function getOperationLayoutClass(
  layout: OperationLayout,
  options: Option[]
) {
  switch (layout) {
    case 'grid': {
      const hasTooLongOption = options.some(item =>
        isClozeTextTooLong(item.text)
      )
      if (hasTooLongOption) {
        return 'operation-vertical'
      }

      return 'operation-grid'
    }
    case 'horizontal': {
      return 'operation-horizontal'
    }
    case 'vertical': {
      return 'operation-vertical'
    }
  }
}
