<template>
  <main class="p-4">
    <h1 class="font-bold text-xl mb-4">IconBg 组件，已经全局注册</h1>

    <p>内容通过 slot 传入，同时 IconBg 需要设置一个宽高</p>
    <p>
      Icon 作为“背景“始终在相应容器中居中，类似于 CSS background 的 "cover" 效果
    </p>
    <p>IconBg 组件开启了 Flex 样式，默认 slot 内容会居中显示</p>

    <div class="vstack mt-4">
      <IconBg
        name="logo"
        class="w-150px h-50px border"
      >
        <div class="text-red-400">hello world</div>
      </IconBg>

      <IconBg
        name="logo"
        class="w-50px h-150px border"
      />

      <IconBg
        name="logo"
        class="w-150px h-150px border"
      />

      <IconBg
        name="energy"
        class="w-100px h-100px"
      >
        <div class="text-white">10</div>
      </IconBg>
    </div>
  </main>
</template>

<script lang="ts" setup></script>

<style scoped></style>
