export const APPID = 'wx8c050b3c2831f61c'

export function wechatLogin() {
  // prod wechat login link: https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8c050b3c2831f61c&redirect_uri=https%3A%2F%2Fm.lingduck.cn%2Fwxcallback&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect
  const prefix = _global.prodGatewayPrefix
  const redirectURL =
    location.protocol +
    `//m.lingduck.cn${prefix}/wxcallback?host=${encodeURIComponent(
      location.host
    )}`
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(
    redirectURL
  )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
  location.replace(url)
}

// 回跳到当前 URL
// 该函数只负责获取 openId，所以在回跳到 wxcallback 时通过携带 login=false
// 避免自动登录微信账号
export function wechatRedirect() {
  const prefix = _global.prodGatewayPrefix
  const redirectURL =
    location.protocol +
    `//m.lingduck.cn${prefix}/wxcallback?redirect=${encodeURIComponent(
      location.pathname + location.search
    )}&login=false&host=${encodeURIComponent(location.host)}`
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(
    redirectURL
  )}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  location.replace(url)
}

export function openWechatApp() {
  if (_global.isInsideApp) {
    _bridge.launch('weixin://')
  } else {
    window.location.href = 'weixin://'
  }
}

const TEMPLATE_ID = 'fbxqdgRjnyez_I5NK_3pTB5ph8qC23VNZJmN6FQdlhI'

export const ALPHA_CREATOR_APPROVED_SCENE = 1000

// 跳转微信一次性订阅消息授权，其中模板 id 固定不变，在公众号接口权限中查看
// scene 参数为 0-10000 的正整数，用于区分不同的使用场景
//  eg: 1000 为发送审核通过通知， 1001 为发送审核失败通知，等等。
// redirectPath 参数为回调的 pathname
//  eg: /beta-feature?name=alphaCreator、/atlas
export function wechatSubscribeRedirect(
  scene: number,
  redirectPath: string,
  // search 本身含有 `?` 号
  search: string
) {
  const prefix = _global.prodGatewayPrefix
  if (search !== '') {
    search += `&host=${encodeURIComponent(location.host)}`
  } else {
    search = `?host=${encodeURIComponent(location.host)}`
  }

  const redirectURL =
    location.protocol + `//m.lingduck.cn${prefix}${redirectPath}${search}`

  const url = `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${APPID}&scene=${scene}&template_id=${TEMPLATE_ID}&redirect_url=${encodeURIComponent(
    redirectURL
  )}#wechat_redirect`

  location.replace(url)
}
