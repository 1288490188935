import {
  type Card,
  CardType,
  type ClozeCard,
  type EnWordCard,
} from '@/types/core'
import { tryJSONParse, bossList, getBossAvatar, getBossHalf } from '@/utils'
import { parseClozeCard, parseEnWordCard } from '@/utils/card'
import type { Asset } from '@/assets-manager'

// 角色数量
export const ROLE_COUNT = 9
// 送分角色数量
export const GIVE_AWAY_ROLE_COUNT = 3

const lotties = [
  'star',
  'sparkle',
  'button-sparkle0',
  'button-sparkle1',
  'check-mark',
  'chest-drop',
  'scatter-flowers',
  'flame0',
  'flame1',
  'lesson-end',
  'knowledge_get',
]
const audios = [
  'lesson_end',
  'public_correct',
  'public_incorrect',
  'public_hit',
  'compress-monster_cry',
]

export async function preLoadCards(cards: string[]): Promise<number> {
  const assets: Asset[] = []

  // lottie
  lotties.forEach(name => {
    assets.push({ type: 'lottie', name })
  })

  // 先 load 本地资源，人物图像，怪物图像
  // boss 形象 有三种 1. boss 2. boss-avatar 3. boss-half
  bossList.forEach(boss => {
    assets.push({ type: 'img', name: boss.name })
    assets.push({
      type: 'img',
      name: getBossAvatar(boss.name),
    })
    assets.push({
      type: 'img',
      name: getBossHalf(boss.name),
    })
  })

  // 加载人物图像，这里是送分题专属的图像
  Array.from({ length: GIVE_AWAY_ROLE_COUNT })
    .map((_, i) => i + 1)
    .forEach(id => {
      assets.push({
        type: 'img',
        name: `give-away-role-${id}`,
      })
    })

  // 加载普通人物图像
  Array.from({ length: ROLE_COUNT })
    .map((_, i) => i + 1)
    .forEach(id => {
      assets.push({
        type: 'img',
        name: `role-${id}`,
      })
    })

  // 加载卡片中的图片
  for (const card of cards) {
    const cardObject = tryJSONParse(card) as Card | null

    // TODO: 目前只处理知识点卡片中的图片
    if (cardObject?.type === CardType.CLOZE) {
      const parsedClozeCard = parseClozeCard(cardObject)
      if (parsedClozeCard.error == null) {
        assets.push(...getParsedClozeCardAssets(parsedClozeCard.card))
      }
    }

    if (cardObject?.type === CardType.EN_WORD) {
      const parsedWordCard = parseEnWordCard(cardObject)
      if (parsedWordCard.error == null) {
        assets.push(...getParsedWordCardImageAssets(parsedWordCard.card))
      }
    }
  }

  // 加载学习需要的音频
  audios.forEach(name => {
    assets.push({ type: 'audio', name })
  })

  // 加载其他图片
  assets.push({ type: 'img', name: 'new_knowledge' })

  await _am.preload(assets)

  return assets.length
}

function getParsedClozeCardAssets(card: ClozeCard): Asset[] {
  const assets: Asset[] = []

  if (card.illustration) {
    assets.push({
      type: 'img',
      assetId: card.illustration,
    })
  }

  return assets
}

function getParsedWordCardImageAssets(card: EnWordCard): Asset[] {
  const assets: Asset[] = []

  if (card.illustration) {
    assets.push({
      type: 'img',
      assetId: card.illustration,
    })
  }

  // 加载单词发音
  assets.push({ type: 'pron', text: card.word })

  // 加载例句发音
  card.examples.forEach(item => {
    assets.push({ type: 'pron', text: item.source })
  })

  // 加载干扰项中的发音
  card.distrators.forEach(item => {
    assets.push({ type: 'pron', text: item.word })
  })

  return assets
}
