import api from './base'

import type { CommonResponse } from './base'
import type { EnergyResponse } from './user'

export type Payment_Vendor =
  | 'ALIPAY_WEB'
  | 'WX_PAY_JSAPI'
  | 'ALIPAY_APP'
  | 'APPLE_PAY'

export type WxPayJsApiParam = {
  openId: string
}

export function createPayment(
  orderId: string,
  paymentVendor: Payment_Vendor,
  wxPayJsApiParam?: WxPayJsApiParam
) {
  return api.post<
    unknown,
    CommonResponse<{
      ALIPAY_WEB: {
        form: string
      }
      WX_PAY_JSAPI: {
        signType: string
        paySign: string
        appId: string
        packageValue: string
        timeStamp: string
        nonceStr: string
      }
      ALIPAY_APP: {
        sign: string
      }
    }>
  >('/v1/payment', {
    orderId,
    paymentVendor,
    wxPayJsApiParam,
  })
}

export function createAliPayment(orderId: string) {
  return createPayment(orderId, 'ALIPAY_WEB')
}
export function createAliPaymentInApp(orderId: string) {
  return createPayment(orderId, 'ALIPAY_APP')
}

export function createWxJsApiPayment(orderId: string, openId: string) {
  return createPayment(orderId, 'WX_PAY_JSAPI', { openId })
}

// 苹果支付回调
export function verifyApplePay(orderId: string, transactionId: string) {
  // 交易成功后，客户端将交易凭证发送到服务端，服务端向苹果服务器验证交易凭证

  return api.post<unknown, CommonResponse<never>>(
    '/v1/payment/apple-pay/notify',
    {
      orderId,
      transactionId,
    }
  )
}

export enum OrderStatus {
  CREATED = 'CREATED',
  DELIVERED = 'DELIVERED',
}

export type Order = {
  orderId: string
  amount: number
  status: OrderStatus
  currency: string
  createdAt: string
  deliveredAt: string
}

export function fetchOrders() {
  return api.get<unknown, { orders: Order[] }>('/v1/orders')
}

export function pollOrder(orderId: string) {
  return api.get<unknown, { order: Order | null }>(`/v1/orders/${orderId}`)
}

export function createVipOrder(productId: number) {
  return api.post<unknown, CommonResponse<Order>>('/v1/orders/vip', {
    productId,
  })
}

export function createDiamondOrder(productId: number) {
  return api.post<unknown, CommonResponse<Order>>('/v1/orders/diamond', {
    productId,
  })
}

export function createDiamondCardOrder(productId: number) {
  return api.post<unknown, CommonResponse<Order>>('/v1/orders/diamond-card', {
    productId,
  })
}

export interface DiamondExchangeConfig {
  configId: number
  diamond: number
  energy: number
}

export function fetchDiamondExchangeConfigs() {
  return api.get<unknown, { items: DiamondExchangeConfig[] }>(
    '/v1/diamonds/energy-exchange'
  )
}

export function exchangeEnergy(configId: number) {
  return api.post<unknown, CommonResponse<EnergyResponse>>(
    '/v1/diamonds/energy-exchange',
    { configId }
  )
}

export function exchangeEnergyLimit() {
  return api.post<unknown, CommonResponse<EnergyResponse>>(
    '/v1/diamonds/energy-limit-exchange'
  )
}
