import { RankType, type Rank } from '@/types/rank'

export function getRankName(type: RankType) {
  return {
    [RankType.Bronze]: _t('不屈青铜'),
    [RankType.Silver]: _t('坚韧白银'),
    [RankType.Gold]: _t('荣耀黄金'),
    [RankType.Platinum]: _t('卓越白金'),
    [RankType.Emerald]: _t('璀璨翡翠'),
    [RankType.Diamond]: _t('无瑕钻石'),
    [RankType.Legendary]: _t('传奇王者'),
  }[type]
}

export function getRankTitle(rank: Rank) {
  const rankName = getRankName(rank.type)

  if (rank.type === RankType.Legendary && rank.star! > 1) {
    return `${rankName}⭐️${rank.star}`
  }

  return rankName
}

export function parseRank(rankString: string): Rank {
  const reg = /^([A-Z]+)(\_\d+)?$/

  const match = rankString.match(reg)

  if (match == null) {
    throw 'invalid rank string'
  }

  const rank: Rank = {
    type: match[1] as RankType,
  }

  if (match[2] != null) {
    rank.star = parseInt(match[2].slice(1))
  }

  return rank
}
