<template>
  <div class="flex flex-col gap-4">
    <div
      v-for="task in props.tasks"
      :key="task.goalType + task.rewardClaimed.toString()"
      class="daily-task"
    >
      <template v-if="task.rewardClaimed">
        <div class="flex items-center opacity-50">
          <Icon
            name="task-done"
            class="mr-4 w-44px"
          />
          <span class="text-ld-brand-500 text-19px font-semibold line-through">
            {{ task.title }}
          </span>
        </div>
      </template>

      <template v-else>
        <span
          class="text-44px w-44px h-44px flex items-center justify-center mr-4"
        >
          {{ task.emoji }}
        </span>

        <div class="flex-1">
          <div class="text-ld-text text-19px font-semibold mb-1">
            {{ task.title }}
          </div>

          <div class="relative">
            <ProgressBar
              :percent="getDailyTextProgress(task)"
              :text="`${getTaskProgress(task)} / ${task.total}`"
              :highlight="false"
              style="width: 96%"
              class="h-20px"
            />

            <div
              class="absolute right-[2%] bottom-0 flex"
              :class="{ 'cursor-pointer': !props.isEndView }"
              @click="onDailyTaskClick(task)"
            >
              <RewardBoxIcon
                :animation="isDailyTaskAvaiable(task) && !props.isEndView"
                class="w-40px h-35px"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  receiveTaskReward,
  type DailyTask,
  TaskType,
  GoalType,
} from '@/api/task'
import { useCommonStore } from '@/stores'
import ProgressBar from '@/components/ProgressBar.vue'
import RewardBoxIcon from '@/components/RewardBoxIcon.vue'

const store = useCommonStore()

const props = withDefaults(
  defineProps<{
    tasks: DailyTask[]
    stats: Record<GoalType, number>
    isEndView?: boolean
  }>(),
  {
    isEndView: false,
  }
)

const emit = defineEmits<{
  claimed: [number]
}>()

function isDailyTaskAvaiable(task: DailyTask) {
  const progress = getTaskProgress(task)
  return progress >= task.total && !task.rewardClaimed
}

function getDailyTextProgress(task: DailyTask) {
  const progress = getTaskProgress(task)

  return Math.min((progress / task.total) * 100, 100)
}

function getTaskProgress(task: DailyTask): number {
  const progress = props.stats[task.goalType] ?? 0
  return Math.min(progress, task.total)
}

// 领奖接口正在 loading
let isClaimLoading = false
function onDailyTaskClick(task: DailyTask) {
  if (props.isEndView) {
    return
  }

  // 已领奖
  if (task.rewardClaimed) {
    _message.info('已领取过该奖励')
    return
  }

  // 还不能领奖
  if (!isDailyTaskAvaiable(task)) {
    return
  }

  // 领奖中
  if (isClaimLoading) {
    return
  }

  isClaimLoading = true
  receiveTaskReward(
    {
      taskNo: task.taskNo,
      type: task.newbie ? TaskType.NEWBIE : TaskType.DAILY,
    },
    store
  )
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      emit('claimed', task.taskNo)
      task.rewardClaimed = true
      _showRewards(task.rewards)
    })
    .finally(() => {
      isClaimLoading = false
    })
}
</script>
<style scoped>
.daily-task {
  height: 100px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 4px 0px var(--slate-300);
  border-radius: 8px;
  padding: 0px 16px;
}
</style>
