<template>
  <div
    ref="containerRef"
    class="bg-ld-background p-4 flex-1 flex flex-col overflow-hidden"
  >
    <div class="p-3 rounded-8px relative">
      <Icon
        name="close-circle"
        class="cursor-pointer w-25px absolute right-0 top-0"
        @click="emit('done')"
      ></Icon>

      <div
        class="cursor-pointer"
        @click="onWordPlay"
      >
        <div class="font-semibold text-24px leading-[1] mb-1">
          {{ props.enWord.spelling }}
        </div>

        <div class="flex items-center">
          <span
            v-if="usPron"
            class="text-17px text-ld-label-secondary mr-1"
          >
            {{ usPron.label }}
          </span>

          <Icon
            name="pron"
            class="w-16px text-ld-brand-600"
          />
        </div>
      </div>

      <div class="text-19px leading-[1.5] mt-2">
        {{ props.enWord.explain }}
      </div>
    </div>

    <div
      v-if="sentences.length > 0"
      class="flex flex-col gap-4 mt-4"
    >
      <div
        v-for="(item, i) in highlightedSentences"
        :key="i"
        class="bg-white p-3 rounded-8px flex items-start cursor-pointer"
        @click="onSentencePlay(i)"
      >
        <div class="flex-1 text-17px">
          <div>
            <WordSearchSpan
              v-for="(span, i) of item.highlightedSpans"
              :key="i"
              :class="{
                'text-ld-brand-600 font-semibold': span.highlight,
              }"
              :text="span.text"
            ></WordSearchSpan>
          </div>
          <div>{{ item.translation }}</div>
        </div>

        <Icon
          name="pron"
          class="w-16px text-ld-brand-600 ml-1"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, provide, ref } from 'vue'

import { getHighlightSpans } from '@/utils'
import WordSearchSpan from './WordSearchSpan.vue'
import type { DictEnWord } from '@/api/learn'
import type { AssetPron } from '@/assets-manager'

type Sentence = {
  source: string
  translation: string
}

const props = defineProps<{
  enWord: DictEnWord
}>()

const emit = defineEmits<{
  done: []
}>()

const usPron = computed(() => {
  return {
    label: props.enWord.phoneticUs,
    language: 'en-US',
  }
})

const containerRef = ref<HTMLDivElement>()
provide('cardContainer', containerRef)

function parseSentence(text: string): Sentence | null {
  const match = text.match(/^\[\[(.+)\]\](.+)$/)

  if (match == null) return null

  return {
    source: match[1],
    translation: match[2],
  }
}

const sentences = computed<Sentence[]>(() => {
  return props.enWord.sentences.map(parseSentence).filter(item => item != null)
})

const highlightedSentences = computed(() => {
  return sentences.value.map(item => {
    return {
      highlightedSpans: getHighlightSpans(item.source, props.enWord.spelling),
      translation: item.translation,
    }
  })
})

onInit(() => {
  _am.preload([
    { type: 'pron', text: props.enWord.spelling },
    ...sentences.value.map(
      item =>
        ({
          type: 'pron',
          text: item.source,
        }) as AssetPron
    ),
  ])
})

function onWordPlay() {
  _am.playPron(props.enWord.spelling)
}

function onSentencePlay(index: number) {
  const target = sentences.value[index]
  _am.playPron(target.source)
}
</script>
<style scoped></style>
