<template>
  <div class="g-body-width flex flex-col gap-4 h-full overflow-hidden">
    <div class="max-h-180px overflow-auto">
      <Textarea
        v-model="input"
        class="w-full border-none outline-none shadow-none p-3 bg-white"
        autoResize
        :placeholder="
          _t('请在此输入内容，小灵鸭将为你智能拆分卡片、标记知识点')
        "
        @update:modelValue="onInputChange"
      />
    </div>

    <div class="flex items-center">
      <div
        v-if="input.length > 0"
        :class="['text-17px', isInputOverflow ? 'text-red' : 'text-gray']"
      >
        {{ input.length }}/{{ INPUT_LIMIT }}
        <template v-if="isInputOverflow">{{ _t('(超出字数上限）') }}</template>
      </div>

      <Button
        v-if="isAlreadyGenerated"
        class="ml-auto"
        style="white-space: nowrap"
        :disabled="isInputOverflow || store.aiGenerate.loading"
        scene="secondary"
        @click="onAiGenerateAgain"
      >
        <div class="flex gap-1 text-ld-brand-500">
          <Icon
            name="ai-add-card"
            class="h-20px"
          />
          <div class="text-17px leading-20px">{{ _t('重新生成') }}</div>
        </div>
      </Button>

      <Button
        v-else
        class="ml-auto"
        style="white-space: nowrap"
        :disabled="isInputOverflow || store.aiGenerate.loading"
        @click="onAiGenerate"
      >
        <div class="flex gap-1">
          <Icon
            name="ai-add-card"
            class="h-20px"
          />
          <div class="text-17px leading-20px">{{ _t('AI 生成') }}</div>
        </div>
      </Button>
    </div>

    <div
      ref="resultContainer"
      class="max-h-380px overflow-y-auto flex flex-col gap-4 flex-1"
    >
      <div
        :class="[
          'flex flex-col gap-2 relative shrink-0',
          {
            'min-h-84px': store.aiGenerate.loading,
          },
        ]"
      >
        <AICardItem
          v-for="(keypoint, index) in store.aiGenerate.keypoints"
          :key="keypoint.id"
          :index="index"
          :focused="focusedKeypointId === keypoint.id"
          :content="keypoint.content"
          @update="onKeypointChange(index, $event)"
          @focus="onKeypointFocus(keypoint.id)"
        >
          <template #action>
            <div class="p-1 cursor-pointer">
              <Icon
                name="trash"
                class="text-red w-24px"
                @click="onCardDelete(index)"
              />
            </div>
            <div class="p-1 cursor-pointer">
              <Icon
                name="ai-card-select"
                class="text-green w-24px cursor-pointer"
                @click="onCardSelect(index)"
              />
            </div>
          </template>
        </AICardItem>

        <AIGenLoading
          v-if="store.aiGenerate.loading"
          class="absolute top-0 left-0 w-full h-full"
          @cancel="onAiGenerateCancel"
        />
      </div>

      <div
        v-if="selectedCards.length > 0"
        class="flex flex-col gap-4"
      >
        <Divider
          class="m-0 text-ld-label-secondary"
          :pt="{ content: { class: '!bg-ld-background' } }"
        >
          {{ _t('已选卡片') }}
        </Divider>

        <div class="flex flex-col gap-2">
          <AICardItem
            v-for="(keypoint, index) in selectedCards"
            :id="`keypoint-${keypoint.id}`"
            :key="keypoint.id"
            :index="index"
            :focused="focusedKeypointId === keypoint.id"
            :content="keypoint.content"
            @update="onSelectedKeypointChange(index, $event)"
            @focus="onKeypointFocus(keypoint.id)"
          >
            <template #action>
              <div class="p-1 cursor-pointer">
                <Icon
                  name="ai-card-remove"
                  class="text-red w-24px"
                  @click="onCardUnSelect(index)"
                />
              </div>
            </template>
          </AICardItem>
        </div>
      </div>
    </div>

    <div
      v-if="isAlreadyGenerated"
      class="flex items-center justify-between gap-3"
    >
      <Button
        :label="_t('清空')"
        scene="danger"
        @click="onAiGenerateClear"
      ></Button>

      <Button
        v-if="selectedCards.length > 0 || !store.aiGenerate.loading"
        :loading="isCardsCreating"
        :label="
          selectedCards.length > 0
            ? _t(`添加 ${selectedCards.length} 张卡片到卡包`)
            : _t('全部添加到卡包')
        "
        @click="onCardsCreate"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import Textarea from 'primevue/textarea'
import Divider from 'primevue/divider'
import { ref, computed } from 'vue'
import AIGenLoading from './AIGenLoading.vue'
import AICardItem from './AICardItem.vue'
import { batchCreateCards } from '@/api/package-source'
import { CardCreatedType, type CardResponse } from '@/api/package-source'
import { toAiInput } from '@/utils/aiInput'
import { CardType, type Content } from '@/types/core'
import { nextTick } from 'vue'

const props = defineProps<{
  packageId: number
  chapterId: string
}>()

const emit = defineEmits<{
  cardsCreated: [CardResponse[]]
  done: []
}>()

const resultContainer = ref<HTMLDivElement>()

const store = useCommonStore()

const INPUT_LIMIT = 500
const input = ref(store.aiGenerate.content)
const isCardsCreating = ref(false)
const focusedKeypointId = ref<string>()

const isInputOverflow = computed(() => {
  return input.value.length > INPUT_LIMIT
})

const isAlreadyGenerated = computed(() => {
  const { keypoints: cards, selectedKeypoints: selectedCards } =
    store.aiGenerate
  return cards.length > 0 || selectedCards.length > 0
})

const selectedCards = computed(() => {
  return store.aiGenerate.selectedKeypoints
})

async function onAiGenerate() {
  if (input.value.trim() === '') {
    _message.info(_t('请输入内容'))
    return
  }

  store.aiGenerateCards(input.value).then(() => {
    if (store.aiGenerate.keypoints.length === 0) {
      _message.info(_t('知识点提取失败，请重试'))
    }
  })
}

async function onAiGenerateAgain() {
  if (input.value.trim() === '') {
    _message.info(_t('请输入内容'))
    return
  }

  resultContainer.value?.scrollTo({ top: 0, behavior: 'smooth' })
  store.aiReGenerateCards(input.value).then(() => {
    if (store.aiGenerate.keypoints.length === 0) {
      _message.info(_t('知识点提取失败，请重试'))
    }
  })
}

function onCardSelect(index: number) {
  const card = store.aiGenerate.keypoints[index]
  store.selectAiCard(index)

  // 选择卡片后，由于卡片会进入的「已选择」的列表中，所以需要滚动到视口位置
  nextTick(() => {
    document.querySelector(`#keypoint-${card.id}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  })
}

function onCardDelete(index: number) {
  store.removeAiCard(index)
}

function onCardUnSelect(index: number) {
  store.unselectAiCard(index)
}

function onAiGenerateCancel() {
  store.abortAiGenerate()
}

async function generateCards() {
  const keypoints =
    store.aiGenerate.selectedKeypoints.length === 0
      ? store.aiGenerate.keypoints
      : store.aiGenerate.selectedKeypoints

  isCardsCreating.value = true
  try {
    const res = await batchCreateCards({
      packageId: props.packageId,
      chapterId: props.chapterId,
      createdType: CardCreatedType.AIGC,
      aigc: {
        outputs: keypoints.map(item => toAiInput(item.content)),
        sessionId: store.aiGenerate.sessionId,
      },
      cards: keypoints.map(item => {
        return {
          type: CardType.CLOZE,
          content: item.content,
        }
      }),
    })
    emit('cardsCreated', res.data.cards)
    emit('done')
    _message.info(_t('卡片已全部添加到卡包'))
    store.resetAiGenerate()
  } finally {
    isCardsCreating.value = false
  }
}

async function onCardsCreate() {
  if (store.aiGenerate.loading) {
    _confirm({
      scene: 'warn',
      content: _t('AI 还在生成中，现在添加卡片将会立即结束本次 AI 生成'),
      primaryText: _t('等一等 AI 生成，暂不添加'),
      secondaryText: _t('结束 AI 生成，立即添加卡片'),
      onSecondaryClick(resolve) {
        resolve(true)
        store.abortAiGenerate()
        generateCards()
      },
    })
    return
  }

  generateCards()
}

function onAiGenerateClear() {
  input.value = ''
  store.resetAiGenerate()
}

function onInputChange(input: string) {
  store.updateAiContent(input)
}

function onKeypointChange(index: number, newContent: Content) {
  store.updateAiCard(index, newContent)
}

function onSelectedKeypointChange(index: number, newContent: Content) {
  store.updateSelectedAiCard(index, newContent)
}

function onKeypointFocus(id: string) {
  focusedKeypointId.value = id
}
</script>
<style scoped></style>
