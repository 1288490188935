<template>
  <div :style="style"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const style = computed(() => {
  const top = _store.appInfo?.insets?.top ?? 0
  return {
    height: `${top}px`,
  }
})
</script>

<style scoped></style>
