<template>
  <div
    v-if="groups.length > 0"
    class="p-4"
  >
    <div class="mb-3 text-15px">{{ _t('知识点干扰项') }}</div>

    <div class="flex flex-col gap-2">
      <div
        v-for="(group, i) in groups"
        :key="group.group"
      >
        <div class="flex items-center mb-1">
          <div class="text-13px flex-1 mr-1 overflow-hidden">
            <div
              class="flex-1 flex items-center gap-1 w-full"
              :style="`color: var(--${getGroupColor(group.group)})`"
            >
              {{ _t('知识点') }}

              <div
                class="shrink-0 w-14px h-14px text-12px border border-solid border-ld-text font-[DIN] rounded-100% flex items-center justify-center"
                :style="`border-color: var(--${getGroupColor(group.group)})`"
              >
                {{ group.index }}
              </div>
            </div>

            <div class="truncate text-ld-label-secondary">
              {{ getGroupLabel(group) }}
            </div>
          </div>

          <div
            :class="[
              'px-2px py-1px hover:bg-gray-200 text-ld-brand-500 rounded-4px cursor-pointer flex items-center',
              {
                'g-disabled': aiLoading.get(groupKey(i)),
              },
            ]"
            @click="onAiGenerate(i)"
          >
            <Icon
              name="ai-add-card"
              class="w-16px ml-auto shrink-0"
            />

            <span
              v-if="group.distractorText.length === 0"
              class="text-13px"
            >
              {{ _t('AI 生成') }}
            </span>
          </div>
        </div>

        <div class="relative">
          <div
            v-if="distractorInputCache.get(groupKey(i)) != null"
            class="flex items-center text-13px bg-ld-brand-100 h-30px px-2 rounded-t-8px"
          >
            <Icon
              name="ai-check"
              class="w-20px text-ld-brand-500"
            />
            <span class="font-semibold text-ld-brand-500 ml-1">
              {{ _t('已生成') }}
            </span>
            <div
              class="text-gray cursor-pointer ml-auto"
              @click="onAiGenUndo(i)"
            >
              {{ _t('撤销本次生成') }}
            </div>
          </div>

          <!--
            这里不能用 class 来覆盖顶部圆角，可能会出现无法生效的问题
          -->
          <Textarea
            v-model="group.distractorText"
            class="w-full shadow-none bg-white"
            :style="{
              borderRadius: distractorInputCache.get(groupKey(i))
                ? '0px 0px 6px 6px'
                : undefined,
            }"
            rows="1"
            autoResize
            @focus="emit('focus')"
            @update:modelValue="onDistractorInput(props.cardId, group, $event)"
          />

          <DistractorAILoading
            v-if="aiLoading.get(groupKey(i))"
            class="absolute w-full h-full top-0 left-0"
            @cancel="onAiAbort(i)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type ClozeCard } from '@/types/core'
import { ref, watch } from 'vue'
import Textarea from 'primevue/textarea'
import DistractorAILoading from './DistractorAILoading.vue'
import { genAiDistractors } from '@/api/package-source'
import { genAiSessionId } from '@/utils'
import { uniq } from 'lodash-es'
import { Code } from '@/api/code'
import {
  getAllIndexedClozes,
  type IndexedCloze,
} from '@/components/ConcreteCard/ClozeCardFace/faces/Choice/helper'
import { flatContent, parseClozeGroupIndex } from '@/utils/card'
import {
  PresetClozeGroup,
  getClozeColor,
} from '../ClozeEditor/extensions/cloze'
import {
  aiLoading,
  aiAbortControllers,
  distractorInputCache,
  type GroupKey,
} from './state'
import bus, { BusEvent } from '@/bus/bus'

const emit = defineEmits<{
  focus: []
  'update:distractors': [cardId: number, ClozeGroup, distractors: string[]]
}>()

export type ClozeGroup = {
  group?: string
  index: string
  clozes: IndexedCloze[]
  distractorText: string
  sessionId: string
}

const props = defineProps<{
  card: ClozeCard
  cardId: number
}>()

const groups = ref<ClozeGroup[]>([])

function groupKey(index: number): GroupKey {
  return `${props.cardId}:${index}`
}

function generateClozeGroups() {
  const groups: ClozeGroup[] = []
  const allClozes = getAllIndexedClozes(props.card.content)

  for (const cloze of allClozes) {
    const existGroup = groups.find(item => item.group === cloze.group)

    if (existGroup == null) {
      const index = parseClozeGroupIndex(cloze.group)
      groups.push({
        group: cloze.group,
        index: index == null ? '*' : index.toString(),
        clozes: [cloze],
        distractorText: getDistractorText([cloze]),
        sessionId: genAiSessionId(),
      })
    } else {
      existGroup.clozes.push(cloze)
      existGroup.distractorText = getDistractorText(existGroup.clozes)
    }
  }

  groups.sort((a, b) => {
    const aIndex = parseInt(a.index)
    const bIndex = parseInt(b.index)

    if (isNaN(aIndex) && isNaN(bIndex)) {
      return 0
    } else if (!isNaN(aIndex) && isNaN(bIndex)) {
      return -1
    } else if (isNaN(aIndex) && !isNaN(bIndex)) {
      return 1
    } else {
      return aIndex - bIndex
    }
  })

  return groups
}

function getDistractorText(clozes: IndexedCloze[]) {
  const distractors: string[] = []

  for (const cloze of clozes) {
    if (cloze.distrators) {
      distractors.push(...cloze.distrators)
    }
  }

  return distractors.join('||')
}

function getGroupLabel(group: ClozeGroup) {
  return group.clozes.map(item => item.text).join('，')
}

function getGroupColor(group?: string) {
  if (Object.values(PresetClozeGroup).includes(group as any)) {
    return getClozeColor(group)
  }

  return getClozeColor(PresetClozeGroup.c1)
}

function onDistractorInput(cardId: number, group: ClozeGroup, input: string) {
  const distractors = input
    .split('||')
    .map(item => item.trim())
    .filter(item => item.length > 0)
  emit('update:distractors', cardId, group, distractors)

  // 移动端使用
  bus.emit(BusEvent.AiDistractorsGenerated, cardId, group, distractors)
}

// 给 ai 的数据需要把其他的挖空都变成普通文本
function createAiContent(group: ClozeGroup) {
  const texts: string[] = []
  const allInlineNodes = flatContent(props.card.content)

  let clozeIndex = 0
  for (const n of allInlineNodes) {
    if (n.type === 'text') {
      texts.push(n.text)
    } else if (n.type === 'cloze') {
      const isTarget = group.clozes.some(item => item.index === clozeIndex)

      if (isTarget) {
        texts.push(`{{c1::${n.text}}}`)
      } else {
        texts.push(n.text)
      }

      clozeIndex++
    }
  }

  return texts.join('')
}

function onAiGenerate(index: number) {
  const key = groupKey(index)
  if (aiLoading.value.get(key)) {
    return
  }

  const cardId = props.cardId
  const item = groups.value[index]

  const abortController = new AbortController()
  aiLoading.value.set(key, true)
  aiAbortControllers.value.set(key, abortController)

  genAiDistractors(
    {
      content: createAiContent(item),
      sessionId: item.sessionId,
    },
    abortController.signal
  )
    .then(res => {
      if (res.code !== Code.Ok) {
        _message.info(`${res.message}，请重试`)
        return
      }

      const distractors = uniq(res.data.distractors)

      if (distractors.length > 0) {
        distractorInputCache.value.set(key, item.distractorText)

        item.distractorText = distractors.join('||')
        onDistractorInput(cardId, item, item.distractorText)
      } else {
        _message.info(_t(`干扰项生成失败，请重试`))
      }
    })
    .finally(() => {
      aiLoading.value.set(key, false)
    })
}

function onAiAbort(index: number) {
  const abortController = aiAbortControllers.value.get(groupKey(index))

  abortController?.abort()
}

function onAiGenUndo(index: number) {
  const key = groupKey(index)
  const cache = distractorInputCache.value.get(key)
  const group = groups.value[index]

  if (cache != null && group != null) {
    group.distractorText = cache
    distractorInputCache.value.delete(key)
    onDistractorInput(props.cardId, group, cache)
  }
}

onInit(() => {
  groups.value = generateClozeGroups()
})

watch(
  () => props.card,
  () => {
    groups.value = generateClozeGroups()
  }
)
</script>
<style scoped></style>
