<template>
  <van-time-picker
    v-model="time"
    :show-toolbar="false"
    visible-option-num="5"
    format="HH:mm"
    :formatter="formatter"
    class="time-picker"
    @change="onChanged"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{
  hour: number
  minute: number
}>()
const time = ref([props.hour, props.minute])

const emit = defineEmits<{
  update: [hour: number, minute: number]
}>()
const formatter = (type: string, option: any) => {
  if (type === 'hour') {
    option.text = option.text.replace(/^0/, '') + ' 时'
  }
  if (type === 'minute') {
    option.text = option.text.replace(/^0/, '') + ' 分'
  }
  return option
}
function onChanged() {
  emit('update', time.value[0], time.value[1])
}
</script>

<style scoped>
.time-picker {
  border: 1px solid var(--gray-200);
  border-radius: 12px;
  overflow: hidden;
  font-weight: 600;
}
</style>
