<template>
  <Loading
    v-if="loading"
    class="h-full"
  />

  <div
    v-else-if="taskStats"
    class="relative pb-10 h-full w-full overflow-x-hidden bg-ld-background"
  >
    <div
      v-if="!_global.isPcMode"
      class="circle-bg"
    >
      <SafeAreaTopSpacer />
      <div class="h-280px"></div>
    </div>

    <SafeAreaTopSpacer v-if="!_global.isPcMode" />

    <div class="h-302px">
      <div
        v-if="!showWeeklyTasks"
        class="flex flex-col items-center pt-30px leading-normal relative"
      >
        <Img
          name="ld-greet"
          class="w-100px"
          @dblclick="onRcodeDialogShow"
        />

        <div class="text-ld-brand-500 text-23px font-semibold mt-2 mb-1">
          {{ _t('日积月累，聚沙成塔') }}
        </div>

        <div class="text-ld-brand-900 text-16px font-semibold">
          {{ _t(`完成每日目标，获取奖励`) }}
        </div>
      </div>

      <template v-else-if="taskStats.weekly != null">
        <div class="flex justify-between px-4 pt-30px leading-normal relative">
          <div class="py-6">
            <div class="text-ld-brand-500 text-23px font-semibold">
              {{ _t('日积月累，聚沙成塔') }}
            </div>

            <div
              class="mt-4px mb-12px text-ld-brand-900 text-16px font-semibold"
            >
              {{
                _t(
                  `本周已完成 ${taskStats.weekly.completedDailyTaskCount} 个每日目标`
                )
              }}
            </div>

            <div
              :class="[
                'text-gray flex items-center text-15px',
                {
                  'text-red': isWeeklyDeadlineRed(taskStats.weekly.deadline),
                },
              ]"
            >
              <i class="pi pi-clock mr-1"></i>
              {{ _t(`还剩 ${formatDeadline(taskStats.weekly.deadline)}`) }}
            </div>
          </div>

          <Img
            name="ld-laugh"
            class="ld-icon"
            @dblclick="onRcodeDialogShow"
          />
        </div>

        <div class="mx-4 mt-4 mb-32 relative">
          <ProgressBar
            :percent="weeklyTaskProgress"
            :highlight="false"
            style="width: 96%"
            class="h-20px"
          />

          <Tooltip
            v-for="(task, i) of taskStats.weekly.tasks"
            :key="i + task.rewardClaimed.toString()"
            :show="overlayWeeklyTask === task"
            placement="top"
            trigger="click"
            @update:show="onTooltipShowUpdate"
          >
            <div class="w-180px flex flex-col items-center">
              <ProgressBar
                :percent="getWeeklyTaskProgress(task)"
                :highlight="false"
                :text="`${taskStats.weekly.completedDailyTaskCount}/${task.dailyTaskRequire}`"
              />
              <div class="text-17px mt-2">
                {{ _t(`完成 ${task.dailyTaskRequire} 个每日任务`) }}
              </div>
            </div>

            <template #trigger>
              <div
                class="weekly-task cursor-pointer"
                :style="getWeeklyTaskStyle(task)"
                @click="onWeeklyTaskClick(task)"
              >
                <Icon
                  v-if="task.rewardClaimed"
                  name="weekly-task-done"
                  class="w-40px"
                />

                <RewardBoxIcon
                  v-else
                  :animation="isWeeklyTaskAvaiable(task)"
                  class="w-40px"
                />

                <div class="task-require">{{ task.dailyTaskRequire }}</div>
              </div>
            </template>
          </Tooltip>
        </div>
      </template>
    </div>

    <div class="flex justify-between mx-4">
      <div class="text-ld-text text-17px font-semibold">
        {{ _t('每日目标') }}
      </div>

      <div
        :class="[
          'text-gray flex items-center text-15px',
          {
            'text-red': isDailyDeadlineRed(taskStats.daily.deadline),
          },
        ]"
      >
        <i class="pi pi-clock mr-1"></i>
        {{ _t(`还剩 ${formatDeadline(taskStats.daily.deadline)}`) }}
      </div>
    </div>

    <DailyTasks
      class="mt-3 mx-4"
      :tasks="taskStats.daily.tasks"
      :stats="taskStats.daily.stats"
      @claimed="onDailyTaskClaimed"
    />
  </div>
</template>
<script setup lang="ts">
import ProgressBar from '@/components/ProgressBar.vue'
import {
  fetchTasks,
  receiveTaskReward,
  TaskType,
  type TaskStats,
  type WeeklyTask,
} from '@/api/task'
import { ref } from 'vue'
import { maxBy } from 'lodash-es'
import { computed } from 'vue'
import dayjs from 'dayjs'
import { useCommonStore } from '@/stores'
import DailyTasks from './DailyTasks.vue'
import RewardBoxIcon from '@/components/RewardBoxIcon.vue'
import { formatDeadline } from '@/utils'

import RcodeConsumeDialog from '@/components/RcodeConsumeDialog.vue'

const store = useCommonStore()

const loading = ref(false)
const overlayWeeklyTask = ref<WeeklyTask>()
const taskStats = ref<TaskStats>()

const showWeeklyTasks = computed(() => {
  if (taskStats.value == null) return false
  if (taskStats.value.weekly == null) return false
  if (taskStats.value.weekly.tasks.length == 0) return false
  return true
})

const weeklyTaskMax = computed(() => {
  if (taskStats.value == null || taskStats.value.weekly == null) return 0

  return maxBy(taskStats.value.weekly.tasks, item => item.dailyTaskRequire)!
    .dailyTaskRequire
})

const weeklyTaskProgress = computed(() => {
  if (
    taskStats.value == null ||
    weeklyTaskMax.value === 0 ||
    taskStats.value.weekly == null
  )
    return 0

  return (
    (taskStats.value.weekly.completedDailyTaskCount / weeklyTaskMax.value) * 100
  )
})

function isWeeklyTaskAvaiable(task: WeeklyTask) {
  if (taskStats.value == null || taskStats.value.weekly == null) return false

  return (
    taskStats.value.weekly.completedDailyTaskCount >= task.dailyTaskRequire &&
    !task.rewardClaimed
  )
}

async function fetchUserTaskProgress() {
  loading.value = true
  try {
    const stats = await fetchTasks()
    taskStats.value = stats
  } finally {
    loading.value = false
  }
}

function isWeeklyDeadlineRed(deadline: string) {
  return dayjs(deadline).diff(new Date(), 'day') < 1
}

function isDailyDeadlineRed(deadline: string) {
  return dayjs(deadline).diff(new Date(), 'hour') < 1
}

function getWeeklyTaskProgress(task: WeeklyTask) {
  if (taskStats.value == null || taskStats.value.weekly == null) return 0

  const percent =
    (taskStats.value.weekly.completedDailyTaskCount / task.dailyTaskRequire) *
    100
  return Math.min(percent, 100)
}

function getWeeklyTaskStyle(task: WeeklyTask) {
  if (taskStats.value == null) return

  const styleList: string[] = []

  const left = (task.dailyTaskRequire / weeklyTaskMax.value) * 100
  const offset = Math.max(24, Math.floor(20 * (left / 50)))

  styleList.push(`left: calc(${left}% - ${offset}px);`)

  return styleList.join('')
}

let isClaimLoading = false
function onWeeklyTaskClick(task: WeeklyTask) {
  if (task.rewardClaimed) {
    _message.info('已领取过该奖励')
    return
  }

  // 还未达到领奖条件
  if (!isWeeklyTaskAvaiable(task)) {
    overlayWeeklyTask.value = task
    return
  }

  if (isClaimLoading) return

  isClaimLoading = true
  receiveTaskReward(
    {
      taskNo: task.taskNo,
      type: TaskType.WEEKLY,
    },
    store
  )
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      task.rewardClaimed = true
      _showRewards(task.rewards)
    })
    .finally(() => {
      isClaimLoading = false
    })
}

function onTooltipShowUpdate(val: boolean) {
  if (!val) {
    overlayWeeklyTask.value = undefined
  }
}

function onDailyTaskClaimed() {
  if (taskStats.value?.weekly == null) return

  taskStats.value.weekly.completedDailyTaskCount++
}

function onRcodeDialogShow() {
  _openDialog(RcodeConsumeDialog, {
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-3 items-center overflow-x-hidden' },
      },
    },
  })
}

onInit(fetchUserTaskProgress)
</script>
<style scoped>
.ld-icon :deep(img) {
  width: 100px;
}

.circle-bg {
  position: absolute;
  left: -50%;
  width: 200%;
  background-color: var(--ld-brand-100);
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.weekly-task {
  width: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.weekly-task .task-require {
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--neutral-500);
  border-radius: 4px;
  font-family: DIN;
  color: white;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  width: 34px;
  height: 20px;
  text-align: center;
}
</style>
