<template>
  <div class="h-full flex flex-col bg-ld-background">
    <AppBar
      :title="_t('操作偏好')"
      @back="onBack"
    />
    <div class="flex flex-col p-4">
      <ListCell
        :title="_t('音效反馈')"
        :arrow="false"
      >
        <template #action>
          <InputSwitch
            :model-value="store.soundFeedbackOn"
            @update:modelValue="onSoundFeedbackChange"
          />
        </template>
      </ListCell>
      <ListCell
        v-if="_global.isInsideApp"
        :title="_t('震动反馈')"
        :arrow="false"
      >
        <template #action>
          <InputSwitch
            :model-value="store.vibrateFeedbackOn"
            @update:modelValue="onHapticFeedbackChange"
          />
        </template>
      </ListCell>
    </div>
  </div>
</template>

<script setup lang="ts">
import ListCell from '@/components/ListCell/ListCell.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'

const store = useCommonStore()

const emit = defineEmits<{
  back: []
}>()

function onHapticFeedbackChange(value: boolean) {
  store.setVibrateFeedbackOn(value)
}
function onSoundFeedbackChange(value: boolean) {
  store.setSoundFeedbackOn(value)
}

function onBack() {
  emit('back')
}
</script>

<style scoped></style>
