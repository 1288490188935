<template>
  <Divider :pt="{ content: { class: '!bg-ld-background' } }">
    <div class="flex items-center gap-1 text-17px font-semibold">
      <Icon
        :name="props.iconName"
        class="w-24px"
      />

      {{ label }}

      <Icon
        :name="props.iconName"
        class="w-24px"
      />
    </div>
  </Divider>
</template>
<script lang="ts" setup>
const props = defineProps<{
  iconName: string
  label: string
}>()
</script>
<style scoped></style>
