export enum Code {
  Ok = 0,
  PackageNotFound = 1203,
  PermissionDenied = 1206,
  NotEnoughCards = 1501,
  WechatAlereadyBound = 1008,
  NotEnoughDiamonds = 1016,
  NotEnoughEnergy = 1701,
  EnergyAlereadyFull = 1702,
  PackageCardsTooFeew = 1211,
  UsernameOrPwdWrong = 1001,
  WechatOpenIdInvalid = 1425,
}
