<template>
  <div class="h-44px flex items-center">
    <Icon
      name="close-circle"
      class="w-20px ml-auto cursor-pointer"
      @click="emit('done')"
    />
  </div>

  <div class="flex flex-col items-center justify-center">
    <Icon
      name="ld-avatar"
      class="w-85px"
    />

    <div class="h-12px"></div>

    <div
      class="text-19px font-semibold text-[var(--text-color-primary)] text-center whitespace-pre-line"
    >
      {{ props.content }}
    </div>

    <div class="h-20px"></div>

    <template v-if="props.scene === 'wechat'">
      <Button
        class="whitespace-nowrap w-full"
        scene="wechat"
        @click="onConfirmButtonClick()"
      >
        <div class="flex justify-center items-center w-full">
          <Icon
            name="wechat-pure"
            class="h-28px"
          />
          <span class="ml-1">{{ props.okText }}</span>
        </div>
      </Button>
    </template>
  </div>
</template>

<script lang="ts" setup>
import Icon from './Icon.vue'

const props = defineProps<{
  scene: 'wechat'
  content: string
  okText: string
}>()

const emit = defineEmits<{
  confirm: []
  done: []
}>()

function onConfirmButtonClick() {
  emit('confirm')
}
</script>

<style scoped></style>
