<template>
  <ColumnLayout
    :data="data"
    :columns="4"
    class="w-500px"
  >
    <template #default="data">
      <div
        class="p-4 bg-blue-100"
        :style="{ height: data.item * 100 + 'px' }"
      >
        {{ data }}
      </div>
    </template>
  </ColumnLayout>
</template>

<script lang="ts" setup>
import ColumnLayout from '../../components/ColumnLayout.vue'

const data = [1, 2, 3, 4, 5]
</script>

<style scoped></style>
