<template>
  <div class="flex flex-col p-4 gap-2">
    <span>整体区域布局</span>
    <div class="flex space-x-2">
      <div
        :class="[
          'layout-container h-500px bg-red-200 flex-1',
          {
            'justify-between': viewSize.width <= 700,
          },
        ]"
      >
        <div
          class="layout-item h-200px flex justify-center items-center bg-blue-200"
        >
          问题
        </div>
        <div
          class="layout-item h-200px flex justify-center items-center bg-blue-200"
        >
          答案
        </div>
      </div>

      <div class="layout-container h-500px bg-red-200 flex-1">
        <div
          class="layout-item h-200px flex justify-center items-center bg-blue-200"
        >
          问题
        </div>
        <div
          class="layout-item h-500px flex justify-center items-center bg-blue-200"
        >
          答案
        </div>
      </div>
    </div>

    <span>grid 2</span>
    <div class="layout-item bg-green-100 grid-answer">
      <AutoFontSizeByText
        class="grid-option aspect-square bg-white"
        :font-size-list="[21, 19, 17, 15]"
      >
        浪漫主义诗歌
      </AutoFontSizeByText>
      <AutoFontSizeByText
        class="grid-option aspect-square bg-white"
        :font-size-list="[21, 19, 17, 15]"
      >
        现实主义诗歌
      </AutoFontSizeByText>
    </div>

    <span>grid 4</span>
    <div class="layout-item bg-green-100 grid-answer">
      <AutoFontSizeByText class="grid-option bg-white">一</AutoFontSizeByText>
      <AutoFontSizeByText class="grid-option bg-white">
        一二三
      </AutoFontSizeByText>
      <AutoFontSizeByText class="grid-option bg-white">
        一二三四五六
      </AutoFontSizeByText>
      <AutoFontSizeByText class="grid-option bg-white">
        1234567890123
      </AutoFontSizeByText>
    </div>

    <span>list</span>
    <div class="layout-item bg-green-100 list-answer">
      <AutoFontSizeByLine
        :font-size-list="[21, 19, 17, 15]"
        class="list-option bg-white"
      >
        一二三四五六七八九十一二三
      </AutoFontSizeByLine>
      <AutoFontSizeByLine
        :font-size-list="[21, 19, 17, 15]"
        class="list-option bg-white"
      >
        一二三四五六七八九十一二三四五六七八九十五六七八九十五六五六
      </AutoFontSizeByLine>
      <AutoFontSizeByLine
        :font-size-list="[21, 19, 17, 15]"
        class="list-option bg-white"
      >
        一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十
      </AutoFontSizeByLine>
      <AutoFontSizeByLine
        :font-size-list="[21, 19, 17, 15]"
        class="list-option bg-white"
      >
        一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十一二三四五六七八九十
      </AutoFontSizeByLine>
    </div>

    <span>问题区</span>
    <CardQuestionLayout class="layout-item bg-green-100">
      <template #text>楚辞是?</template>
    </CardQuestionLayout>
    <CardQuestionLayout class="layout-item bg-green-100">
      <template #text>
        楚辞是中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史
      </template>
    </CardQuestionLayout>
    <CardQuestionLayout class="layout-item bg-green-100">
      <template #text>
        楚辞是中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上第一部？中国史上中国史上中国史上中国史上中国史上史上中国史上中国史上中国史上史上中国史上中国史上中国史上
        楚辞是中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上第一部？中国史上中国史上中国史上中国史上中国史上史上中国史上中国史上中国史上史上中国史上中国史上中国史上
      </template>
    </CardQuestionLayout>

    <CardQuestionLayout
      asset-id="/usr/76003/img/1011.800.800.jpg"
      class="layout-item bg-green-100"
    >
      <template #text>楚辞是?</template>
    </CardQuestionLayout>
    <CardQuestionLayout
      asset-id="/usr/76003/img/1011.800.800.jpg"
      class="layout-item bg-green-100"
    >
      <template #text>
        楚辞是中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史
      </template>
    </CardQuestionLayout>
    <CardQuestionLayout
      asset-id="/usr/76003/img/1011.800.800.jpg"
      class="layout-item bg-green-100"
    >
      <template #text>
        楚辞是中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上第一部？中国史上中国史上中国史上中国史上中国史上史上中国史上中国史上中国史上史上中国史上中国史上中国史上
        楚辞是中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上中国史上第一部？中国史上中国史上中国史上中国史上中国史上史上中国史上中国史上中国史上史上中国史上中国史上中国史上
      </template>
    </CardQuestionLayout>
  </div>
</template>

<script setup lang="ts">
import CardQuestionLayout from '@/components/ConcreteCard/layout/CardQuestionLayout.vue'
import AutoFontSizeByText from '@/components/AutoFontSizeByText.vue'
import AutoFontSizeByLine from '@/components/AutoFontSizeByLine.vue'

const viewSize = window._viewSize
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px 0px;
  gap: 16px;
}
/* 问题和答案块 */
.layout-item {
  flex-shrink: 0;
  padding: 16px;
}

/* grid 每行两个 */
/* 选项 2个比例为1 超过2个 比例为3/2 */

.grid-answer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
.grid-option {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 3/2;
}

.list-answer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list-option {
  padding: 16px;
}

.text-container {
  width: 100%;
}

.text-content {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 21px;
  line-height: 1.5;
}
</style>
