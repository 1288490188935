<template>
  <div
    class="flex items-center py-6px px-3 shadow-sm z-1 bg-white"
    @click="onAppOpen"
  >
    <Icon
      name="ld-avatar"
      class="w-36px"
    />

    <span class="ml-2 text-13px text-ld-brand-500 font-medium">
      {{ _t('打开小灵鸭 App，免费领钻石') }}
    </span>

    <Button
      :label="_t('打开 App')"
      size="small"
      class="ml-auto"
    />
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router'

const router = useRouter()
function onAppOpen() {
  router.push({ name: 'app-download' })
}
</script>
<style scoped></style>
