import { parseClozeGroupIndex } from '@/utils/card'
import { Mark, mergeAttributes, type Editor } from '@tiptap/core'

export enum PresetClozeGroup {
  c1 = 'c1',
  c2 = 'c2',
  c3 = 'c3',
  c4 = 'c4',
  c5 = 'c5',
  c6 = 'c6',
  c7 = 'c7',
  c8 = 'c8',
  c9 = 'c9',
}

export const CLOZE_CLEAR = 'clear'

export function getClozeColor(group?: string) {
  if (group == null) return 'gray-900'

  return (
    {
      [PresetClozeGroup.c1]: 'ld-brand-500',
      [PresetClozeGroup.c2]: 'sky-600',
      [PresetClozeGroup.c3]: 'green-600',
      [PresetClozeGroup.c4]: 'orange-600',
      [PresetClozeGroup.c5]: 'pink-600',
    }[group] ?? 'gray-900'
  )
}

declare module '@tiptap/core' {
  interface Editor {
    id?: number
    clozeGroup?: PresetClozeGroup
  }

  interface Commands<ReturnType> {
    cloze: {
      setCloze: (group: PresetClozeGroup) => ReturnType
      toggleCloze: (group: PresetClozeGroup) => ReturnType
      unsetCloze: () => ReturnType
    }
  }
}
const Cloze = Mark.create({
  name: 'cloze',

  // 用于解决初始化时两个连续的挖空只会渲染一次
  // 增加该属性后，连续的挖空不会自动合并
  spanning: false,

  exitable: true,

  // 不能和 textStyle 同时存在，后续可以开放
  excludes: 'textStyle',

  parseHTML() {
    return [
      {
        tag: 'span[data-cloze]',
      },
    ]
  },
  renderHTML({ HTMLAttributes, mark }) {
    const group = mark.attrs.group
    const groupIndex = parseClozeGroupIndex(group)

    let supscriptClass = ''

    if (groupIndex == null) {
      supscriptClass = 'g-cloze-supscript-wildcard'
    } else if (groupIndex > 9) {
      supscriptClass = 'g-cloze-supscript-more'
      // 除了 c1 外都展示角标
    } else if (groupIndex > 1) {
      supscriptClass = `g-cloze-supscript-${groupIndex}`
    }

    return [
      'span',
      mergeAttributes(
        {
          'data-cloze': true,
          class: `g-cloze ${supscriptClass}`,
          style: `color: var(--${getClozeColor(mark.attrs.group)})`,
        },
        HTMLAttributes
      ),
      0,
    ]
  },

  addAttributes: () => {
    return {
      group: {
        default: PresetClozeGroup.c1,
      },
      distrators: {
        default: undefined,
      },
      giveAwayDistrators: {
        default: undefined,
      },
    }
  },

  addCommands() {
    return {
      setCloze:
        (group: PresetClozeGroup) =>
        ({ chain }) => {
          return chain()
            .unsetMark('textStyle')
            .unsetMark(this.name)
            .setMark(this.name, {
              group,
            })
            .run()
        },
      toggleCloze:
        (group: PresetClozeGroup) =>
        ({ chain, editor }) => {
          // 这里不能使用 editor.toggleMark 的原因是在 toggle 不同 group 的 cloze 时
          // 会添加多个 cloze mark
          if (editor.isActive(this.name, { group })) {
            return chain().unsetCloze().run()
          } else {
            return chain().unsetMark('textStyle').setCloze(group).run()
          }
        },
      unsetCloze:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        },
    }
  },

  addKeyboardShortcuts() {
    const editor: Editor = this.editor

    function toggleCloze(group?: PresetClozeGroup) {
      const selectedGroup = group ?? editor.clozeGroup ?? PresetClozeGroup.c1

      return editor.chain().toggleCloze(selectedGroup).run()
    }

    return {
      'Mod-d': () => toggleCloze(),
      'Mod-D': () => toggleCloze(),
      'Ctrl-d': () => toggleCloze(),
      'Ctrl-D': () => toggleCloze(),

      'Mod-1': () => toggleCloze(PresetClozeGroup.c1),
      'Mod-2': () => toggleCloze(PresetClozeGroup.c2),
      'Mod-3': () => toggleCloze(PresetClozeGroup.c3),
      'Mod-4': () => toggleCloze(PresetClozeGroup.c4),
      'Mod-5': () => toggleCloze(PresetClozeGroup.c5),
      'Mod-6': () => toggleCloze(PresetClozeGroup.c6),
      'Mod-7': () => toggleCloze(PresetClozeGroup.c7),
      'Mod-8': () => toggleCloze(PresetClozeGroup.c8),
      'Mod-9': () => toggleCloze(PresetClozeGroup.c9),

      'Ctrl-1': () => toggleCloze(PresetClozeGroup.c1),
      'Ctrl-2': () => toggleCloze(PresetClozeGroup.c2),
      'Ctrl-3': () => toggleCloze(PresetClozeGroup.c3),
      'Ctrl-4': () => toggleCloze(PresetClozeGroup.c4),
      'Ctrl-5': () => toggleCloze(PresetClozeGroup.c5),
      'Ctrl-6': () => toggleCloze(PresetClozeGroup.c6),
      'Ctrl-7': () => toggleCloze(PresetClozeGroup.c7),
      'Ctrl-8': () => toggleCloze(PresetClozeGroup.c8),
      'Ctrl-9': () => toggleCloze(PresetClozeGroup.c9),
    }
  },
})

export default Cloze
