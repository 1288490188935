<template>
  <div class="vstack justify-center h-full">
    <Icon
      name="404"
      :class="iconClass"
    />

    <div :class="textClass">
      {{ _t('你似乎来到了一个不存在的页面') }}
    </div>

    <Button
      :label="_t('回到首页')"
      class="w-310px max-w-full mx-16px"
      @click="onBackToHome"
    ></Button>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const iconClass = computed(() => {
  return _global.isPcMode ? 'h-420px' : 'h-210px'
})

const textClass = computed(() => {
  const classList = ['leading-[1.4]', 'font-semibold']

  if (_global.isPcMode) {
    classList.push('text-48px mt-154px mb-64px')
  } else {
    classList.push('text-24px mt-92px mb-32px')
  }
  return classList.join(' ')
})

function onBackToHome() {
  router.replace(_global.homePage)
}
</script>
<style scoped></style>
