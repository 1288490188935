<template>
  <OperationPreference
    class="g-safe-area"
    @back="onBack"
  />
</template>
<script setup lang="ts">
import OperationPreference from '@/pages/OperationPreference.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.push({ name: 'mine' })
  }
}
</script>
<style scoped></style>
