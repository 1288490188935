// 导航栏 Icon 配置
export const NavIcons = {
  atlas: {
    label: '闯关',
    icon: 'nav-atlas',
    activeIcon: 'nav-atlas-active',
  },
  reward: {
    label: '奖励',
    icon: 'nav-reward',
    activeIcon: 'nav-reward-active',
  },
  rank: {
    label: '排位',
    icon: 'nav-rank',
    activeIcon: 'nav-rank-active',
  },
  bookshelf: {
    label: '卡包',
    icon: 'nav-learn',
    activeIcon: 'nav-learn-active',
  },
  mine: {
    label: '个人',
    icon: 'nav-mine',
    activeIcon: 'nav-mine-active',
  },
}

// 数据统计 Icon 配置
export const StatsIcons = {
  stageCount: {
    icon: 'nav-atlas-active',
    color: 'sky',
    label: '总闯关数',
  },
  exp: {
    icon: 'exp',
    color: 'ld-brand',
    label: '总经验',
  },
  accumulatedCheckInDays: {
    icon: 'calendar',
    color: 'rose',
    label: '累计天数',
  },
  maxCheckInCtDays: {
    icon: 'flame',
    color: 'orange',
    label: '最长连续天数',
  },
}
