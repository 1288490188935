<template>
  <div class="w-full flex flex-col items-center gap-4">
    <div class="w-full flex items-enter h-24px">
      <span class="text-17px leading[1.4] mx-auto font-bold">
        {{ title }}
      </span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 z-1 w-25px"
        @click="emit('done')"
      ></Icon>
    </div>
    <div class="flex flex-col py-4 w-full gap-8">
      <TimePicker
        :hour="hour"
        :minute="minute"
        class="w-full"
        @update="onUpdateTime"
      />

      <Button
        :label="_t('确定')"
        size="normal"
        @click="onDone"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import TimePicker from '@/components/TimePicker.vue'
import { ref } from 'vue'
const emit = defineEmits<{
  done: [
    time?: {
      hour: number
      minute: number
    },
  ]
}>()

const props = defineProps<{
  title: string

  initialTime: {
    hour: number
    minute: number
  }
}>()

const [h, m] = [props.initialTime.hour, props.initialTime.minute]
const hour = ref(h)
const minute = ref(m)

function onUpdateTime(h: number, m: number) {
  hour.value = h
  minute.value = m
}

function onDone() {
  emit('done', { hour: hour.value, minute: minute.value })
}
</script>

<style scoped></style>
