<template>
  <div class="h-full flex flex-col bg-ld-background">
    <AppBar
      :title="_t('账号与绑定')"
      @back="onBack"
    />

    <div class="flex flex-col gap-6 mt-4">
      <div class="flex flex-col gap-3 px-4">
        <div class="text-13px font-semibold text-ld-label-secondary">
          {{ _t('账号绑定') }}
        </div>

        <div class="w-full">
          <ListCell
            :title="_t('微信')"
            :label="wechatLabel"
          >
            <template #action>
              <TextButton
                v-if="!user.hasBoundWechat"
                :loading="wechatLoading"
                :label="_t('绑定')"
                @click="onBindWechat"
              ></TextButton>
              <div v-else></div>
            </template>
          </ListCell>

          <ListCell
            :title="_t('邮箱')"
            :label="user.email"
          >
            <template #action>
              <TextButton
                v-if="user.email != null"
                :loading="emailLoading"
                :label="_t('解绑')"
                @click="onUnbindEmail"
              ></TextButton>
              <TextButton
                v-else
                :loading="emailLoading"
                :label="_t('绑定')"
                @click="onBindEmail"
              ></TextButton>
            </template>
          </ListCell>

          <ListCell
            v-if="user.email != null"
            :title="_t('密码')"
          >
            <template #action>
              <TextButton
                :label="_t('修改')"
                @click="onChangePwd"
              ></TextButton>
            </template>
          </ListCell>
        </div>
      </div>

      <div class="flex flex-col gap-3 px-4">
        <div class="text-13px font-semibold text-ld-label-secondary">
          {{ _t('账户注销') }}
        </div>

        <div class="w-full">
          <ListCell
            :title="_t('删除我的账户和所有相关数据')"
            class="text-red"
            @click="onTerminate"
          >
            <template #action>
              <i class="pi pi-angle-right text-20px text-black"></i>
            </template>
          </ListCell>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ListCell from '@/components/ListCell/ListCell.vue'
import { useCommonStore } from '@/stores/common-store'
import { getCurrentUser } from '@/api/auth'
import BindEmailForm from './BindEmailForm.vue'
import BindWechat from './BindWechat.vue'
import PasswordChangeForm from './PasswordChangeForm.vue'
import { computed, ref } from 'vue'
import { bindWechat, unbindEmail } from '@/api/user'
import AppBar from '@/mobile/components/AppBar.vue'

const WECHAT_NICKNAME_LIMIT = 10

const store = useCommonStore()
const user = store.user!

const emit = defineEmits<{
  back: []
  terminate: []
}>()

const wechatLabel = computed(() => {
  if (user.hasBoundWechat) {
    const nickname = user.wechatInfo?.nickname ?? ''
    return nickname.length > WECHAT_NICKNAME_LIMIT
      ? `${nickname.slice(0, WECHAT_NICKNAME_LIMIT)}...`
      : nickname
  }
  return ''
})

function onChangePwd() {
  _openDialog(PasswordChangeForm, {
    title: _t('修改密码'),
    props: {
      email: user.email,
    },
  }).then(newPassword => {
    if (newPassword) {
      _message.success(_t('密码已修改'))
    }
  })
}

// 微信绑定解绑
const wechatLoading = ref(false)

function onBindWechat() {
  wechatLoading.value = true

  _openDialog(BindWechat, {
    title: _t('扫码绑定微信'),
  })
    .then(code => {
      if (typeof code === 'string') {
        bindWechat(code).then(res => {
          if (res.code === 0) {
            store.patchUser({ hasBoundWechat: true })
            _message.success(_t('微信绑定成功'))

            getCurrentUser().then(res => {
              store.patchUser({ wechatInfo: res.user.wechatInfo })
            })
            return
          }

          _message.info(res.message)
        })
      }
    })
    .finally(() => {
      wechatLoading.value = false
    })
}

// 邮箱绑定解绑

const emailLoading = ref(false)
function onBindEmail() {
  _openDialog(BindEmailForm, {
    title: _t('绑定邮箱'),
  })
}

function onUnbindEmail() {
  emailLoading.value = true
  unbindEmail()
    .then(res => {
      if (res.code === 0) {
        _message.success(_t('解绑成功'))
        user.email = undefined
        return
      }

      _message.error(res.message)
    })
    .finally(() => {
      emailLoading.value = false
    })
}

function onBack() {
  emit('back')
}

function onTerminate() {
  emit('terminate')
}
</script>
<style scoped></style>
