<template>
  <div class="h-full flex flex-col bg-ld-background g-safe-area">
    <SafeAreaTopSpacer />
    <AppBar
      title="设置"
      @back="onBack"
    />

    <UserSetting
      class="flex-1 p-4"
      @to-account="onToAccountPage"
      @to-operation-preference="onToOperationPreferencePage"
      @to-notification="onToNotificationPage"
    />
  </div>
</template>
<script setup lang="ts">
import UserSetting from '@/components/UserSetting/UserSetting.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({ name: 'mine' })
  }
}

function onToAccountPage() {
  router.push({ name: 'account-setting' })
}
function onToOperationPreferencePage() {
  router.push({ name: 'operation-preference' })
}
function onToNotificationPage() {
  router.push({ name: 'notification' })
}
</script>
<style scoped></style>
