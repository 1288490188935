import type {
  RouteLocationNormalized,
  RouteLocationRaw,
  Router,
} from 'vue-router'
import { RouterView } from 'vue-router'
import Store from '@/components/Store/Store.vue'
import { handleUnitCancelled } from '@/pages/UnitLearn/report'
import { CardTypeName, type PackageBasic } from '@/api/package-source'
import { CardType } from '@/types/core'
import PackageGetSheet from '@/components/PackageGetSheet.vue'
import PkgCreateDialog from '@/components/PkgCreateDialog/PkgCreateDialog.vue'
import { UserFlag } from '@/api/user'
import EnergyBuy from '@/components/EnergyBuy.vue'
import { fetchLeatestUnit } from '@/api/learn'

export const WX_CALLBACK_PATH = '/wxcallback'
export const LANDING_PATH = '/landing'
export const UNIT_LEARN_PATH = '/unit/learn'

export function setupHistoryMgmt(router: Router) {
  // 栈顶始终是当前 route
  // 注意，如果用户使用了浏览器的 back/forward button 会导致 history 不正确
  // 这种情况忽略，应该引导用户始终使用 App 提供的导航
  const history: RouteLocationNormalized[] = []
  let navType: 'push' | 'replace' | 'back' = 'push'

  router.__history = history
  Object.defineProperty(router, 'canGoBack', {
    get() {
      return history.length > 1
    },
  })

  const originalPush = router.push
  router.push = function (location: RouteLocationRaw) {
    navType = 'push'

    return originalPush.call(this, location)
  }

  const originalReplace = router.replace
  router.replace = function (location: RouteLocationRaw) {
    navType = 'replace'
    return originalReplace.call(this, location)
  }

  const originalBack = router.back
  router.back = function () {
    navType = 'back'
    if (history.length <= 1) {
      return
    }

    return originalBack.call(this)
  }

  // should not use router.go method
  router.go = undefined

  // history 管理
  router.afterEach((to, _from, failure) => {
    if (!failure) {
      switch (navType) {
        case 'push': {
          history.push(to)
          // history 存储太多没有意义
          if (history.length > 50) {
            history.splice(0, 40)
          }
          break
        }
        case 'replace': {
          history[history.length - 1] = to
          break
        }
        case 'back': {
          history.pop()
          break
        }
      }
    }
  })
}

export const DEBUG_ROUTE_PREFIX = '/debug/'
export const debugRoute = {
  path: DEBUG_ROUTE_PREFIX,
  component: RouterView,
  children: [
    {
      // 测试各种三方服务的 redirect
      path: '',
      component: () => import('@/pages/debug/DebugIndex.vue'),
    },
    {
      path: 'icon-bg',
      component: () => import('@/pages/debug/DebugIconBg.vue'),
    },
    {
      path: 'raw',
      component: () => import('@/pages/debug/DebugRaw.vue'),
    },
    {
      path: 'button',
      component: () => import('@/pages/debug/DebugButton.vue'),
      name: 'debug/button',
    },
    {
      path: 'card-layout',
      component: () => import('@/pages/debug/DebugCardLayout.vue'),
      name: 'debug/card-layout',
    },
    {
      path: 'editor',
      component: () => import('@/pages/debug/DebugEditor.vue'),
      name: 'debug/editor',
    },
    {
      path: 'lesson-end',
      component: () => import('@/pages/debug/DebugLessonEnd.vue'),
      name: 'debug/lesson-end',
    },
    {
      path: 'check-in',
      component: () => import('@/pages/debug/DebugCheckIn.vue'),
      name: 'debug/check-in',
    },
    {
      path: 'column-layout',
      component: () => import('@/pages/debug/DebugColumnLayout.vue'),
      name: 'debug/column-layout',
    },
    {
      path: 'boss',
      component: () => import('@/pages/debug/DebugBoss.vue'),
      name: 'debug/boss',
    },
    {
      path: 'text-button',
      component: () => import('@/pages/debug/DebugTextButton.vue'),
      name: 'debug/text-button',
    },
    {
      path: 'rank',
      component: () => import('@/pages/debug/DebugRank.vue'),
      name: 'debug/rank',
    },
  ],
}

export function openStore(props?: InstanceType<typeof Store>['$props']) {
  _openDialog(Store, {
    fullscreenInMobile: true,
    props,
    dialog: {
      showHeader: false,
      pt: {
        root: {
          // TODO(buding): uno class 写在 rootClass 中不会自动生成对应的 class
          // 这里先放在 style 中
          style: 'height: 600px; width: 400px',
        },
        content: { style: 'padding: 0px;' },
      },
    },
  })
}

export function initPlausible() {
  if (location.port === '') {
    const script = document.createElement('script')
    script.src = 'https://plausible.lingduck.top/js/script.js'
    script.defer = true
    script.dataset.domain = location.hostname
    script.setAttribute(
      'event-client',
      `${_global.clientType}.${_global.clientChannel}`
    )
    document.head.appendChild(script)
  }
}

export function afterAppMount() {
  // 提醒当前连接的是 prod api
  // 防止忘记导致污染了用户数据
  if (!_global.isProd && _global.apiEnv === 'prod') {
    const res = _confirm({
      scene: 'warn',
      content: '请注意，当前连接的是生产 API',
      primaryText: '继续连接生产 API',
      secondaryText: '取消连接',
      onSecondaryClick(resolve) {
        _db.debug.apiEnv = 'staging'
        _refreshApp()
        resolve(true)
      },
    })
    res.instance.shouldIgnoreByCloseAll = true
  }
}

let showUnitContinueDialog = false
export async function confirmStageUnitContinue(router: Router) {
  if (location.pathname === UNIT_LEARN_PATH) return

  // 先检查本地的学习单元是否可用
  const isLocalUnitAvaiable = _store.checkStageUnit()

  if (isLocalUnitAvaiable && !showUnitContinueDialog) {
    // 这里如果获取最近一次学习单元失败，就不用弹出弹窗
    return fetchLeatestUnit()
      .then(res => {
        // 如果最近一次的学习单元和本地不是同一个，直接清除本地的单元，无需提醒
        if (res.unit.id !== _store.stageUnit?.unitId) {
          _store.clearStageUnit()
          return
        }

        showUnitContinueDialog = true

        return _confirm({
          scene: 'warn',
          content: _t('上次学习中断了\n是否要恢复进度、继续学习?'),
          primaryText: _t('恢复进度、继续学习'),
          secondaryText: _t('放弃进度、不学了'),
          onPrimaryClick(resolve) {
            resolve(true)
            router.push({
              name: 'unit/learn',
            })
          },
          onSecondaryClick(resolve) {
            resolve(true)
            handleUnitCancelled()
            _store.clearStageUnit()
          },
        })
      })
      .finally(() => {
        showUnitContinueDialog = false
      })
  }
}

export function convertCardTypeToCardName(cardType: CardType): CardTypeName {
  return {
    [CardType.CLOZE]: CardTypeName.CLOZE,
    [CardType.EN_WORD]: CardTypeName.WORD,
    [CardType.MCQ]: CardTypeName.MCQ,
  }[cardType]
}

export function convertCardNameToCardType(cardName: CardTypeName): CardType {
  return {
    [CardTypeName.CLOZE]: CardType.CLOZE,
    [CardTypeName.WORD]: CardType.EN_WORD,
    [CardTypeName.MCQ]: CardType.MCQ,
  }[cardName]
}

export function openPackageGetSheet(props: {
  onCreate: VoidFunction
  onSearch?: VoidFunction
}) {
  _presentContent(PackageGetSheet, {
    rootClass: 'min-w-400px max-w-600px',
    dialog: {
      contentStyle: 'padding: 0px;',
    },
    props: {
      onCreate: props.onCreate,
      onSearch: props.onSearch,
    },
  })
}

export function openPackageCreateDialog(props: {
  onCreate: (pkg: PackageBasic) => void
}) {
  _openDialog(PkgCreateDialog, {
    title: '新建卡包',
    props: {
      onCreate: props.onCreate,
    },
    rootClass: 'p-0 g-dialog',
    dialog: {
      showHeader: false,
      contentClass: 'px-4 py-3',
    },
  })
}

// isNotEnoughEnergy: 是否时在体力不足时弹出的，此时需要检查免费体力的领取状态，特殊处理
export function showEnergyDialog(isNotEnoughEnergy?: boolean): Promise<void> {
  const freeEnergyUsed = _store.user!.flag?.[UserFlag.FreeEnergyUsed] ?? false
  const showFreeEnergy = isNotEnoughEnergy && !freeEnergyUsed

  return _presentContent(EnergyBuy, {
    rootClass: 'w-500px',
    props: {
      showFreeEnergy,
    },
    dialog: {
      showHeader: false,
      contentStyle: 'padding: 0px;',
    },
  })
}
