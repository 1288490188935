import { Extension } from '@tiptap/core'
import type { Editor } from '@tiptap/core'

function parseTiptapHtml(html: string): string | null {
  const el = document.createElement('span')
  el.innerHTML = html

  const p = el.querySelector('p[data-pm-slice]')

  if (p != null) {
    p.replaceWith(...p.childNodes)
    return el.outerHTML
  }

  return null
}

// 判断一段文本是不是一整个 cloze span
// 如果是，则返回挖空中的文本内容
// 否则返回 null
function parseSingleClozeText(html: string): string | null {
  const el = document.createElement('div')
  el.innerHTML = html
  const rootSpan = el.querySelector('div > span')

  if (rootSpan == null) {
    return null
  }

  const spanChildren = rootSpan.querySelectorAll('span')

  if (spanChildren.length === 1 && spanChildren[0].dataset['cloze'] != null) {
    return spanChildren[0].innerText
  }

  return null
}

async function onPaste(editor: Editor) {
  const clipboardContentArray = await navigator.clipboard.read()

  if (clipboardContentArray.length == 0) {
    return
  }

  const clipboardContent = clipboardContentArray[0]

  if (clipboardContent.types.includes('text/html')) {
    let html = await (await clipboardContent.getType('text/html')).text()
    const parsedHtml = parseTiptapHtml(html)

    // 粘贴的挖空不会和前面的挖空合并，刷新之后再次编辑才会合并
    if (parsedHtml) {
      // 先判断粘贴的内容是不是一整个挖空，如果粘贴的内容是整个挖空
      // 而且当前是在一个挖空内，则直接插入文本即可，否则会变成两个挖空
      const clozeText = parseSingleClozeText(parsedHtml)

      if (clozeText != null && editor.isActive('cloze')) {
        editor.commands.insertContent(clozeText)
      } else {
        editor.commands.insertContent(parsedHtml)
      }
    } else {
      editor.commands.insertContent(html)
    }
  } else if (clipboardContent.types.includes('text/plain')) {
    let text = await (await clipboardContent.getType('text/plain')).text()

    editor.commands.insertContent(text)
  }
}

const CustomPaste = Extension.create({
  name: 'custom_paste',

  addKeyboardShortcuts() {
    return {
      'Mod-v': ({ editor }) => {
        onPaste(editor)
        return true
      },
      'Mod-V': ({ editor }) => {
        onPaste(editor)
        return true
      },
    }
  },
})

export default CustomPaste
