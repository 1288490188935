import { reportUnitCancel, reportUnitComplete } from '@/api/learn'
import { computed, reactive } from 'vue'

import type {
  DailyTaskResponse,
  UnitCompleteStat,
  UnitRankResponse,
} from '@/api/learn'
import { LearnStatus } from '@/types/core'
import bus, { BusEvent } from '@/bus/bus'
import {
  checkInStats,
  type CheckInCtRes,
  type CheckInStatsItem,
} from '@/api/user'
import { formatDate } from '@/utils'
import { sum } from 'lodash-es'
import { AtlasStageMode } from '@/api/atlas'

const state = reactive({
  cardReports: {} as Record<number, Promise<void> | null>,
  completeReport: null as Promise<void> | null,
  completedStats: null as UnitCompleteStat | null,
  mode: undefined as AtlasStageMode | undefined,
  dailyTaskResponse: null as DailyTaskResponse | null,
  unitRankResponse: undefined as UnitRankResponse | undefined,
  checkInData: null as {
    checkInCtRes: CheckInCtRes
    stats: CheckInStatsItem[]
  } | null,
})

export const completedStats = computed(() => state.completedStats)
export const dailyTaskResponse = computed(() => state.dailyTaskResponse)
export const beforeRankGroup = computed(
  () => state.unitRankResponse?.beforeGroup
)
export const afterRankGroup = computed(() => state.unitRankResponse?.afterGroup)
export const checkInData = computed(() => state.checkInData)
export const mode = computed(() => state.mode)

export const pending = computed(
  () =>
    state.completeReport != null ||
    Object.values(state.cardReports).some(item => item != null)
)

bus.on(BusEvent.StageUnitClear, function clearState() {
  state.cardReports = {}
  state.completeReport = null
  state.completedStats = null
  state.dailyTaskResponse = null
  state.checkInData = null
  state.mode = undefined
})

export async function completeReport(leftBlood?: number) {
  if (_store.stageUnit == null) return null

  _store.completeStageUnit()

  // 兼容本地处理
  if (_store.stageUnit.isDebug) {
    const schedules = _store.stageUnit.schedules
    state.completedStats = {
      unitId: 0,
      debutCount: schedules.filter(
        item => item.cardStatus === LearnStatus.DEBUT
      ).length,
      reviewCount: schedules.filter(
        item => item.cardStatus === LearnStatus.REVIEW
      ).length,
      comboCount: 0,
      duration: sum(Object.values(_store.stageUnit.cardDurations)),
      exp: 20,
      comboExp: 10,
      star: 3,
      checkInPopped: true,
    }
    state.mode = _store.passionateMode
      ? AtlasStageMode.PASSIONATE
      : AtlasStageMode.NORMAL
  }

  // 当调用完成时的上传，需要检测所有的卡片数据是否已经上传完成
  await Promise.allSettled(Object.values(state.cardReports))

  if (state.completeReport) return

  if (state.completedStats) return

  state.completeReport = reportUnitComplete({
    unitId: _store.stageUnit.unitId,
    learnEvents: _store.stageUnit.events,
    cardDurations: _store.stageUnit.cardDurations,
    cardLearnTimes: _store.stageUnit.cardLearnTimes,
    comboCount: _store.stageUnit.maxComboCount,
    challengeStageParam:
      leftBlood == null
        ? undefined
        : {
            duelParam: {
              leftBlood,
            },
          },
  })
    .then(res => {
      const reportedCardIds = Object.keys(_store.stageUnit!.events).map(Number)

      reportedCardIds.forEach(_store.clearCardEvents)
      state.completedStats = res.stats
      state.mode = res.mode
      state.dailyTaskResponse = res.dailyTaskResponse
      state.unitRankResponse = res.unitRankResponse
      if (res.stats != null) {
        getCheckData()
        // 上报完成后，重新获取用户资产
        _store.fetchUserAssets()
        _store.completeStageUnit()
        _store.fetchEnergyStatus()
        _store.fetchStatsOverview()

        // 通知闯关页面更新一下关卡的信息
        bus.emit(BusEvent.StageUnitComplete)

        // 如果闯关完成，此时可能会进入榜单，所以需要再去获取一下排位赛的引导状态
        _store.fetchRankGuide()
      }
    })
    .finally(() => {
      state.completeReport = null
    })
}

export function handleUnitCancelled() {
  if (_store.stageUnit == null) return

  if (_store.stageUnit.isDebug) {
    _store.clearStageUnit()
    return
  }

  // 这个事件不用等待
  reportUnitCancel({
    unitId: _store.stageUnit.unitId,
    learnEvents: _store.stageUnit.events,
  })

  _store.fetchEnergyStatus()
  _store.clearStageUnit()
}

// 上报完成后 获取签到数据
export async function getCheckData() {
  const checkInPopped = state.completedStats?.checkInPopped

  if (checkInPopped) return

  const checkInCtRes = await _store.fetchCheckInCtRes()
  // 本周签到数据

  const now = new Date()
  const week = now.getDay()
  // 本周第一天
  const currentWeekFirstDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - (week == 0 ? 7 : week) + 1
  )

  // 本周最后一天
  const currentMonthLastDay = new Date(currentWeekFirstDay)
  currentMonthLastDay.setDate(currentWeekFirstDay.getDate() + 6)
  const stats = (
    await checkInStats(
      formatDate(currentWeekFirstDay, 'YYYY-MM-DD'),
      formatDate(currentMonthLastDay, 'YYYY-MM-DD')
    )
  ).stats
  state.checkInData = {
    checkInCtRes,
    stats,
  }
}
