<template>
  <div class="flex flex-col gap-10px">
    <div
      ref="textRef"
      :class="[expand ? 'line-clamp-none' : 'line-clamp-5']"
    >
      <slot></slot>
    </div>

    <div
      v-if="showExpand"
      class="flex items-center gap-1 text-blue-500 cursor-pointer self-end"
      @click.stop="expand = !expand"
    >
      <div class="w-16px h-16px">
        <Icon
          v-if="expand"
          name="collapse"
        ></Icon>
        <Icon
          v-else
          name="expand"
        ></Icon>
      </div>
      <span class="text-15px leading-none">
        {{ expand ? _t('收起') : _t('展开') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isTextTruncated } from '@/utils'
import { onMounted, onUnmounted, ref } from 'vue'

const expand = ref(false)
const textRef = ref<HTMLElement>()

const showExpand = ref(false)

function resize() {
  if (!textRef.value) return
  // 当已经展开时不再计算
  // 这里有个问题，如果重新计算，这个值会因为已经展开了而不再显示【收起】按钮
  // 如果不重新计算，内容变化足够显示了，但是【展开】按钮还在。
  // 和卡拉赞确认过了 不用改，不需要太精确
  if (expand.value) return
  showExpand.value = isTextTruncated(textRef.value)
}

onMounted(() => {
  resize()

  window.addEventListener('resize', resize)
})
onUnmounted(() => {
  window.removeEventListener('resize', resize)
})
</script>

<style scoped></style>
