<template>
  <div ref="mainRef">
    <div>
      <label
        data-required
        class="block text-900 text-xl font-medium"
      >
        单词
      </label>

      <Textarea
        class="mt-2 w-full"
        autoResize
        :modelValue="props.card.word"
        maxlength="1000"
        :pt="{ root: { 'data-focus': 'true' } }"
        @update:modelValue="onChange({ word: $event })"
      />
    </div>
    <div class="mt-4">
      <div class="hstack">
        <label
          data-required
          class="block text-900 text-xl font-medium"
        >
          发音
        </label>
        <TextButton
          class="ml-auto"
          label="Add"
          @click="onAddPron"
        />
      </div>

      <div class="vstack">
        <div
          v-for="(o, idx) in props.card.prons"
          :key="idx"
          class="flex flex-col w-full mt-2 border p-2 space-y-2"
        >
          <i
            class="pi pi-times cursor-pointer ml-auto"
            @click="
              onChange({ prons: props.card.prons.filter((_, i) => i !== idx) })
            "
          ></i>
          <Textarea
            :model-value="o.label"
            autoResize
            rows="1"
            maxlength="1000"
            :placeholder="_t('请填写发音文本')"
            @update:modelValue="onPronLabelUpdate(idx, $event)"
          />
          <SelectButton
            :modelValue="o.language"
            :options="languages"
            :allowEmpty="false"
            @update:modelValue="onPronLanguageUpdate(idx, $event)"
          ></SelectButton>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <label
        data-required
        class="block text-900 text-xl font-medium"
      >
        释义
      </label>

      <Textarea
        class="mt-2 w-full"
        autoResize
        maxlength="1000"
        :modelValue="props.card.definition"
        @update:modelValue="onChange({ definition: $event })"
      />
    </div>
    <div class="mt-4">
      <div class="hstack">
        <label
          data-required
          class="block text-900 text-xl font-medium"
        >
          干扰选项
        </label>
        <TextButton
          class="ml-auto"
          label="Add"
          @click="onAddDistrators"
        />
      </div>

      <div class="vstack">
        <div
          v-for="(o, idx) in props.card.distrators"
          :key="idx"
          class="flex flex-col w-full mt-2 border p-2 space-y-2"
        >
          <i
            class="pi pi-times cursor-pointer ml-auto"
            @click="
              onChange({
                distrators: props.card.distrators.filter((_, i) => i !== idx),
              })
            "
          ></i>
          <Textarea
            class="mt-2 w-full"
            maxlength="1000"
            auto-resize
            :modelValue="o.word"
            placeholder="word"
            @update:modelValue="onDistractionWordUpdate(idx, $event)"
          />
          <Textarea
            class="mt-2 w-full"
            maxlength="1000"
            auto-resize
            :modelValue="o.definition"
            placeholder="definition"
            @update:modelValue="onDistractionDefinitionUpdate(idx, $event)"
          />
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="hstack">
        <label
          data-required
          class="block text-900 text-xl font-medium"
        >
          例句
        </label>
        <TextButton
          class="ml-auto"
          label="Add"
          @click="onAddSentence"
        />
      </div>

      <div class="vstack">
        <div
          v-for="(o, idx) in props.card.examples"
          :key="idx"
          class="flex flex-col w-full mt-2 border p-2 space-y-2"
        >
          <i
            class="pi pi-times cursor-pointer ml-auto"
            @click="
              onChange({
                examples: props.card.examples.filter((_, i) => i !== idx),
              })
            "
          ></i>
          <Textarea
            maxlength="1000"
            :modelValue="o.source"
            auto-resize
            :placeholder="_t('请填写例句')"
            @update:modelValue="onExampleSourceUpdate(idx, $event)"
          />
          <Textarea
            maxlength="1000"
            :modelValue="o.translation"
            auto-resize
            :placeholder="_t('请填写翻译')"
            @update:modelValue="onExampleTranslationUpdate(idx, $event)"
          />
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="hstack">
        <label class="block text-900 text-xl font-medium">Tab</label>
        <TextButton
          class="ml-auto"
          label="Add"
          @click="onAddTab"
        />
      </div>

      <div class="vstack">
        <div
          v-for="(o, idx) in props.card.tabs"
          :key="idx"
          class="flex flex-col w-full mt-2 border p-2 space-y-2"
        >
          <i
            class="pi pi-times cursor-pointer ml-auto"
            @click="
              onChange({ tabs: props.card.tabs.filter((_, i) => i !== idx) })
            "
          ></i>

          <Textarea
            maxlength="1000"
            :modelValue="o.title"
            auto-resize
            placeholder="title"
            @update:modelValue="onTabTitleUpdate(idx, $event)"
          />
          <ClozeEditor
            class="px-4 py-2 border-solid border-1 rounded-8px border-[var(--surface-300)]"
            maxlength="1000"
            :toolbtns="false"
            :content="o.content"
            :limit="1000"
            @update="onTabContentUpdate(idx, $event)"
          />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <label class="block text-900 text-xl font-medium">配图</label>

      <InputText
        maxlength="1000"
        class="mt-2 w-full"
        autoResize
        placeholder="上传图片后的 assetId"
        :modelValue="props.card.illustration"
        @update:modelValue="onChange({ illustration: $event })"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CardType } from '@/types/core'
import SelectButton from 'primevue/selectbutton'
import Textarea from 'primevue/textarea'
import { ref, onMounted } from 'vue'
import ClozeEditor from '@/components/ClozeEditor/ClozeEditor.vue'

import type { Content, EnWordCard, PronunciationLanguage } from '@/types/core'

const props = defineProps<{
  card: EnWordCard
}>()

const emit = defineEmits<{
  change: [EnWordCard]
}>()

const languages = ['en-US', 'en-GB']

function onAddDistrators() {
  onChange({
    distrators: [...props.card.distrators, { word: '', definition: '' }],
  })
}
function onAddSentence() {
  onChange({
    examples: [
      ...props.card.examples,
      {
        source: '',
        translation: '',
      },
    ],
  })
}
function onAddTab() {
  onChange({
    tabs: [
      ...props.card.tabs,
      {
        title: '',
        content: [],
      },
    ],
  })
}
function onAddPron() {
  onChange({
    prons: [
      ...props.card.prons,
      {
        label: '美式',
        language: 'en-US',
      },
    ],
  })
}

function onPronLabelUpdate(idx: number, label: string) {
  const prons = [...props.card.prons]
  const pron = prons[idx]
  prons.splice(idx, 1, { ...pron, label })

  onChange({ prons })
}
function onPronLanguageUpdate(idx: number, language: PronunciationLanguage) {
  const prons = [...props.card.prons]
  const pron = prons[idx]
  prons.splice(idx, 1, { ...pron, language })

  onChange({ prons })
}

function onTabTitleUpdate(idx: number, title: string) {
  const tabs = [...props.card.tabs]
  const tab = tabs[idx]
  tabs.splice(idx, 1, { ...tab, title })

  onChange({ tabs })
}
function onTabContentUpdate(idx: number, content: Content) {
  const tabs = [...props.card.tabs]
  const tab = tabs[idx]
  tabs.splice(idx, 1, { ...tab, content })

  onChange({ tabs })
}
function onExampleSourceUpdate(idx: number, source: string) {
  const examples = [...props.card.examples]
  const example = examples[idx]
  examples.splice(idx, 1, { ...example, source })

  onChange({ examples })
}
function onExampleTranslationUpdate(idx: number, translation: string) {
  const examples = [...props.card.examples]
  const example = examples[idx]
  examples.splice(idx, 1, { ...example, translation })

  onChange({ examples })
}

function onDistractionWordUpdate(idx: number, word: string) {
  const distrators = [...props.card.distrators]
  const distrator = distrators[idx]
  distrators.splice(idx, 1, { ...distrator, word })

  onChange({ distrators })
}
function onDistractionDefinitionUpdate(idx: number, definition: string) {
  const distrators = [...props.card.distrators]
  const distrator = distrators[idx]
  distrators.splice(idx, 1, { ...distrator, definition })

  onChange({ distrators })
}

function onChange({
  word,
  definition,
  distrators,
  examples,
  prons,
  tabs,
  illustration,
}: {
  word?: EnWordCard['word']
  definition?: EnWordCard['definition']
  distrators?: EnWordCard['distrators']
  examples?: EnWordCard['examples']
  prons?: EnWordCard['prons']
  tabs?: EnWordCard['tabs']
  illustration?: EnWordCard['illustration']
}) {
  emit('change', {
    type: CardType.EN_WORD,
    word: word ?? props.card.word,
    definition: definition ?? props.card.definition,
    examples: examples ?? props.card.examples,
    distrators: distrators ?? props.card.distrators,
    tabs: tabs ?? props.card.tabs,
    prons: prons ?? props.card.prons,
    illustration: illustration ?? props.card.illustration,
  })
}

// auto focus
const mainRef = ref()
onMounted(() => {
  setTimeout(() => {
    mainRef.value.querySelector('[data-focus]').focus()
  }, 0)
})
</script>

<style scoped></style>
