<template>
  <Loading v-if="loading" />

  <DataTable
    :expandedRows="expandedRows"
    :value="units"
    dataKey="id"
    @rowExpand="onRowExpand"
  >
    <Column
      expander
      style="width: 5rem"
    />

    <Column
      field="id"
      header="id"
    ></Column>

    <Column
      field="createdAt"
      header="创建时间"
    >
      <template #body="slotProps">
        {{ formatDate(slotProps.data.createdAt) }}
      </template>
    </Column>

    <Column header="状态">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.completedAt">
          完成({{ formatDate(slotProps.data.completedAt) }})
        </Tag>

        <Tag
          v-else-if="slotProps.data.cancelledAt"
          severity="danger"
        >
          取消({{ formatDate(slotProps.data.cancelledAt) }})
        </Tag>

        <Tag
          v-else
          severity="warning"
        >
          进行中
        </Tag>
      </template>
    </Column>

    <template #expansion="slotProps">
      <div class="p-3">
        <h5>详细日志： {{ slotProps.data.id }}</h5>

        <DataTable :value="unitLogs[slotProps.data.id]">
          <Column
            field="cardId"
            header="卡片 id"
          ></Column>

          <Column
            field="detail.rateType"
            header="rating"
          ></Column>

          <Column header="上报时间">
            <template #body="sp0">
              {{ formatDate(sp0.data.createdAt) }}
            </template>
          </Column>

          <Column header="详细日志">
            <template #body="sp1">
              <DataTable :value="sp1.data.detail.events">
                <Column
                  field="event"
                  header="类型"
                ></Column>

                <Column
                  field="stage"
                  header="阶段"
                ></Column>

                <Column header="时间">
                  <template #body="sp2">
                    {{ formatDate(sp2.data.timestamp) }}
                  </template>
                </Column>
              </DataTable>
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </DataTable>
</template>

<script lang="ts" setup>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import { ref } from 'vue'
import { fetchUnits, fetchUnitLogs } from '@/api/debug'
import { formatDate } from '@/utils'

import type { DataTableRowExpandEvent } from 'primevue/datatable'
import type { Unit, UnitLog } from '@/api/debug'

defineEmits<{
  done: []
}>()

const loading = ref(true)
const units = ref<Unit[]>([])
const unitLogs = ref<Record<number, UnitLog[]>>({})
const expandedRows = ref<Record<string, boolean>>({})

async function onRowExpand(event: DataTableRowExpandEvent) {
  if (unitLogs.value[event.data.id]) return

  const res = await fetchUnitLogs(event.data.id)

  unitLogs.value[event.data.id] = res.learnLogs
  expandedRows.value[event.data.id] = true
}

fetchUnits().then(res => {
  units.value = res.units
  loading.value = false
})
</script>

<style scoped></style>
