export enum RankType {
  // 青铜
  Bronze = 'BRONZE',

  // 白银
  Silver = 'SILVER',

  // 黄金
  Gold = 'GOLD',

  // 铂金
  Platinum = 'PLATINUM',

  // 翡翠
  Emerald = 'EMERALD',

  // 钻石
  Diamond = 'DIAMOND',

  // 王者
  Legendary = 'LEGENDARY',
}

// 这个是后端返回的段位数据，需要经过解析后得到 Rank 类型
// eg: BRONZE | PLATINUM | LEGENDARY | LEGENDARY10
export type RankResponse = string

export type Rank = {
  // 段位
  type: RankType

  // 段位星级，目前只有王者段位才会有星级
  star?: number
}

// 赛季结果
export enum SeasonResult {
  // 上升一个段位
  Up1 = 'UP_1',

  // 上升两个段位
  Up2 = 'UP_2',

  // 保持当前段位
  Keep = 'KEEP',

  // 下降一个段位
  Down1 = 'DOWN_1',

  // 没有参与赛季
  Missed = 'MISSED',
}

export enum RankHomeStatus {
  // 显示上个赛季（时间）的结果
  NotClaimed = 'NOT_CLAIMED',

  // 当前用户未开启排位赛功能
  RankFeatureDisabled = 'RANK_FEATURE_DISABLED',

  // 当前用户不满足进入赛季的条件
  NotQualified = 'NOT_QUALIFIED',

  // 当前是休赛期
  BreakTime = 'BREAK_TIME',

  // 当前是冻结期，未加入榜单的用户无法参与赛季
  FreezeTime = 'FREEZE_TIME',

  // 当前正在榜单中，显示用户所在的榜单
  InGroup = 'IN_GROUP',
}

// 显示上个赛季（时间）的结果
export type RankHomeNotClaimed = {
  type: RankHomeStatus.NotClaimed
  data: {
    id: number
    result: SeasonResult

    beforeRank: RankResponse

    afterRank: RankResponse

    // 榜单内的排名
    groupPosition: number

    // 付费保级消耗的钻石
    keepRankDiamond?: number
  }
}

// 当前用户未开启排位赛功能
export type RankHomeRankFeatureDisabled = {
  type: RankHomeStatus.RankFeatureDisabled
  data: {
    // 世界地图的最低等级
    requiredAtlasLevel: number
  }
}

// 当前用户不满足进入赛季的条件
export type RankHomeNotQualified = {
  type: RankHomeStatus.NotQualified
  data: {
    currentRank: RankResponse
    seasonEndTime: string

    // 多少名可以晋级
    upCount: number
  }
}

// 当前是休赛期
// 未参与榜单的用户不会有休赛期的状态
export type RankHomeBreakTime = {
  type: RankHomeStatus.BreakTime
  data: {
    nextSeasonStartTime: string
  }
}

// 当前是冻结期，未加入榜单的用户无法参与赛季
export type RankHomeFreezeTime = {
  type: RankHomeStatus.FreezeTime
  data: {
    nextSeasonStartTime: string
  }
}

export type RankUser = {
  userId: string
  nickname: string
  avatar: string // assetId
  exp: number // 当前赛季经验值
}

// 显示用户所在的榜单
export type RankHomeInGroup = {
  type: RankHomeStatus.InGroup
  data: {
    groupRank: RankResponse
    seasonEndTime: string

    // 当前用户在什么名次 1-base
    position: number

    // 前多少名可以晋级
    upCount: number

    // 前多少名可以跳级
    up2Count: number

    // 后多少名之后会降级 0-base，如果返回的是 25 则 [26, 30] 名降级
    down1Index: number

    // 降级的用户会回落到什么段位
    down1Rank: RankResponse
    users: RankUser[]
  }
}

export type RankHome =
  | RankHomeNotClaimed
  | RankHomeRankFeatureDisabled
  | RankHomeNotQualified
  | RankHomeBreakTime
  | RankHomeFreezeTime
  | RankHomeInGroup
