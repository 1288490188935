<template>
  <div class="w-full">
    <div class="flex justify-center items-center w-full h-60px gap-4">
      <Button
        class="flex-1 h-full"
        scene="secondary"
        @click="emit('createCard')"
      >
        <div
          class="flex-1 flex items-center justify-center gap-10px text-ld-brand-500"
        >
          <Icon name="add_card" />
          {{ _t('新建卡片') }}
        </div>
      </Button>

      <Button
        class="flex-1 h-full"
        :disabled="props.aiButtonDisabled"
        @click="emit('aiGenerate')"
      >
        <div
          class="flex flex-col justify-center items-center gap-1 w-full whitespace-nowrap text-center font-600"
        >
          <div class="flex gap-1">
            <Icon
              name="ai-add-card"
              class="h-20px"
            />
            <div class="leading-20px">{{ _t('AI 智能建卡') }}</div>
          </div>

          <div class="text-13px leading-[1.4]">
            {{
              props.aiButtonDisabled
                ? _t('暂未开放，尽请期待')
                : _t('内测期间 限时免费')
            }}
          </div>
        </div>
      </Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<{
  aiButtonDisabled: boolean
}>()

const emit = defineEmits<{
  createCard: []
  aiGenerate: []
}>()
</script>
<style scoped></style>
