import { startAtlasChallengeStage, startAtlasStage } from '@/api/atlas'
import { Code } from '@/api/code'
import { UnitEventType } from '@/types/core'
import { reportUnitComplete, type UnitEvent } from '@/api/learn'
import { guestLogin } from './api/debug'
import { afterLogin } from './shared/landing'
import { type RouteLocationNormalizedLoaded, type Router } from 'vue-router'

export function debugFinishStage(packageId: number): Promise<boolean> {
  if (_global.isProd) return Promise.resolve(false)

  return startAtlasStage(packageId).then(async res => {
    if (res.code !== Code.Ok) {
      _message.error(res.message)
      return false
    }

    const mockLearnEvents: Record<string, UnitEvent[]> = {}
    const mockCardDurations: Record<number, number> = {}
    const mockCardLearnTimes: Record<number, number> = {}

    for (const item of res.data.schedules) {
      mockLearnEvents[item.cardId] = [
        {
          event: UnitEventType.CORRECT,
          timestamp: Date.now(),
        },
      ]
      mockCardDurations[item.cardId] = 10
      mockCardLearnTimes[item.cardId] = 2
    }

    await reportUnitComplete({
      unitId: res.data.unitId,
      learnEvents: mockLearnEvents,
      cardDurations: mockCardDurations,
      cardLearnTimes: mockCardLearnTimes,
      comboCount: res.data.schedules.length,
    })

    _store.fetchRankGuide()
    return true
  })
}

export async function debugFinishChallengeStage(
  index: number,
  startLevel: number,
  endLevel: number,
  star: 1 | 2 | 3
): Promise<boolean> {
  if (_global.isProd) return Promise.resolve(false)

  return startAtlasChallengeStage({
    index: index,
    startLevel,
    endLevel,
  }).then(async res => {
    if (res.code !== Code.Ok) {
      _message.error(res.message)
      return false
    }

    const mockLearnEvents: Record<string, UnitEvent[]> = {}
    const mockCardDurations: Record<number, number> = {}
    const mockCardLearnTimes: Record<number, number> = {}

    for (const item of res.data.schedules) {
      mockLearnEvents[item.cardId] = [
        {
          event: UnitEventType.CORRECT,
          timestamp: Date.now(),
        },
      ]
      mockCardDurations[item.cardId] = 10
      mockCardLearnTimes[item.cardId] = 2
    }

    await reportUnitComplete({
      unitId: res.data.unitId,
      learnEvents: mockLearnEvents,
      challengeStageParam: {
        duelParam: {
          leftBlood: {
            1: 0.5,
            2: 0.7,
            3: 1,
          }[star],
        },
      },
      cardDurations: mockCardDurations,
      cardLearnTimes: mockCardLearnTimes,
      comboCount: res.data.schedules.length,
    })

    _store.fetchRankGuide()
    return true
  })
}

export function setDebugInitialUser(userId: string) {
  if (_global.isProd) return
  _db.debug.initialUserId = userId
}

export function debugGuestLogin(
  router: Router,
  route: RouteLocationNormalizedLoaded
) {
  guestLogin().then(res => {
    _message.info('访客登录成功')
    _store.login(res.token, res.user, true)
    afterLogin(router, route)
  })
}
