<template>
  <div class="flex flex-col text-center p-4 g-safe-area">
    <div
      class="flex-1 flex flex-col justify-center self-center g-body-width overflow-hidden"
    >
      <div class="flex-1 relative overflow-hidden">
        <LottieBox
          name="check-mark"
          :loop="false"
          :autoplay="true"
        />
        <LottieBox
          name="scatter-flowers"
          :loop="false"
          :autoplay="true"
          class="position-absolute top-0 left-0 w-full h-full scale-120"
        />
      </div>

      <div
        class="text-25px mb-60px flex items-center justify-center color-[var(--yellow-500)] font-bold space-x-4"
      >
        <Icon
          name="wheat-left"
          class="w-16px"
        />
        <span>{{ _t('学习完成') }}</span>
        <Icon
          name="wheat-right"
          class="w-16px"
        />
      </div>
      <div class="flex justify-center text-center gap-4">
        <AnimationLabel
          text="学习时长"
          style="--color-main: var(--yellow-500)"
          :index="0"
          class="flex-1"
        >
          <Icon
            name="clock-circle"
            class="mr-1 w-25px"
          />
          <AnimationNum :value="formatLearnDuration(stat.duration)" />
        </AnimationLabel>

        <AnimationLabel
          text="学习卡片"
          style="--color-main: var(--green-500)"
          :index="1"
          class="flex-1"
        >
          <Icon
            name="document-add"
            class="mr-1 w-24px"
          />
          <AnimationNum :value="cardCount" />
        </AnimationLabel>

        <AnimationLabel
          v-if="props.mode === AtlasStageMode.PASSIONATE"
          :text="_t('获取经验')"
          style="--color-main: var(--rose-500)"
          :index="2"
          class="flex-1"
        >
          <Icon
            name="crazy-mode"
            class="mr-1 w-24px"
          />

          <span class="text-17px font-semibold">{{ _t('狂热中') }}</span>
        </AnimationLabel>

        <AnimationLabel
          v-else-if="stat.comboExp > 0"
          :text="_t(`${stat.comboCount} 连击`)"
          style="--color-main: var(--ld-brand-500)"
          :index="2"
          class="flex-1"
        >
          <Icon
            name="exp"
            class="mr-1 w-25px"
          />

          <AnimationNum :value="`${baseExp}+${stat.comboExp}`" />
        </AnimationLabel>

        <AnimationLabel
          v-else
          :text="_t('获得经验')"
          :index="2"
          style="--color-main: var(--ld-brand-500)"
          class="flex-1"
        >
          <Icon
            name="exp"
            class="mr-1 w-25px"
          />

          <AnimationNum :value="stat.exp" />
        </AnimationLabel>
      </div>
    </div>

    <div class="mt-80px w-full flex gap-2 g-footer-width mb-4">
      <Button
        :label="_t('学习回顾')"
        class="basis-1/3 whitespace-nowrap"
        scene="secondary"
        @click="onCardsReview"
      />

      <Button
        :label="_t('继续')"
        class="basis-2/3 whitespace-nowrap"
        @click="onContinue"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import AnimationNum from '@/components/AnimationNum.vue'
import LottieBox from '@/components/LottieBox.vue'
import AnimationLabel from '@/components/AnimationLabel.vue'
import Icon from './Icon.vue'
import { formatLearnDuration, vibrate, VibrateType } from '@/utils'
import type { UnitCompleteStat } from '@/api/learn'
import { computed, onMounted } from 'vue'
import { AtlasStageMode } from '@/api/atlas'

const props = defineProps<{
  stat: UnitCompleteStat
  mode?: AtlasStageMode
}>()

const emit = defineEmits<{
  review: []
  continue: []
}>()

const cardCount = computed(() => props.stat.debutCount + props.stat.reviewCount)

// 基础经验值
const baseExp = computed(() => props.stat.exp - props.stat.comboExp)

function onCardsReview() {
  emit('review')
}
function onContinue() {
  emit('continue')
}

onMounted(() => {
  // 震动提示
  vibrate(VibrateType.Success)
  _am.playAudio('lesson_end')
})
</script>

<style scoped></style>
