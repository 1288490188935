<template>
  <ClozeCardLayout>
    <template #content>
      <DebutCardBadge
        v-if="face.isFirstLearn"
        class="absolute top-0 left-0"
      />

      <CardQuestionLayout
        :asset-id="face.card.illustration"
        :hide-role-image="finalFace.style.hideRoleImage"
        :use-give-away-role-image="
          finalFace.type === ClozeCardFaceType.GiveAwayJudgement
        "
      >
        <template #text>
          <Content :content="content">
            <template #cloze="{ node }">
              <PickerCloze
                :node="node"
                :rectify-mode="getClozeRectifyMode()"
                :input-text="getInputText(node)"
              ></PickerCloze>
            </template>
          </Content>
        </template>
      </CardQuestionLayout>
    </template>
    <template #tip>
      <Tip
        v-if="!finalFace.style.hideTip"
        :visible="data.showTip"
        :tip-times="data.tipTimes"
      />
    </template>
    <template #options>
      <div class="operation-grid">
        <Button
          class="aspect-square"
          :scene="getOptionScene(true)"
          :class="getOptionClass(true)"
          option
          @click="onSelect(true)"
        >
          <SparkleText
            class="sparkle-text"
            :tag="data.selected === true && onResult()"
          >
            <Icon
              name="check"
              class="w-53px"
            />
          </SparkleText>
        </Button>

        <Button
          class="flex-1 aspect-square"
          :scene="getOptionScene(false)"
          :class="getOptionClass(false)"
          option
          @click="onSelect(false)"
        >
          <SparkleText
            class="sparkle-text"
            :tag="data.selected === false && onResult()"
          >
            <Icon
              name="times"
              class="w-53px"
            />
          </SparkleText>
        </Button>
      </div>
    </template>
  </ClozeCardLayout>
</template>
<script setup lang="ts">
import { ClozeCardFaceType, QuizStage, UnitEventType } from '@/types/core'
import { reactive, inject, computed, watch } from 'vue'
import { useHotKey } from '@/hooks'
import Icon from '@/components/Icon.vue'
import { randomPick, wait } from '@/utils'
import PickerCloze from '@/components/card-render/PickerCloze.vue'
import ClozeCardLayout from '@/components/ConcreteCard/layout/ClozeCardLayout.vue'
import Tip from '@/components/ConcreteCard/common/Tip.vue'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'
import SparkleText from '@/components/SparkleText.vue'
import { getContentClozes, processClozeFace } from '@/utils/card'
import Content from '@/components/card-render/Content.vue'
import DebutCardBadge from '@/components/ConcreteCard/common/DebutCardBadge.vue'

import '@/components/ConcreteCard/layout/style.css'

import type { Cloze, ClozeCardJudgementFace } from '@/types/core'
import CardQuestionLayout from '../../layout/CardQuestionLayout.vue'
import { shuffle } from 'lodash-es'
import { showCardDetail } from '../../common'

interface DistractedCloze {
  cloze: Cloze
  correctText: string
  distrator: string
}

const props = defineProps<{
  face: ClozeCardJudgementFace
  quizStage?: QuizStage
}>()

const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onNext = inject<VoidFunction>('onNext')
const onStar = inject<(star: FeedbackStar) => void>('onStar')

// quiz
const changeAnswer = inject<(answered: boolean) => void>('answerChange')

useHotKey('1', (evt: KeyboardEvent) => {
  evt.preventDefault()

  onSelect(true)
})

useHotKey('2', (evt: KeyboardEvent) => {
  evt.preventDefault()

  onSelect(false)
})

const finalFace = computed(() => processClozeFace(props.face))
const data = reactive({
  star: FeedbackStar.Three,
  passed: false,
  selected: null as boolean | null,
  distractedCloze: null as DistractedCloze | null,

  showTip: false,
  tipTimes: 0,
})

const content = computed(() =>
  randomPick([
    finalFace.value.card.content,
    ...finalFace.value.card.altContents,
  ])
)

function getOptionScene(
  v: boolean
):
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed' {
  if (data.selected === v) {
    const correct = onResult()
    if (correct) {
      return 'choiceCorrect'
    } else {
      return 'choiceWrong'
    }
  }
  return 'choice'
}

function getOptionClass(v: boolean) {
  if (data.selected === v) {
    const correct = onResult()
    if (correct) {
      return 'correct'
    } else {
      return 'wrong'
    }
  }
  return ''
}

// 生成判断题参数
// 随机将一个「正确选项 / 干扰项」填入挖空 处。
// 如果填入的是正确选项返回空
// 如果是错误选项则返回是index 正确的内容: text 错误的内容: distrator。
function generateDistractedCloze(): DistractedCloze | null {
  if (finalFace.value.type === ClozeCardFaceType.GiveAwayJudgement) {
    return null
  }

  const clozes = getContentClozes(content.value)
  if (clozes.length === 0) {
    return null
  }

  // 随机 true or false
  const correct = Math.random() > 0.5
  if (correct) {
    return null
  }

  // 在map中随机选择一个cloze
  const cloze = randomPick(clozes) as Cloze

  let distrators = cloze?.distrators ?? []

  if (distrators.length === 0) {
    distrators = finalFace.value.altCards.reduce((acc, cur) => {
      const clozes = getContentClozes(cur.content)
      const totalDistrators: string[] = []

      for (const altCloze of clozes) {
        totalDistrators.push(altCloze.text)
        totalDistrators.push(...(altCloze.distrators ?? []))
      }
      return acc.concat(totalDistrators)
    }, [] as string[])
  }

  const distrator = shuffle(distrators).find(item => item !== cloze.text)

  if (distrator == null) return null

  return {
    cloze,
    correctText: cloze.text,
    distrator,
  }
}

function getInputText(cloze: Cloze) {
  if (!data.distractedCloze) return cloze.text

  return data.distractedCloze.cloze === cloze
    ? data.distractedCloze.distrator
    : cloze.text
}

function getClozeRectifyMode(): boolean {
  return data.passed && data.distractedCloze != null
}

// 生成判断题
data.distractedCloze = generateDistractedCloze()

// 自动切换到下一张，此时需要等待一些事件的完成，比如这里是等动画结束
async function onAutoNext() {
  // TODO(buding): 如何准确获取动画结束，这里先 hardcode
  await wait(700)

  // 如果是新知识且设置的展示卡片详情，此时会先弹出卡片详情
  if (props.face.isFirstLearn && props.face.style.showCardDetail) {
    showCardDetail(props.face.card, props.face.isFirstLearn, onNext)
    return
  }

  onNext?.()
}

// 点击选项
function onSelect(value: boolean) {
  if (data.passed) return

  data.selected = value

  const correct = onResult()

  if (!correct) {
    setTimeout(() => {
      data.selected = null
    }, 400)

    onTip()
    onEvent?.(UnitEventType.WRONG)
  } else {
    data.passed = true
    onCorrect()
  }

  if (data.passed) {
    onStar?.(data.star)
    onAutoNext()
  }
}

function onResult(): boolean {
  if (data.selected == null) {
    return false
  }

  return data.selected === !data.distractedCloze
}

function onTip() {
  data.showTip = true
  data.tipTimes++
  onEvent?.(UnitEventType.TIP)
}

function onCorrect() {
  data.star = data.tipTimes > 0 ? FeedbackStar.Two : FeedbackStar.Three
  onEvent?.(UnitEventType.CORRECT)
}

watch(
  () => data.selected,
  () => {
    changeAnswer?.(data.selected != null)
  }
)
</script>
<style scoped></style>
