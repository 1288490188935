<template>
  <div class="flex items-center">
    <component
      :is="n"
      v-for="(n, i) in numberImages"
      :key="i"
      :style="{
        width: props.fontWidth,
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    value: number
    color: 'brand' | 'legendary'
    fontWidth?: number
  }>(),
  {
    fontWidth: 12,
  }
)

const numberImages = computed(() => {
  const numberChars = [...props.value.toString()]
  return numberChars.map(n => {
    return defineAsyncComponent(async () => {
      return import(`../assets/fonts/${props.color}-${n}.svg`)
    })
  })
})
</script>
