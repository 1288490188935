<template>
  <div class="h-full flex flex-col bg-ld-background">
    <SafeAreaTopSpacer />

    <AccountSetting
      class="g-safe-area flex-1"
      @back="onBack"
      @terminate="onTerminate"
    />
  </div>
</template>
<script setup lang="ts">
import AccountSetting from '@/pages/AccountSetting/AccountSetting.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.push({ name: 'mine' })
  }
}

function onTerminate() {
  router.push({ name: 'terminate-apply' })
}
</script>
<style scoped></style>
