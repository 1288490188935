<template>
  <div
    class="flex flex-col h-full bg-ld-background overflow-hidden g-safe-area"
  >
    <SafeAreaTopSpacer class="bg-ld-background" />

    <PublicPackage
      :hash-id="hashId"
      :show-back-btn="router.canGoBack"
      class="flex-1 overflow-hidden"
      @back="onBack"
      @challenge="onPkgChallenge"
      @view="onView"
      @need-auth="onNeedAuth"
    />
  </div>
</template>
<script setup lang="ts">
import PublicPackage from '@/components/PublicPackage.vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const hashId = route.params.hashId as string

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({
      name: 'atlas',
    })
  }
}

function onPkgChallenge(id: number) {
  router.push({
    name: 'atlas',
    query: {
      pkgId: id,
    },
  })
}

function onView(id: number) {
  router.push({
    name: 'package',
    params: {
      id: id,
    },
  })
}

function onNeedAuth() {
  router.push({
    name: 'auth',
    query: {
      redirect: encodeURIComponent(route.fullPath),
    },
  })
}
</script>

<style></style>
