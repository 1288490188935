<template>
  <div
    v-if="parsed"
    :class="[
      'flex items-start gap-2',
      {
        'flex-col': !_global.isPcMode,
      },
    ]"
  >
    <TextClamp class="w-full">
      <div class="font-semibold">
        {{ parsed.card.question }}
      </div>

      <div
        v-for="item in options"
        :key="item.index"
      >
        <span class="font-semibold text-ld-brand-500">{{ item.index }}.</span>

        <span>{{ item.text }}</span>
      </div>
    </TextClamp>

    <Image
      v-if="card.illustration"
      preview
      :class="[
        'illustration shrink-0',
        {
          'ml-auto': _global.isPcMode,
        },
      ]"
    >
      <template #image>
        <img
          :src="_global.assetUrl(card.illustration)"
          class="h-78px w-78px rounded-4px object-cover"
          @click.stop
        />
      </template>
      <template #preview="slotProps">
        <img
          :src="_global.assetUrl(card.illustration)"
          class="object-cover"
          :style="slotProps.style"
          @click="slotProps.onClick"
        />
      </template>
    </Image>
  </div>
  <div v-else>
    {{ _t('卡片解析失败') }}
  </div>
</template>
<script setup lang="ts">
import { parseMCQCard } from '@/utils/card'
import Image from 'primevue/image'

import type { MCQCard } from '@/types/core'
import TextClamp from '@/components/TextClamp.vue'
import { genOptions } from '../ConcreteCard/MCQCardFace/common'

const props = defineProps<{
  card: MCQCard
}>()

const parsed = parseMCQCard(props.card)

const options = genOptions(parsed.card.options, false, false)
</script>
<style scoped>
.illustration :deep(.p-image-preview-indicator) {
  opacity: 0;
}
</style>
