import { createRouter, createWebHistory } from 'vue-router'

import App from './App.vue'

import WxCallback from '@/mobile/pages/WxCallback.vue'
import Auth from '@/mobile/pages/Auth.vue'
import AuthInApp from '@/pages/in-app/Auth.vue'
import Shelf from '@/pages/Shelf.vue'
import TagManagement from '@/mobile/pages/TagManagement.vue'
import Mine from '@/pages/Mine.vue'
import Rank from '@/pages/Rank/Rank.vue'
import Reward from '@/mobile/pages/Reward/Reward.vue'
import Atlas from '@/pages/Atlas/Atlas.vue'
import UnitLearn from '@/pages/UnitLearn/UnitLearn.vue'
import PublicPackage from '@/pages/PublicPackage.vue'
import PkgSearch from '@/mobile/pages/PkgSearch.vue'
import Landing from '@/mobile/pages/Landing.vue'
import LandingInApp from '@/pages/in-app/Landing.vue'
import IdentityOnboarding from '@/components/IdentityOnboarding/IdentityOnboarding.vue'
import PackageBrowserPage from '@/mobile/pages/pkg/PackageBrowserPage.vue'

import type { RouteRecordRaw } from 'vue-router'
import UserSetting from '@/mobile/pages/mine/UserSetting.vue'
import Message from '@/pages/Message.vue'
import NotFound from '@/pages/NotFound.vue'
import {
  LANDING_PATH,
  UNIT_LEARN_PATH,
  WX_CALLBACK_PATH,
  debugRoute,
  setupHistoryMgmt,
} from '@/shared'
import BetaFeature from '@/pages/BetaFeature/BetaFeature.vue'
import TerminateApply from '@/mobile/pages/TerminateApply.vue'
import Terminating from '@/pages/Terminating.vue'
import AccountSetting from '@/mobile/pages/mine/AccountSetting.vue'
import Support from '@/pages/Support.vue'
import AboutUs from '@/pages/in-app/AboutUs.vue'
import OperationPreference from '@/mobile/pages/mine/OperationPreference.vue'
import Notification from '@/mobile/pages/mine/Notification.vue'
import AppDownload from '@/mobile/pages/AppDownload.vue'

// meta:
//  - auth: boolean
const routes: RouteRecordRaw[] = [
  {
    path: WX_CALLBACK_PATH,
    component: WxCallback,
    name: 'wxcallback',
  },

  {
    path: '/app-download',
    component: AppDownload,
    name: 'app-download',
    meta: {
      hideAppDownloadHeader: true,
    },
  },

  {
    path: '/beta-feature',
    name: 'beta-feature',
    component: BetaFeature,
    meta: {
      needAuth: true,
    },
  },

  {
    path: '/auth',
    component: _global.isInsideApp ? AuthInApp : Auth,
    name: 'auth',
  },

  {
    path: LANDING_PATH,
    component: _global.isInsideApp ? LandingInApp : Landing,
    name: 'landing',
  },

  {
    path: '/identity-onboarding',
    component: IdentityOnboarding,
    name: 'identity-onboarding',
  },

  {
    path: '/pkgs/:hashId',
    component: PublicPackage,
    name: 'publicPackage',
  },
  {
    path: '/tag/management',
    component: TagManagement,
    name: 'tag/management',
  },
  {
    path: '/404',
    component: NotFound,
    name: '404',
  },
  {
    path: '/support/:productId',
    component: Support,
    name: 'support',
  },
  {
    path: '/about-us',
    component: AboutUs,
    name: 'about-us',
  },
  {
    path: '/',
    component: App,
    children: [
      { path: '', redirect: 'landing' },
      {
        path: 'atlas',
        component: Atlas,
        name: 'atlas',
        meta: {
          keepAlive: true,
        },
      },
      {
        path: 'shelf',
        component: Shelf,
        name: 'shelf',
      },
      {
        path: 'mine',
        component: Mine,
        name: 'mine',
      },
      {
        path: 'reward',
        component: Reward,
        name: 'reward',
      },
      {
        path: 'rank',
        component: Rank,
        name: 'rank',
      },
      {
        path: UNIT_LEARN_PATH,
        component: UnitLearn,
        name: 'unit/learn',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/packages/:id',
        component: PackageBrowserPage,
        name: 'package',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/message',
        component: Message,
        name: 'message',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/user-setting',
        component: UserSetting,
        name: 'user-setting',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/account-setting',
        component: AccountSetting,
        name: 'account-setting',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/operation-preference',
        component: OperationPreference,
        name: 'operation-preference',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/notification',
        component: Notification,
        name: 'notification',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/pkg-search',
        component: PkgSearch,
        name: 'pkg-search',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/terminate-apply',
        component: TerminateApply,
        name: 'terminate-apply',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/terminating',
        component: Terminating,
        name: 'terminating',
        meta: {
          fullscreen: true,
        },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]
if (!_global.isProd) {
  routes.push(debugRoute)
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

setupHistoryMgmt(router)

router.beforeEach((to, _from) => {
  const needAuth = to.matched[0].path === '/' || to.meta.needAuth

  if (needAuth && !_store.isLoggedIn) {
    return {
      name: 'landing',
      query: {
        redirect: encodeURIComponent(to.fullPath),
      },
      replace: _global.isInsideWechat,
    }
  }
})

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    _closeAllDialogs()
  }
})

export default router
