<template>
  <div class="listcell">
    <Img
      v-if="props.img"
      :name="props.img"
      class="w-20px mr-6px"
    />
    <span
      :class="[
        'title',
        {
          debug: props.debug,
        },
      ]"
    >
      {{ props.title }}
    </span>

    <div class="ml-auto flex items-center">
      <span class="label mx-2 line-clamp-1">
        {{ props.label }}
      </span>

      <slot name="action">
        <i
          v-if="props.arrow"
          class="pi pi-angle-right text-20px"
        ></i>
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    label?: string
    debug?: boolean
    img?: string
    arrow?: boolean
  }>(),
  {
    debug: false,
    label: undefined,
    img: undefined,
    arrow: true,
  }
)
</script>
<style scoped>
.listcell {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 56px;
  justify-content: space-between;
  background-color: white;
  padding: 16px;
  cursor: pointer;
}

.title {
  color: var(--text-primary);
  font-size: 17px;
  line-height: 1;
  letter-spacing: 0.25px;
  font-weight: 500;
  white-space: nowrap;
}

.debug {
  color: var(--yellow-500);
}

.listcell:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.listcell:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.label {
  color: var(--text-color-secondary);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}
</style>
