<template>
  <RatioSpacedContainer
    class="p-4 h-full g-body-width"
    :top="1"
    :bottom="1"
  >
    <Img
      name="rank-mock-group"
      class="w-full"
    />

    <div class="mt-16 mb-6 text-21px">
      {{ _t('闯过') }}
      <span class="text-ld-brand-500">
        {{ _t(`第 ${requiredAtlasLevel} 关`) }}
      </span>
      {{ _t('，即可开启排位赛') }}
    </div>

    <div class="w-full px-4">
      <Button
        scene="secondary"
        class="w-full"
        @click="onChallenge"
      >
        <div
          class="flex-1 text-center text-21px font-semibold text-ld-brand-500"
        >
          {{ _t('去闯关') }}
        </div>
      </Button>
    </div>
  </RatioSpacedContainer>
</template>
<script lang="ts" setup>
import type { RankHomeRankFeatureDisabled } from '@/types/rank'
import { toRefs } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps<{
  rankHome: RankHomeRankFeatureDisabled
}>()

const { requiredAtlasLevel } = toRefs(props.rankHome.data)

function onChallenge() {
  router.replace({ name: 'atlas' })
}
</script>
<style scoped></style>
