<template>
  <div
    class="flex flex-col items-center justify-center w-24px h-24px gap-2px rounded-4px"
  >
    <slot>
      <Icon
        name="toolbar-cloze"
        class="w-18px"
        :style="style"
      />
    </slot>

    <Icon
      name="toolbar-cloze-underline"
      class="w-20px"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  color?: string
}>()

const style = computed(() => {
  if (props.color) {
    return `color: var(--${props.color});`
  }
})
</script>
<style scoped></style>
