<template>
  <div
    ref="tabbarRef"
    class="flex items-center border-t-2"
  >
    <div
      v-for="tab in tabs"
      :key="tab.route"
      v-fast-click
      class="flex-1 flex flex-col space-y-1 cursor-pointer items-center justify-center text-gray-500 text-10px font-semibold py-2"
      :class="{
        'text-ld-brand-500': isActivedRoute(tab.route),
      }"
      @click="onClick(tab)"
    >
      <div class="w-6 h-6 relative">
        <Badge
          v-if="tab.badge != null"
          :value="tab.badge"
          class="top--4px left-18px"
        />
        <Icon
          v-if="isActivedRoute(tab.route)"
          :name="tab.activeIcon"
        />
        <Icon
          v-else
          :name="tab.icon"
        />
      </div>
      <span class="text-10px font-semibold">
        {{ tab.label }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Badge from '@/components/Badge.vue'
import { useCommonStore } from '@/stores'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NavIcons } from '@/shared/icons'
import { vibrate } from '@/utils'

const store = useCommonStore()
const router = useRouter()

interface HomeTab {
  label: string
  icon: string
  activeIcon: string
  route: string
  badge?: number | string
}

const tabbarRef = ref()

function onClick(tab: HomeTab) {
  vibrate()
  router.replace({ name: tab.route })
}

const tabs = computed<HomeTab[]>(() => {
  return [
    {
      label: NavIcons.atlas.label,
      icon: NavIcons.atlas.icon,
      activeIcon: NavIcons.atlas.activeIcon,
      route: 'atlas',
    },
    {
      label: NavIcons.reward.label,
      icon: NavIcons.reward.icon,
      activeIcon: NavIcons.reward.activeIcon,
      route: 'reward',
      badge: store.unreceivedTaskCount,
    },
    {
      label: NavIcons.rank.label,
      icon: NavIcons.rank.icon,
      activeIcon: NavIcons.rank.activeIcon,
      route: 'rank',
      badge: store.showRankGuide ? 'i' : '',
    },
    {
      label: NavIcons.bookshelf.label,
      icon: NavIcons.bookshelf.icon,
      activeIcon: NavIcons.bookshelf.activeIcon,
      route: 'shelf',
    },
    {
      label: NavIcons.mine.label,
      icon: NavIcons.mine.icon,
      activeIcon: NavIcons.mine.activeIcon,
      route: 'mine',
      badge: isActivedRoute('mine') ? undefined : store.unreadMsgCount,
    },
  ]
})

const route = useRoute()

function isActivedRoute(routeName: string) {
  return ((route.name ?? '') as string).startsWith(routeName)
}
</script>

<style scoped></style>
