<template>
  <Img
    class="max-w-114px flex-shrink-0"
    :name="name"
  />
</template>

<script lang="ts">
let roleStorage: Record<string, number> = {
  role: -1,
  giveAwayRole: -1,
}
</script>
<script setup lang="ts">
import { GIVE_AWAY_ROLE_COUNT, ROLE_COUNT } from '@/pages/UnitLearn/preload'
import { random } from 'lodash-es'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    useGiveAwayRole?: boolean
  }>(),
  {
    useGiveAwayRole: false,
  }
)

const name = computed(() => {
  if (props.useGiveAwayRole) {
    const roleIndex =
      randomWithoutLast('giveAwayRole', GIVE_AWAY_ROLE_COUNT) + 1
    return `give-away-role-${roleIndex}`
  }

  const roleIndex = randomWithoutLast('role', ROLE_COUNT) + 1
  return `role-${roleIndex}`
})

// 随机数生成器 保证连续两次不一样
function randomWithoutLast(key: string, count: number): number {
  if (count <= 0) {
    return 0
  }
  const lastNum = roleStorage[key]
  // 由于 role 资源是 1-base 的，所以这里随机的时候上限是 count - 1
  // 外层在使用的时候会 +1
  let nextNum = random(count - 1)

  if (nextNum === lastNum) {
    // 如果重复就 +1 防止连续出现相同的随机数
    nextNum = (nextNum + 1) % count
  }
  roleStorage[key] = nextNum

  return nextNum
}
</script>

<style scoped></style>
