<template>
  <main class="p-4">
    <ClozeEditor
      :content="content"
      :id="1"
      class="editor focus"
      editable
      autofocus
      @update="content = $event"
    />
  </main>

  <div class="px-4">
    <pre
      class="border border-yellow-400 mt-4 p-2 h-[400px] overflow-auto text-sm"
    ><code>{{ json }}</code></pre>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import ClozeEditor from '@/components/ClozeEditor/ClozeEditor.vue'
import type { Content as TContent } from '@/types/core'

const content = ref<TContent>([
  {
    type: 'p',
    content: [
      {
        type: 'cloze',
        text: '123',
      },
      {
        type: 'cloze',
        text: '456',
      },
    ],
  },
])
const json = computed(() => JSON.stringify(content.value, null, 2))
</script>
<style scoped>
.editor {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  padding: 12px 16px;
  border: 1px solid transparent;
  transition: all 0.3s;
  border-width: 1px solid var(--surface-200);
  background-color: white;
}

.editor * {
  transition: all 0.3s;
}

.editor.focus {
  border-color: var(--primary-color);
}
</style>
