<template>
  <div class="vstack items-start">
    <div>每张卡片出现多少次</div>

    <InputNumber v-model="practiceCardTimes" />

    <Button
      class="ml-2"
      :label="_t('更新配置')"
      @click="onSubmit"
    ></Button>
  </div>
</template>
<script setup lang="ts">
import InputNumber from 'primevue/inputnumber'
import db from '@/db'
import { ref } from 'vue'

const practiceCardTimes = ref(db.debug.insertQueueConfig.practiceCardTimes)

function onSubmit() {
  if (practiceCardTimes.value < 1) {
    _message.error('卡片至少需要出现一次')
    return
  }

  db.debug.insertQueueConfig.practiceCardTimes = practiceCardTimes.value

  _message.success('队列配置已更新')
}
</script>
<style scoped></style>
