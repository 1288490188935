import type { ChoiceOption } from '@/types/core'
import { randomPick } from '@/utils'
import { shuffle } from 'lodash-es'

export function numberToLetter(num: number) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  if (num >= 0 && num < 26) {
    return alphabet[num]
  } else {
    throw new Error('输入的数字应在 0 到 25 之间')
  }
}

export interface Option {
  text: string
  index: string
  isAnswer: boolean
  selected: boolean
  wrong?: boolean
}

function choiceToOption(choice: ChoiceOption, i: number): Option {
  return {
    text: choice.content,
    index: numberToLetter(i),
    isAnswer: choice.isAnswer,
    selected: false,
    wrong: false,
  }
}

export function genOptions(
  choices: ChoiceOption[],
  minimalOptions: boolean,
  randomOrder: boolean = true
): Option[] {
  const displayChoices: ChoiceOption[] = []
  const answerChoices = choices.filter(item => item.isAnswer)
  const wrongChoices = choices.filter(item => !item.isAnswer)

  // https://www.figma.com/design/sNi7fnbw3njn4pmRyoRkmr/Cloze-App?node-id=36902-37811&node-type=frame&t=gAbvuwidKAb8c4qZ-0
  // minimal 选择题，选项数量为正确答案数量 + 1，不超过总的选项数量
  if (minimalOptions) {
    displayChoices.push(...answerChoices)

    if (wrongChoices.length > 0) {
      const distractorChoice = randomPick(wrongChoices)
      displayChoices.push(distractorChoice)
    }
  } else {
    displayChoices.push(...choices)
  }

  if (randomOrder) {
    return shuffle(displayChoices).map(choiceToOption)
  }

  return displayChoices.map(choiceToOption)
}
