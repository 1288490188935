<template>
  <div class="flex items-center gap-2">
    <div
      class="bg-ld-brand-200 text-ld-brand font-[DIN] text-11px w-4 h-4 rounded-100% flex items-center justify-center"
    >
      {{ props.index + 1 }}
    </div>

    <div
      :class="[
        'flex-1 rounded-8px py-3 px-4 border border-ld-border bg-white',
        props.focused ? 'border-ld-brand-500' : 'hover:border-gray',
      ]"
    >
      <ClozeEditor
        :content="content"
        editable
        @update="emit('update', $event)"
        @focus="emit('focus')"
      />
    </div>

    <div class="flex items-center justify-center select-none gap-2px">
      <slot name="action"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Content } from '@/types/core'
import ClozeEditor from '@/components/ClozeEditor/ClozeEditor.vue'

const props = defineProps<{
  index: number
  content: Content
  focused: boolean
}>()
const emit = defineEmits<{
  update: [Content]
  focus: []
}>()
</script>
<style scoped></style>
