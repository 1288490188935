<template>
  <div class="h-full flex flex-col items-center px-4 g-body-width">
    <div class="flex flex-col items-center gap-64px flex-1 justify-center">
      <Img
        name="rank-guide-step1"
        class="w-261px fade-in"
        :style="{ animationDelay: getFadeInDelay() }"
      />

      <Img
        name="rank-guide-step2"
        class="w-261px fade-in"
        :style="{ animationDelay: getFadeInDelay() }"
      />

      <Img
        name="rank-guide-step3"
        class="w-261px fade-in"
        :style="{ animationDelay: getFadeInDelay() }"
      />
    </div>

    <Button
      class="w-full mt-64px mb-48px fade-in"
      :label="_t('🏆 开启排位赛')"
      :style="{ animationDelay: getFadeInDelay() }"
      @click="onRankStart"
    />
  </div>
</template>
<script lang="ts" setup>
const emit = defineEmits<{
  start: []
}>()

let delay = 0
function getFadeInDelay() {
  const delayStyle = `${delay}s`

  delay++

  return delayStyle
}

function onRankStart() {
  emit('start')
}
</script>
<style scoped>
.fade-in {
  opacity: 0;
  animation: g-fade-in 0.5s;
  animation-fill-mode: forwards;
}
</style>
