<template>
  <AppBar
    v-if="!currentPage.fullscreen"
    :title="currentPage.name"
    @back="onBack"
  />

  <component :is="currentPage.vnode" />
</template>
<script setup lang="ts">
import AppBar from '@/mobile/components/AppBar.vue'
import UserSetting from '@/components/UserSetting/UserSetting.vue'
import type { VNode } from 'vue'
import { ref, computed, h } from 'vue'
import AccountSetting from '@/pages/AccountSetting/AccountSetting.vue'
import TerminateApply from '@/pages/TerminateApply.vue'
import OperationPreference from '@/pages/OperationPreference.vue'
import Notification from '@/pages/Notification/Notification.vue'

const emit = defineEmits<{
  done: []
}>()

interface InnerPage {
  name: string
  vnode: VNode
  fullscreen: boolean
}

const pages = ref<InnerPage[]>([
  {
    name: _t('设置'),
    vnode: h(UserSetting, {
      onToAccount,
      onToOperationPreference,
      onToNotification,
      class: 'p-4',
    }),
    fullscreen: false,
  },
])

const currentPage = computed(() => pages.value[pages.value.length - 1])

function onToAccount() {
  pages.value.push({
    name: _t('账号与绑定'),
    vnode: h(AccountSetting, {
      onBack,
      onTerminate,
    }),
    fullscreen: true,
  })
}
function onToOperationPreference() {
  pages.value.push({
    name: _t('操作偏好'),
    vnode: h(OperationPreference, {
      onBack,
    }),
    fullscreen: true,
  })
}

function onToNotification() {
  pages.value.push({
    name: _t('提醒通知'),
    vnode: h(Notification, {
      onBack,
    }),
    fullscreen: true,
  })
}

function onTerminate() {
  pages.value.push({
    name: _t('注销申请'),
    vnode: h(TerminateApply, {
      onBack,
      class: 'flex-1',
    }),
    fullscreen: true,
  })
}

function onBack() {
  if (pages.value.length === 1) {
    emit('done')
    return
  }

  pages.value.pop()
}
</script>
<style scoped></style>
