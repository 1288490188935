<template>
  <div class="p-4">
    <ul class="list-disc list-inside">
      <li>内容只能通过 label 传入，不支持 slot</li>
      <li>
        目前只有一种样式，需要定制的话通过 class 自行定制（例如 卡包解锁 场景）
      </li>
    </ul>

    <hr class="my-4" />

    <div class="hstack">
      <Button
        :label="status.loading ? 'loading(true)' : 'loading(false)'"
        outlined
        @click="
          () => {
            status.loading = !status.loading
          }
        "
      />
    </div>

    <hr class="my-4" />

    <div class="hstack">
      <p>默认样式</p>
      <div class="p-4 flex flex-col">
        <TextButton
          label="text content"
          :loading="status.loading"
        />
      </div>
    </div>
    <div class="hstack">
      <p>自定义样式</p>
      <div class="p-4 flex flex-col">
        <TextButton
          label="卡包解锁"
          :loading="status.loading"
          class="underline underline-offset-3"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import TextButton from '@/components/TextButton.vue'

const status = reactive({
  loading: false,
})
</script>

<style scoped></style>
