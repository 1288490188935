import { type RankHome } from '@/types/rank'
import api, { type CommonResponse } from './base'

export function fetchRankHome(): Promise<RankHome> {
  return api.get<unknown, RankHome>('/rank/home')
}

export function claimRankReward(resultId: number) {
  return api.post<unknown, CommonResponse<never>>('/rank/claim-reward', {
    resultId,
  })
}

export function keepRankByDiamond(resultId: number) {
  return api.post<unknown, CommonResponse<never>>('/rank/diamond-keep-rank', {
    resultId,
  })
}
