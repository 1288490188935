export const fastClick = {
  mounted(el: HTMLElement) {
    const handleTouchStart = (e: TouchEvent) => {
      const syntheticEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
      e.target!.dispatchEvent(syntheticEvent)
      e.preventDefault()
    }

    // 添加事件监听器
    el.addEventListener('touchstart', handleTouchStart, { passive: false })

    // 在组件卸载时移除事件监听器
    ;(el as any)._instantClickCleanup = () => {
      el.removeEventListener('touchstart', handleTouchStart)
    }
  },
  unmounted(el: any) {
    // 清理事件监听器
    if (el._instantClickCleanup) {
      el._instantClickCleanup()
    }
  },
}
