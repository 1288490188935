<template>
  <RatioSpacedContainer class="px-8 text-center">
    <Icon
      name="logo"
      class="w-50px"
    />

    <Loading class="w-50px h-50px my-2" />
    <span class="text-2xl">
      {{ _t('每一个伟大的成就，都始于一个勇敢的开始') }}
    </span>
  </RatioSpacedContainer>
</template>
<script setup lang="ts"></script>
<style scoped></style>
