<template>
  <div
    ref="listRef"
    class="flex items-center overflow-x-auto overflow-y-hidden gap-4"
    :style="style"
  >
    <RankIcon
      v-for="rank in rankList"
      :key="rank.type"
      :rank="rank"
      :locked="rank.locked"
      :class="[
        'shrink-0',
        rank.isCurrentRank ? 'w-90px h-90px active-rank' : 'w-60px h-60px',
      ]"
    ></RankIcon>
  </div>
</template>
<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, type CSSProperties } from 'vue'
import RankIcon from '@/components/RankIcon.vue'
import { RankType, type Rank } from '@/types/rank'

type RankIconData = Rank & {
  locked: boolean
  isCurrentRank: boolean
}

const props = defineProps<{
  currentRank: Rank
}>()

const listRef = ref<HTMLDivElement>()

const style = computed<CSSProperties>(() => {
  if (listRef.value == null) return {}

  const style: CSSProperties = {}
  const paddingX = Math.floor(listRef.value.clientWidth / 2)
  const rankTypes = Object.values(RankType)
  const index = rankTypes.indexOf(props.currentRank.type)

  // 列表左右两侧的 padding 为半个屏幕的宽度减去首尾段位图标的一半宽度
  // 由于首尾图标可能是当前段位（此时图标大小为 90px），所以需要减去不同的大小
  style.paddingLeft = `${paddingX - (index === 0 ? 45 : 30)}px`
  style.paddingRight = `${
    paddingX - (index === rankTypes.length - 1 ? 45 : 30)
  }px`

  return style
})

const rankList = computed<RankIconData[]>(() => {
  const rankTypes = Object.values(RankType)

  // 先找到当前段位在所有段位中的 index，index 前面的段位都是解锁过的，后面的都是锁定状态
  const currentRankIndex = rankTypes.indexOf(props.currentRank.type)

  return rankTypes.map((rankType, i) => {
    const isCurrentRank = rankType === props.currentRank.type
    return {
      type: rankType,
      star: isCurrentRank ? props.currentRank.star : undefined,
      locked: i > currentRankIndex,
      isCurrentRank,
    }
  })
})

onMounted(async () => {
  // 由于上面 style 中 padding 的计算依赖 listRef
  // 所以刚挂载的时候需要先等待 style 计算完毕拿到 padding 后再进行滚动定位
  await nextTick()

  // 初始时需要把当前段位定位到屏幕中间
  if (listRef.value) {
    const activeRank = listRef.value.querySelector('.active-rank') as any

    if (activeRank != null) {
      // 先找到当前段位元素中间的位置
      const midX =
        activeRank.offsetLeft -
        listRef.value.offsetLeft +
        activeRank.clientWidth / 2
      // 再找到列表容器中间的位置
      const screenMidX = listRef.value.clientWidth / 2

      // 滚动距离为当前段位的中间位置和屏幕的中间位置差
      listRef.value.scrollTo({
        left: midX - screenMidX,
      })
    }
  }
})
</script>
<style scoped></style>
