import { Mark, mergeAttributes } from '@tiptap/core'

// 从编辑器复制的 span 再粘贴时不会被编辑器识别
// 所以这里需要加一个空的 mark 来让粘贴的时候能识别到
const PlainText = Mark.create({
  name: 'plainText',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },
})

export default PlainText
