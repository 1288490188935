import api, { type CommonResponse } from './base'
import type { CardSchedule } from './learn'
import type { PackageBasic, PackageStyle } from './package-source'

export interface Stage {
  stageId: number
  level: number
  pkgInfo: {
    id: number
    name: string
    style: PackageStyle
    createdAt: string
  }
  completedAt: string
}

export interface ChallengeStage {
  stageId: number
  index: number

  // eg: '2024-07-25T13:12:16.514Z'
  lastCompletedAt: string
  star: number
}

export function fetchAtlasStages() {
  return api.get<
    never,
    {
      stages: Stage[]
    }
  >('/v1/atlas/stages')
}

export enum AtlasStageMode {
  // 普通模式
  NORMAL = 'NORMAL',
  // 狂热模式，会增加经验值
  PASSIONATE = 'PASSIONATE',
}

export function startAtlasStage(packageId: number, mode?: AtlasStageMode) {
  return api.post<
    never,
    CommonResponse<{
      stageId: number
      unitId: number
      schedules: CardSchedule[]
    }>
  >('/v1/atlas/stages', {
    packageId,
    mode,
  })
}

export enum LookBackStatus {
  NEEDS_IMPROVEMENT = 'NEEDS_IMPROVEMENT',
  GOOD = 'GOOD',
  PERFECT = 'PERFECT',
}

export interface AtlasStageCard {
  content: string
  lookBackStatus: LookBackStatus
}

export function fetchAtlasStageCards(stageId: number) {
  return api.get<never, { cards: AtlasStageCard[] }>(
    `/v1/atlas/${stageId}/look-back`
  )
}

export function startAtlasChallengeStage(payload: {
  index: number
  startLevel: number
  endLevel: number
}) {
  return api.post<
    never,
    CommonResponse<{
      stageId: number
      unitId: number
      schedules: CardSchedule[]
    }>
  >('/v1/atlas/challenge-stages', payload)
}

export function fetchAtlasChallengeStages() {
  return api.get<
    never,
    {
      stages: ChallengeStage[]
    }
  >('/v1/atlas/challenge-stages')
}

export function fetchAtlasRegionReward(index: number) {
  return api.post<never, CommonResponse<never>>('/v1/atlas/region-reward', {
    index,
  })
}

export function fetchAtlasRegionRewardStatus(index: number) {
  return api.get<never, { reward: { index: number; diamond: number } }>(
    `/v1/atlas/region-reward/${index}`
  )
}

export interface StagePreInfo {
  packageList: {
    packages: PackageBasic[]
  }
  requiredEnergy: number
  expMap: Record<number, number>
}

export function preStartAtlasStage(stageLevel: number) {
  return api.post<never, StagePreInfo>('/v1/atlas/stages/pre', {
    stageLevel,
  })
}

export interface ChallengeStagePreInfo {
  requiredEnergy: number
  exp: number
}

export function preStartAtlasChallengeStage(payload: {
  index: number
  startLevel: number
  endLevel: number
}) {
  return api.post<never, ChallengeStagePreInfo>(
    '/v1/atlas/challenge-stages/pre',
    payload
  )
}
