<template>
  <div class="flex-1 flex flex-col w-full overflow-auto gap-6">
    <div class="vstack items-center">
      <Avatar
        :avatar="user.avatar"
        class="w-90px h-90px"
      />
      <TextButton
        :label="_t('更改头像')"
        :loading="avatarLoading"
        @click="onChangeAvatar"
      ></TextButton>
    </div>

    <div class="w-full">
      <ListCell
        :title="_t('昵称')"
        :label="user.nickname"
      >
        <template #action>
          <TextButton
            :label="_t('修改')"
            :loading="nicknameLoading"
            @click="onChangeNickname"
          ></TextButton>
        </template>
      </ListCell>
      <ListCell
        :title="_t('编号')"
        :label="user.id"
      >
        <template #action>
          <TextButton
            :label="_t('复制')"
            @click="onCopyId"
          ></TextButton>
        </template>
      </ListCell>

      <ListCell
        :title="_t('账号与绑定')"
        arrow
        @click="emit('toAccount')"
      ></ListCell>
    </div>

    <div class="w-full">
      <ListCell
        :title="_t('操作偏好')"
        arrow
        @click="emit('toOperationPreference')"
      />
      <ListCell
        v-if="showNotificationCell"
        :title="_t('提醒通知')"
        arrow
        @click="emit('toNotification')"
      />
      <ListCell
        :title="_t('学习阶段')"
        :label="identityTags[0]?.name"
        :arrow="false"
        class="break-all"
      >
        <template #action>
          <TextButton
            label="修改"
            @click="onChangeIdentityTag"
          ></TextButton>
        </template>
      </ListCell>
      <ListCell :title="_t('清除缓存')">
        <template #action>
          <TextButton
            :label="_t('立即清除')"
            @click="onCacheClear"
          ></TextButton>
        </template>
      </ListCell>
    </div>

    <div
      v-if="showWidget"
      class="w-full"
    >
      <ListCell
        :title="_t('小组件')"
        arrow
        @click="onClickHomeWidget"
      />
    </div>

    <div class="mt-auto flex flex-col gap-16px">
      <Button
        label="登出当前账户"
        scene="danger"
        @click="onLogout"
      />

      <div class="text-13px text-ld-label-secondary text-center">
        {{ _t(`资源版本：${gitHash}`) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useCommonStore } from '@/stores'
import { fetchUserTags, patchUser, uploadAvatar } from '@/api/user'

import { useRouter } from 'vue-router'
import ListCell from '@/components/ListCell/ListCell.vue'
import { openLink, pickImg } from '@/utils/index'
import { Code } from '@/api/code'

import db, { mergeDefaultDb, type DB } from '@/db'
import type { IdentityTag } from '@/types/core'
import { useCopy } from '@/hooks'
import { checkNotificationAvaiable } from '@/utils/notification'
import { Platform } from '@/utils/bridge'

const router = useRouter()
const store = useCommonStore()
const user = store.user!

const emit = defineEmits<{
  toAccount: []
  toOperationPreference: []
  toNotification: []
  done: []
}>()

const gitHash = computed(() => _global.gitCommit.split('(')[0])

// 是否显示小组件
const showWidget = ref(false)

onInit(() => {
  if (_global.isInsideApp) {
    _bridge.has('homewidget').then(res => {
      showWidget.value = res
    })
  }
})

function onLogout() {
  store.logout()
  router.push({ name: 'landing' })
  emit('done')
}

const nicknameLoading = ref(false)
const copy = useCopy()

function onClickHomeWidget() {
  if (store.appInfo?.platform === Platform.IOS) {
    openLink(_global.iosWidgetLink)
  } else {
    openLink(_global.androidWidgetLink)
  }
}

function onChangeNickname() {
  _openInputDialog({
    title: _t('输入新的昵称'),
    placeholder: _t('输入新的昵称'),
    okText: _t('保存'),
    async onSubmit(newNickname: string) {
      if (newNickname == null) return false

      if (newNickname.length < 2) {
        _message.info(_t('昵称最少 2 个字'))
        return false
      }
      if (newNickname.length > 10) {
        _message.info(_t('昵称最多 10 个字'))
        return false
      }
      if (!/^[^!\"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]*$/.test(newNickname)) {
        _message.info(_t('请勿使用特殊符号'))
        return false
      }

      nicknameLoading.value = true
      await patchUser({ nickname: newNickname.trim() })
        .then(() => {
          user.nickname = newNickname
          _message.success(_t('修改成功'))
        })
        .finally(() => {
          nicknameLoading.value = false
        })
      return true
    },
  })
}

function onCopyId() {
  copy(user.id).then(() => {
    _message.success(_t('已复制'))
  })
}

const avatarLoading = ref(false)

async function onChangeAvatar() {
  const imgFile = await pickImg(_t('更改头像'), true)

  if (!imgFile) return

  avatarLoading.value = true
  try {
    const res = await uploadAvatar(imgFile)

    if (res.code !== Code.Ok) {
      _message.info(res.message)
    } else {
      store.patchUser({ avatar: res.data.assetId })
      _message.success(_t('头像更换成功'))
    }
  } finally {
    avatarLoading.value = false
  }
}

function onCacheClear() {
  const newDb = mergeDefaultDb({
    loginToken: db.loginToken,
  })

  for (const key of Object.getOwnPropertyNames(db)) {
    ;(db as any)[key] = newDb[key as keyof DB]
  }

  _message.info('缓存已全部清理')
  _refreshApp()
}

const identityTags = ref<IdentityTag[]>([])
function fetchIndentityTags() {
  fetchUserTags().then(res => {
    identityTags.value = res.identityTagResponses
  })
}

const showNotificationCell = ref(false)

onInit(() => {
  fetchIndentityTags()
  checkNotificationAvaiable().then(avaiable => {
    showNotificationCell.value = avaiable
  })
})

function onChangeIdentityTag() {
  router.push({
    name: 'identity-onboarding',
    query: {
      backAfterEnd: 'true',
    },
  })
}
</script>

<style scoped></style>
