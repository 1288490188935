<template>
  <props.entry
    :key="key"
    :class="{
      'bg-red-100': _global.isDebugProd,
    }"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import type { Component } from 'vue'

const props = defineProps<{
  entry: Component
}>()

const key = ref(1)
window._refreshApp = function () {
  key.value++
}
</script>

<style scoped></style>
