<template>
  <div class="h-full flex flex-col bg-ld-background">
    <SafeAreaTopSpacer />

    <AppBar
      title="关于我们"
      @back="onBack"
    />
    <div class="flex-1 flex flex-col px-4">
      <div class="flex flex-col items-center gap-16px">
        <img
          src="/logo.png"
          alt="logo"
          class="w-72px mt-16px rounded-16px"
        />
        <div class="flex flex-col items-center font-semibold text-17px">
          <div>{{ _t('小灵鸭') }}</div>
          <div
            class="text-ld-label-secondary"
            @dblclick="onClickVersion"
          >
            v{{ version }}
          </div>
        </div>
      </div>

      <div class="w-full mt-48px">
        <ListCell
          title="🚀️ 检查更新"
          @click="onCheckUpdate"
        >
          <template #action>
            <Icon
              v-if="hasUpdate"
              name="upgrade"
              class="w-20px h-20px"
            ></Icon>
            <i class="pi pi-angle-right text-20px ml-8px"></i>
          </template>
        </ListCell>
        <ListCell
          title="📧️ 联系我们"
          @click="openContactUs"
        ></ListCell>
      </div>

      <div class="g-safe-area mt-auto flex flex-col items-center gap-16px">
        <div class="flex gap-16px text-15px">
          <span
            class="cursor-pointer font-semibold text-ld-brand-500"
            @click="openUserAgreement"
          >
            {{ _t('《用户协议》') }}
          </span>
          <span
            class="cursor-pointer font-semibold text-ld-brand-500"
            @click="openPrivacyPolicy"
          >
            {{ _t('《隐私政策》') }}
          </span>
        </div>
        <div class="text-13px text-ld-label-secondary text-center">
          {{ _t(`资源版本：${gitHash}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ListCell from '@/components/ListCell/ListCell.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { openContactUs, openPrivacyPolicy, openUserAgreement } from '@/utils'
import { computed, ref } from 'vue'

import { useRouter } from 'vue-router'

const fullVersion = computed(() => _store.appInfo?.fullVersion ?? '')

// 是否显示完整版本号 true: 显示完整版本号 x.y.z.q false: 显示简化版本号 x.y.z
const showFullVersion = ref(false)
// 要显示的版本号
const version = computed(() =>
  showFullVersion.value
    ? fullVersion.value
    : fullVersion.value.split('.').slice(0, 3).join('.')
)

// 有更新
const hasUpdate = computed(() => {
  return _store.appInfo?.updateConfig
})

const router = useRouter()
const gitHash = computed(() => _global.gitCommit.split('(')[0])

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({ name: 'mine' })
  }
}
function onCheckUpdate() {
  _bridge.checkUpdate().then(res => {
    if (!res) {
      _message.info(_t('当前已是最新版本'))
    }
  })
}

// 双击版本号切换显示完整版本号
function onClickVersion() {
  showFullVersion.value = !showFullVersion.value
}
</script>

<style scoped></style>
