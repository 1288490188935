import type { CardSchedule } from '@/api/learn'
import {
  LearnStatus,
  type Card,
  type EnWordCard,
  type ParsedCard,
  type ParsedClozeCard,
} from '@/types/core'
import { parseCard } from '@/utils/card'

export type QueueCard = {
  cardId: number
  cardStatus: LearnStatus
  card: ParsedCard
  r: number
}

export function parseCardSchedule(schedule: CardSchedule): QueueCard {
  const parseResult = parseCard(JSON.parse(schedule.content))

  return {
    cardId: schedule.cardId,
    cardStatus: schedule.cardStatus,
    card: parseResult.card,
    r: schedule.r,
  }
}

export function getAltCards(cards: QueueCard[], cardId: number, card: Card) {
  return cards
    .filter(item => item.cardId !== cardId)
    .filter(item => card.type === item.card.type)
    .map(result => result.card) as ParsedClozeCard[] | EnWordCard[]
}

export function orderCards(cards: QueueCard[]): QueueCard[] {
  const orderedCards: QueueCard[] = []

  // 基础阶段，卡片的顺序需要按照 复习/新学 混合穿插，多余的卡片排在队尾
  // eg: 复习1、新学1、复习2、新学2、复习3、新学3、新学4、新学5
  const reviewCards = cards.filter(
    item => item.cardStatus === LearnStatus.REVIEW
  )
  const debutCards = cards.filter(item => item.cardStatus === LearnStatus.DEBUT)

  let insertReviewCard = true
  while (reviewCards.length > 0 && debutCards.length > 0) {
    orderedCards.push(
      insertReviewCard ? reviewCards.shift()! : debutCards.shift()!
    )
    insertReviewCard = !insertReviewCard
  }

  orderedCards.push(...reviewCards)
  orderedCards.push(...debutCards)

  return orderedCards
}
