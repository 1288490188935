<template>
  <div
    class="bg-[var(--primary-100)] flex items-center justify-between rounded-4px leading-[1.4] px-3"
  >
    <span class="title">
      {{ _t('AI 生成中') }}
      <span class="dots">{{ '.'.repeat(loadingDotCount) }}</span>
    </span>

    <span
      class="cancel"
      @click="emit('cancel')"
    >
      {{ _t('取消生成') }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

const emit = defineEmits<{
  cancel: []
}>()

const MIN_DOTS_COUNT = 1
const MAX_DOTS_COUNT = 6

const loadingDotCount = ref(MIN_DOTS_COUNT)
let loadingTimer: number

onMounted(() => {
  loadingTimer = setInterval(() => {
    loadingDotCount.value++

    if (loadingDotCount.value > MAX_DOTS_COUNT) {
      loadingDotCount.value = MIN_DOTS_COUNT
    }
  }, 400)
})

onUnmounted(() => {
  clearInterval(loadingTimer)
})
</script>
<style scoped>
.title {
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-color);
  white-space: nowrap;
}

.cancel {
  color: var(--text-color-secondary);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
</style>
