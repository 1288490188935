<template>
  <div class="flex items-start justify-center relative">
    <LottieBox
      class="absolute z-0"
      name="reward"
      autoplay
      :loop="false"
    />

    <Icon
      :name="props.iconName"
      class="z-1 reward-content mt-16px w-122px"
    ></Icon>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import Icon from './Icon.vue'
import LottieBox from './LottieBox.vue'

const props = defineProps<{
  iconName: string
}>()

const emit = defineEmits<{
  animationEnd: []
}>()

onMounted(() => {
  // 延迟播放声音，以便与动画同步
  setTimeout(() => {
    _am.playAudio('diamond_gain')
  }, 316)

  setTimeout(() => {
    emit('animationEnd')
  }, 316 + 400)
})
</script>

<style scoped>
.reward-content {
  opacity: 0;
  animation: anime-content 400ms;
  animation-fill-mode: forwards;
  animation-delay: 316ms;
}

@keyframes anime-content {
  0% {
    transform: scale(0.45);
    opacity: 1;
  }
  75% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
