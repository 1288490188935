<template>
  <span>
    <SingleWordSearchSpan
      v-for="(word, i) in words"
      :key="i"
      :word="word"
    ></SingleWordSearchSpan>
  </span>
</template>
<script lang="ts" setup>
import { parseTokens } from '@/utils'
import { computed } from 'vue'
import SingleWordSearchSpan from './SingleWordSearchSpan.vue'

const props = defineProps<{
  text: string
}>()

const words = computed(() => parseTokens(props.text))
</script>
<style scoped></style>
