const IMGS = import.meta.glob('../assets/images/*', { eager: true })

// 传入图片的 name ，返回图片的完整路径
// eg:
//   input: role-1
//   output: /src/assets/images/[hash].role-1.216.228.png
export function resolveImgPath(name: string): string | undefined {
  for (const key in IMGS) {
    // 这里后面加 . 是为了防止名字中出现包含关系时会找错
    // eg: 1001 和 1001_avatar
    if (key.startsWith(`../assets/images/${name}.`)) {
      return (IMGS[key] as any).default
    }
  }

  return undefined
}

// 这里传入图片的实际名称或者是 assetId
// eg:
//   path: /xxxx/role-9.216.228.png
//   assetId: /usr/50223/img/1.1600.1600.jpeg
export function parseImgSize(
  pathOrAssetId: string
): { height: number; width: number } | null {
  const section = pathOrAssetId.split('/').pop()

  if (section == null) {
    return null
  }

  const parts = section.split('.')
  // ext
  parts.pop()

  const height = Number(parts.pop())
  const width = Number(parts.pop())

  if (!isNaN(width) && !isNaN(height)) {
    return { width, height }
  }

  return null
}
