<template>
  <div>
    <LoginForm
      v-if="scene === 'login'"
      :agreementChecked="props.agreementChecked"
      @resetPwd="onLoginResetPwd"
      @done="onAuthDone"
      @handleLoginWithoutAgreementChecked="
        emit('handleLoginWithoutAgreementChecked')
      "
    />

    <ResetPwdForm
      v-if="scene === 'resetPwd'"
      @login="scene = 'login'"
      @done="onResetPwdDone"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import ResetPwdForm from './ResetPwdForm.vue'
import LoginForm from './LoginForm.vue'
import type { UserInfo } from '@/api/auth'

export type Scene = 'login' | 'resetPwd'
const props = withDefaults(
  defineProps<{
    agreementChecked?: boolean
  }>(),
  {
    agreementChecked: true,
  }
)

const emit = defineEmits<{
  done: [token: string, user: UserInfo]
  handleLoginWithoutAgreementChecked: []
}>()

const scene = ref<Scene>('login')

// 当为 true 时从「重置密码」返回时进入「账号绑定」
const backToWxBind = ref(false)

function onAuthDone(token: string, user: UserInfo) {
  emit('done', token, user)
}

function onLoginResetPwd() {
  scene.value = 'resetPwd'
  backToWxBind.value = false
}

function onResetPwdDone() {
  scene.value = 'login'
}
</script>

<style scoped></style>
