// 管理 Dialog/Toast 的 z-index
const BASE_DIALOG_Z_INDEX = 100
const BASE_TOAST_Z_INDEX = 1000

const Z_INDEX_STEP = 1

export class ZIndexMgmt {
  dialogZIndex: number
  toastZIndex: number

  constructor() {
    this.dialogZIndex = BASE_DIALOG_Z_INDEX
    this.toastZIndex = BASE_TOAST_Z_INDEX
  }

  nextDialogZIndex() {
    this.dialogZIndex += Z_INDEX_STEP
    return this.dialogZIndex
  }

  nextToastZIndex() {
    this.toastZIndex += Z_INDEX_STEP
    return this.toastZIndex
  }
}

export default new ZIndexMgmt()
